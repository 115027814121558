
import { PageBase } from "../Pages/PageBase";
import { IDictionary } from "../Common/IDictionary";
import { Helper } from "../Utilities/Helper";
import { Control } from "../Common/Control";
import { IAppUser } from "../App";
import { PageLoader } from "../Partials/Generics";
import { ILocalization } from "../Common/ILocalizations";


export class PageNavigation extends Control {

    constructor(private user: IAppUser, private localization: ILocalization) {
        super();
    }

    public async init(): Promise<boolean> {
        let self = this;
        if (!await super.init()) return false;

        let hashchange = () => {
            if (!location.hash || '') {
                if (!!self.user) {
                    self.showPage(self.getPage('start'), {});
                }
                else {
                    self.showPage(self.getPage('unauthorized'), {});
                }
            }
            else {
                let split = location.hash.split('!'),
                    pg = split[0],
                    parameters = Helper.parseQueryString(split.length > 1 ? split[1] : "");
                let page = (self._mappings[pg] || self._mappings['#not_found'])();
                if (!!page)
                    self.showPage(page, parameters);
            }
        };

        window.addEventListener("hashchange", hashchange);

        hashchange();

        return true;
    }

    public get activePage(): PageBase { return this.children['active'] as PageBase; }
    public async showPage(page: PageBase, parameters: IDictionary<string> = {}) {
        let self = this;
        let loadPage = async () => {
            await self.activePage.load(parameters);
            PageBase.tag.get().empty().append(await self.activePage.render());
            self.activePage.init();
            self._initBackButton();
        };

        PageLoader.showLoader(true);
        try {
            self.setOrResetChild('active', page);
            if (!!self.activePage) {
                await loadPage();
            }
        }
        catch (ex) {
            //ErrorHandler.ajaxError(ex);
            console.log(ex);
        }
        PageLoader.showLoader(false);
    }

    _initBackButton() {
        let self = this,
            element = PageBase.tag.get();

        $('<backbutton>')
            .appendTo(element)
            .append($('<button>', { type: 'button', 'class': 'k-button', onclick: `window.history.back()`, html: `<i class="fa fa-step-backward"></i> <span>${self.localization.button_Back}</span>` }));

    }

    _mappings: IDictionary<() => PageBase> = {};
    public getPage(name: string): PageBase {
        return this._mappings[`#${name}`]();
    }
    public mapPage(name: string, pageConstructor: () => PageBase) {
        this._mappings[`#${name}`] = pageConstructor;
    }

}
