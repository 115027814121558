import { PageBase } from "./PageBase";
import { IDictionary } from "../Common/IDictionary";
import { InfinateScrollView } from "../Views/InfinateScrollView";
import { Helper } from "../Utilities/Helper";
import { IPagedCollection, IPagingSorts, IWorkOrder, ITruck_Sales } from "../Service/ServicesModels";
import { ServiceHandler } from "../Service/ServiceHandler";
import { TagSelector } from "../Common/Tag";
import { AppSession } from "../AppSession";
import { TruckFilter, SortSelection, FilterSortSection } from "../Partials/Filters";
import { ILocalization } from "../Common/ILocalizations";
import { WorkOrderCard } from "../Partials/WorkOrders";



export class WorkOrderSearchPage extends PageBase {
    static tag = new TagSelector('workorder-search-page');

    _trucks: ITruck_Sales[];
    _sorts: IPagingSorts[];

    constructor(private localization: ILocalization, private service: ServiceHandler, private workOrderPageHashTag: string, private isPrinciple: boolean, private subcontractorCrewLeadId: number, id?: string) {
        super(new TagSelector('workordersearchpage'));
        let self = this;

        self._sorts = [
            { sort: "ScheduledStart desc", description: self.localization.dateNewestToOldest },
            { sort: "ScheduledStart asc", description: self.localization.dateOldestToNewest },
            //{ sort: "Lead/Customer/LastName asc", description: self.localization.nameAscending },
            //{ sort: "Lead/Customer/LastName desc", description: self.localization.nameDescending },
        ];

        if (Helper.hasValue(subcontractorCrewLeadId)) AppSession.setData('WorkOrderSearch', 'truckId', subcontractorCrewLeadId);

        self.scroller = new WorkOrderSearchScroller(self, self.service, self.localization, self.workOrderPageHashTag);
    }

    // #region PageBase
    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;
        if (!await super.load(parameters)) return false;
        self.scroller.clear();
        self._trucks = await self.service.getLookupTrucks();
        return true;
    }

    public async init(): Promise<boolean> {
        let self = this;
        if (!await super.init()) return false;

        if (!!self.element) {
            // We need to set a static viewable area;
            //self.resize()
            //$(window).on('resize', function (e) { self.resize(); });

            //$(LeadSearchPage.MenuTag, self.element).children("ul").kendoMenu();
            self.element
                .off()
                .on('change', TruckFilter.tag.selector, function (e) {
                    self.scroller.truckId = Helper.toNumber($(this).val() || null);
                    FilterSortSection.tag.get(self.element).blur();
                })
                .on('change', SortSelection.tag.selector, function (e) {
                    self.scroller.orderby = Helper.toString($(this).val() || null);
                    FilterSortSection.tag.get(self.element).blur();
                })
                .on('click', WorkOrderSearchScroller.tag.selector, function () {
                    FilterSortSection.tag.get(self.element).blur();
                })
                .on('click', function (e) {
                    let filters = FilterSortSection.tag.get(self.element);
                    if ($(e.target).is(filters) ||
                        $(e.target).is(filters.children('div'))) {
                        self.element.toggleClass('show-only-filters');
                    }
                    else if ($(e.target).is(filters.children('select'))) {
                        return false;
                    }
                    else {
                        self.element.removeClass('show-only-filters');
                    }
                })
                .on('change', `${FilterSortSection.tag.selector} select`, function (e) {
                    self.element.removeClass('show-only-filters');
                });
        }
    }

    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        element.append(FilterSortSection.render(
            /*isPrinciple*/ self.isPrinciple,
            /*title*/ self.localization,
            /*truckFilter*/ { trucks: self._trucks, selected: self.scroller.truckId },
            /*productFilter*/ null,
            /*statusFilter*/ null,
            /*notificationType*/ null,
            /*sort*/ { sorts: self._sorts, sort: self.scroller.orderby }
        ));
        element.append(await self.scroller.render());

        return element;
    }

    // #endregion

    // #region Scroller
    public get scroller(): WorkOrderSearchScroller {
        return this.children['scroller'] as WorkOrderSearchScroller;
    }
    public set scroller(val: WorkOrderSearchScroller) {
        this.setOrResetChild('scroller', val);
    }
    // #endregion

    public resize() {
        let self = this;
        super.resize();
        if (!!self.element) {
            let items = WorkOrderSearchScroller.tag.get(self.element),
                height = self.calculateViewableHeight(InfinateScrollView.tag.get(self.element), FilterSortSection.tag.get(self.element));
            //items.height(height);
            self.scroller.resetHeight(height);
        }
    }
}

class WorkOrderSearchScroller extends InfinateScrollView {
    static tag = new TagSelector('workorders');

    constructor(parentPage: PageBase, private service: ServiceHandler, private localization: ILocalization, private workOrderPageHashTag: string) {
        super(parentPage, WorkOrderSearchScroller.tag);

        this._truckId = AppSession.asNumber('WorkOrderSearch', 'truckId');
        this._orderby = AppSession.asString('WorkOrderSearch', 'orderby') || 'ScheduledStart desc';

    }

    _truckId: number = null;
    _orderby: string = 'ScheduledStart desc';
    _skip: number = 0;
    _take: number = 5;
    _totalCount: number = 1;

    public get truckId(): number { return this._truckId; }
    public set truckId(val: number) { if (this._truckId !== val) { this._truckId = val; AppSession.setData('WorkOrderSearch', 'truckId', val); this.queryChanged(); } }

    public get orderby(): string { return this._orderby; }
    public set orderby(val: string) { if (this._orderby !== val) { this._orderby = val; AppSession.setData('WorkOrderSearch', 'orderby', val); this.queryChanged(); } }

    public get skip(): number { return this._skip; }
    public get take(): number { return this._take; }
    public get totalCount(): number { return this._totalCount; }


    public async init(): Promise<boolean> {
        let self = this;
        if (!await super.init()) return false;

        if (!!self.element) {
            self.element
                .on('click', WorkOrderCard.tag.selector, function (e) {
                    let id = Helper.toNumber($(this).attr('data-id'));
                    window.location.hash = `${self.workOrderPageHashTag}${Helper.toQueryString({ id: id }, '!')}`;
                });
        }
        return true;
    }

    // #region fetch data

    public clear() {
        let self = this;

        // reseting fetch counts
        self._skip = 0;
        self._totalCount = 1;
        if (!!self.element) {
            self.element.empty();
        }
    }

    public queryChanged() {
        let self = this;
        self.clear();
        self.refresh();
    }
    public async fetchMorePages(): Promise<JQuery[]> {
        let self = this;
        let items: JQuery[] = [];

        if (self.skip > self.totalCount) { }
        else if (self.skip === self.totalCount) { }
        else {
            let leads = await self._getPagedWorkOrders();
            for (let i = 0; i < leads.items.length; i++) {
                let lead = leads.items[i];
                items.push(WorkOrderCard.render(lead, self.localization));
            }

            self._totalCount = leads.totalCount;
            self._skip += leads.items.length || 1;
        }
        return items;
    }

    async _getPagedWorkOrders(): Promise<IPagedCollection<IWorkOrder>> {
        let self = this;
        try {
            let filter = "";
            if (!!self.truckId) filter += `Crew/Truck/Id eq ${self.truckId}`;
            //if (Helper.hasValue(self.productId)) filter += `Lead/Branch/Product/Id eq ${self.productId}`;
            //if (Helper.hasValue(self.status)) filter += !!filter ? ` and Status eq '${self.statusEnums[self.status]}'` : `Status eq '${self.statusEnums[self.status]}'`
            return await self.service.getPagedWorkOrders(filter, self.orderby, self.skip, self.take);
        }
        catch (ex) {
            return { items: [], totalCount: 0 };
        }
    }
    // #endregion

}

