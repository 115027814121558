import { Helper } from "../Utilities/Helper";


export class ServiceModels {

    static parseArray<T>(src: T[], parseItem: (item: T) => T): T[] {
        if (!!src) {
            let x: T[] = [];
            if (!!parseItem) {
                for (let i = 0; i < src.length; i++) {
                    x.push(parseItem(src[i]));
                }
            }
            return x;
        }
        else return null;
    }

    static parseCalendarDetail(src: ICalendarDetail): ICalendarDetail {
        let a = src as any;
        return {
            LaborBillId: Helper.toNumber(src.LaborBillId) || Helper.toNumber(a.LaborBillID),
            TimeBlockType: Helper.toNumber(src.TimeBlockType),
        };
    }

    static parseCalendarDay(src: ICalendarDay): ICalendarDay {
        return {
            StartDate: Helper.toDate(src.StartDate),
            EndDate: Helper.toDate(src.EndDate),
            Hours: ServiceModels.parseArray<ICalendarHour>(src.Hours, ServiceModels.parseCalendarHour),
        };
    }

    static parseCalendarHour(src: ICalendarHour): ICalendarHour {
        return {
            StartDate: Helper.toDate(src.StartDate),
            EndDate: Helper.toDate(src.EndDate),
            Status: Helper.toNumber(src.Status),
            Details: ServiceModels.parseArray<ICalendarDetail>(src.Details, ServiceModels.parseCalendarDetail),
        };
    }

    static parseCalendarTruck(src: ICalendarTruck): ICalendarTruck {
        return {
            Id: Helper.toNumber(src.Id),
            Description: Helper.toString(src.Description),
        };
    }

    static parseCalendarWorkOrder(src: ICalendarWorkOrder): ICalendarWorkOrder {
        let a = src as any;
        return {
            Id: Helper.toNumber(src.Id),
            Customer: Helper.toString(src.Customer),
            JobId: Helper.toNumber(src.JobId) || Helper.toNumber(a.JobID),
            Status: Helper.toNumber(src.Status)
        };
    }

    static parseCalendarLeads(src: ICalendarLead): ICalendarLead {
        let a = src as any;
        return {
            Id: Helper.toNumber(src.Id),
            Customer: Helper.toString(src.Customer),
            LeadId: Helper.toNumber(src.LeadId) || Helper.toNumber(a.LeadID),
            StartDate: Helper.toDate(src.StartDate),

        };
    }

    static parseCalendar(src: ICalendar): ICalendar {
        return {
            Days: ServiceModels.parseArray<ICalendarDay>(src.Days, ServiceModels.parseCalendarDay),
            Trucks: ServiceModels.parseArray<ICalendarTruck>(src.Trucks, ServiceModels.parseCalendarTruck),
            WorkOrders: ServiceModels.parseArray<ICalendarWorkOrder>(src.WorkOrders, ServiceModels.parseCalendarWorkOrder),
            Leads: ServiceModels.parseArray<ICalendarLead>(src.Leads, ServiceModels.parseCalendarLeads),
            Id: Helper.toNumber(src.Id),
        };
    }
}

export interface IPagedCollection<T> {
    items: T[];
    totalCount: number;
}
export interface ISubcontractorLead {
    Id?: number;
    Status?: LeadStatuses;
    Lead?: ILead;
    Subcontractor?: ISubcontractor;
    SubcontractorNotifiedOn?: Date;
    CreatedOn?: Date;
    CreatedBy?: string;
    UpdatedOn?: Date;
    UpdatedBy?: string;
    Truck?: ICrewMember;
    Quotes?: IQuote[];
}

export interface ISubcontractor {
    Id?: number;
    Contact?: IContact;
    Address?: IAddress;
    Email?: string;
    WorkPhone?: string;
    MobilePhone?: string;
    Fax?: string;
    Pager?: string;
    IsInCompliance?: boolean;
}

export interface ICrewMember {
    Id?: number;
    FirstName?: string;
    LastName?: string;
    ShortDesc?: string;
    Type?: TruckTypes;
    TypeName?: string;
    Truck?: ITruck;
}

export interface ITruck {
    Id?: number;
    Address?: IAddress;
    TruckNumber?: number;
    Email?: string;
    Email2?: string;
    EmailIsUnique?: boolean;
    Phone?: string;
    IsPortalEnabled?: boolean;
    IsSOWEnabled?: boolean;
    NotificationRecipientType?: NotificationRecipientTypes;
    SkillSets?: ISkillSet[];
    Markets?: IOffice[];
    Location?: ICoordinates;
}

export interface ITruck_Sales {
    Id?: number;
    TruckNumber?: number;
    FirstName?: string;
    LastName?: string;
}

export interface ICoordinates {
    Latitude?: number;
    Longitude?: number;
}

export interface ISkillSet {
    Id?: number;
    Skill?: ISkill;
    Trade?: ITrade;
}

export interface ISkill {
    Id?: number;
    Description?: string;
    Active?: boolean;
}

export interface ITrade {
    Id?: number;
    Description?: string;
    Active?: boolean;
}

export interface IContact {
    FirstName?: string;
    LastName?: string;
    MiddleName?: string;
    BusinessName?: string;
}

export interface ILead {
    Id?: number;
    Customer?: IPerson;
    LeadType?: ILeadType;
    Branch?: IBranch;
    CreatedOn?: Date;
    CreatedBy?: string;
    LastUpdatedOn?: Date;
    LastUpdatedBy?: string;
    LastSPRep?: string;
    ApptSetOn?: Date;
    ApptDate?: Date;
    ApptDateTime?: Date;
    StatusCode?: string;
    ReasonCode?: string;
    GroupCode?: string;
    AppointmentPlusReason?: IAppointmentPlusReason;
    MessageToRep?: string;
    ReportedToSears?: Date;
    RecontactDate?: Date;
    UserLock?: boolean;
    LockedBy?: string;
    ApptSetBy?: string;
    StatusCodeLastChangedBy?: string;
    FullFillmentPkg?: boolean;
    CampaignId?: number;
    Priority?: boolean;
    LastCustomerContact?: Date;
    WebToken?: string;
}

export interface IPerson extends IContact {
    CustomerId?: number;
    Spouse?: string;
    Address?: IAddress;
    Location?: ICoordinate;
    Phones?: IPhone[];
    Emails?: IEmail[];
    Offices?: IOffice[];
}

export interface IAddress {
    StreetLine?: string;
    StreetLine2?: string;
    City?: string;
    State?: string;
    Zip?: string;
}

export interface ICoordinate {
    Latitude?: number;
    Longitude?: number;
}

export interface IPhone {
    Id?: number;
    PhoneNumber?: string;
    Type?: PhoneTypes;
    Extension?: string;
}

export interface IEmail {
    Id?: number;
    Address?: string;
    Comment?: string;
}

export interface IOffice {
    Id?: number;
    District?: IDistrict;
    Description?: string;
    ShortDesc?: string;
    Active?: boolean;
    PhoneNumber?: string;
    FaxNumber?: string;
    Notes?: string;
    TmCode?: string;
    TimeZoneId?: number;
    DeactiveDate?: Date;
    PhoneNumberId?: number;
    StreetAddress?: string;
    ZipCode?: number;
    Latitude?: number;
    Longitude?: number;
    State?: string;
    SatelliteOffice?: boolean;
    GeoConfidence?: number;
    DaylightSavings?: boolean;
}

export interface IDistrict {
    Id?: number;
    Region?: IRegion;
    Description?: string;
    ShortDesc?: string;
    Active?: boolean;
}

export interface IRegion {
    Id?: number;
    Company?: ICompany;
    Description?: string;
    ShortDesc?: string;
    Active?: boolean;
    HRActive?: boolean;
}

export interface ICompany {
    Id?: number;
    Description?: string;
    ShortDesc?: string;
    Active?: boolean;
}

export interface ICPC {
    PhoneNumber?: string;
    Fax?: string;
}

export interface ILeadType {
    Id?: number;
}

export interface IBranch {
    Id?: number;
    Office?: IOffice;
    Product?: IProduct;
}

export interface IProduct {
    Id?: number;
    Name?: string;
    FriendlyName?: string;
    IsSamsProduct?: boolean;
}

export interface IAppointmentPlusReason {
    Id?: AppointmentPlusReasons;
    Code?: string;
    Description?: string;
    Active?: boolean;
}

export interface IQuote {
    Id?: number;
}

export enum TruckTypes {
    Principle = 1,
    Supervisor = 2,
    Employee = 3
}

export enum PhoneTypes {
    Home = 1,
    Business,
    Mobile,
    Fax,
    MrsWorkPhone,
    MrWorkPhone,
    /** Not an actual phone type, only used by subcontractor */
    Pager,
}

export enum LeadStatuses {
    NewLead,
    Acknowledged,
    LeadCanceled,
    Scheduled,
    Sold,
    NoSale,


    AllActive, // only for list filter, keep as last item.
}

export enum Products {
    Headquarters = -1,
    Siding = 0,
    Windows = 1,
    CoatingService = 2,
    Patio = 3,
    Cabinets = 4,
    Kitchens = 5,
    Doors = 6,
    Bathroom = 7,
    HVAC = 8,
    Service = 9,
    GarageDoors = 10,
    Roofing = 11,
    InstallOnlyCabinets = 12,
    Flooring = 13,
    Countertops = 14,
    Coating = 15,
    Painting = 16,

    SamsSiding = 17,
    SamsWindows = 18,
    SamsHVAC = 19,
    SamsDoors = 20,
    SamsFlooring = 21,
    SamsBathrooms = 22,
    SamsCountertops = 23,
    SamsCabinets = 24,
    SamsKitchens = 25,
    SamsGarageDoors = 26,

    UniversalProduct = 100,
    CarpetUpholstryCleaning = 200,
    HVACRepair = 201,
    DuctCleaning = 202,
    TrueGreenChemLawn = 300,
    TerminexTermiteInspection = 301,
    TerminexPestEval = 302,
    HVACVantiveOrlandoTampa = 900,
}
//export interface IProductsDescription {
//    Id: Products;
//    Description: string;
//}
//export let ProductDescriptions: IProductsDescription[] = [
//    { Id: Products.Siding, Description: "Siding" },
//    { Id: Products.Windows, Description: "Windows" },
//    { Id: Products.CoatingService, Description: "Coating (Service)" },
//    { Id: Products.Patio, Description: "Patio" },
//    { Id: Products.Cabinets, Description: "Cabinets" },
//    { Id: Products.Kitchens, Description: "Kitchens" },
//    { Id: Products.Doors, Description: "Doors" },
//    { Id: Products.Bathroom, Description: "Bathroom Remodeling" },
//    { Id: Products.HVAC, Description: "HVAC" },
//    { Id: Products.Service, Description: "Service" },
//    { Id: Products.GarageDoors, Description: "Garage Doors" },
//    { Id: Products.Roofing, Description: "Roofing" },
//    { Id: Products.InstallOnlyCabinets, Description: "Install Only Cabinets" },
//    { Id: Products.Flooring, Description: "Flooring" },
//    { Id: Products.Countertops, Description: "Countertops" },
//    { Id: Products.Coating, Description: "Coating" },
//    { Id: Products.Painting, Description: "Painting" },

//    { Id: Products.SamsSiding, Description: "Sams Siding" },
//    { Id: Products.SamsWindows, Description: "Sams Windows" },
//    { Id: Products.SamsCabinets, Description: "Sams Cabinets" },
//    { Id: Products.SamsKitchens, Description: "Sams Kitchens" },
//    { Id: Products.SamsDoors, Description: "Sams Doors" },
//    { Id: Products.SamsBathrooms, Description: "Sams Bathrooms" },
//    { Id: Products.SamsHVAC, Description: "Sams HVAC" },
//    { Id: Products.SamsGarageDoors, Description: "Sams Garage Doors" },
//    { Id: Products.SamsFlooring, Description: "Sams Flooring" },
//    { Id: Products.SamsCountertops, Description: "Sams Countertops" },


//];

export interface ILeadStatusDescription {
    Id: LeadStatuses;
    Description: string;
}
export let LeadStatusDescriptions: ILeadStatusDescription[] = [
    { Id: LeadStatuses.NewLead, Description: "New Lead" },
    { Id: LeadStatuses.Acknowledged, Description: "Acknowledged" },
    { Id: LeadStatuses.LeadCanceled, Description: "Lead Canceled" },
    { Id: LeadStatuses.Scheduled, Description: "Scheduled" },
    { Id: LeadStatuses.Sold, Description: "Quoted - Sold" },
    { Id: LeadStatuses.NoSale, Description: "Quoted - Not Sold" }
];

export enum AppointmentPlusReasons {
    SingleWideMobileHome = 0,
    ConfirmWithMrOnly = 0,
    ConfirmWithMrsOnly = 0,
    HOAApprovalRequired = 0,
    RentalProperty = 0,
    NotPrimaryResidence = 0,
    PowerOfAttorney = 0,
    TownHomeOrCondo = 0,
    Duplex = 0,
    DivorcedOrSeparated = 0,
    DetachedGarage = 0,
    NotHomeownerGettingEst = 0,
    PreviousResetPrime = 0,
    RunBy = 0,
    OtherDecisionMakers = 0,
    SWDW_RentLand = 0,
    CodeRestrictions = 0,
    CounterTopOnly = 0,
    LP_Siding = 0,
    InsuranceLead = 0,
    DataEntryError = 0,
    HVAC_BlueprintCompliance = 0,
    HVAC_SRPrimeOrPlus = 0,
    SpanishSpeaking = 0,
    OneWindow = 0,
    RegionalApproval = 0,
    NeedTranslator = 0,
    ImportedLead = 0,
}


export let AppointmentPlusReasonCodes: IAppointmentPlusReason[] = [
    { Id: AppointmentPlusReasons.SingleWideMobileHome, Code: "SW" },
    { Id: AppointmentPlusReasons.ConfirmWithMrOnly, Code: "MR" },
    { Id: AppointmentPlusReasons.ConfirmWithMrsOnly, Code: "OA" },
    { Id: AppointmentPlusReasons.HOAApprovalRequired, Code: "HO" },
    { Id: AppointmentPlusReasons.RentalProperty, Code: "RE" },
    { Id: AppointmentPlusReasons.NotPrimaryResidence, Code: "SC" },
    { Id: AppointmentPlusReasons.PowerOfAttorney, Code: "PA" },
    { Id: AppointmentPlusReasons.TownHomeOrCondo, Code: "TH" },
    { Id: AppointmentPlusReasons.Duplex, Code: "DU" },
    { Id: AppointmentPlusReasons.DivorcedOrSeparated, Code: "DI" },
    { Id: AppointmentPlusReasons.DetachedGarage, Code: "GC" },
    { Id: AppointmentPlusReasons.NotHomeownerGettingEst, Code: "NH" },
    { Id: AppointmentPlusReasons.PreviousResetPrime, Code: "RS" },
    { Id: AppointmentPlusReasons.RunBy, Code: "RB" },
    { Id: AppointmentPlusReasons.OtherDecisionMakers, Code: "OD" },
    { Id: AppointmentPlusReasons.SWDW_RentLand, Code: "RL" },
    { Id: AppointmentPlusReasons.CodeRestrictions, Code: "CR" },
    { Id: AppointmentPlusReasons.CounterTopOnly, Code: "CT" },
    { Id: AppointmentPlusReasons.LP_Siding, Code: "LP" },
    { Id: AppointmentPlusReasons.InsuranceLead, Code: "IN" },
    { Id: AppointmentPlusReasons.DataEntryError, Code: "DE" },
    { Id: AppointmentPlusReasons.HVAC_BlueprintCompliance, Code: "HB" },
    { Id: AppointmentPlusReasons.HVAC_SRPrimeOrPlus, Code: "RP" },
    { Id: AppointmentPlusReasons.SpanishSpeaking, Code: "SS" },
    { Id: AppointmentPlusReasons.OneWindow, Code: "OW" },
    { Id: AppointmentPlusReasons.RegionalApproval, Code: "RA" },
    { Id: AppointmentPlusReasons.NeedTranslator, Code: "NT" },
    { Id: AppointmentPlusReasons.ImportedLead, Code: "--" },
];

export enum NotificationRecipientTypes {
    PrincipleOnly = 1,
    TruckOnly = 2,
    PrincipleAndTruck = 3,
}

export interface IPagingSorts {
    sort: string;
    description: string;
    disabled?: boolean;
}

export interface IAdminNotification {
    Id?: number;
    SubcontractorId?: number;
    Type?: NotificationTypes;
    Description?: string;
    Message?: string;
    DocumentStorageId?: number;
    DocumentUrl?: string;
    CreatedOn?: Date;
    CreatedBy?: string;
    Scopes?: IScope[];
}

export interface INotification {
    Id?: number;
    SubcontractorId?: number;
    Type?: INotificationType;
    Description?: string;
    Message?: string;
    DocumentStorageId?: number;
    DocumentUrl?: string;
    CreatedOn?: Date;
    CreatedBy?: string;
    ActiveOn?: Date;
    CriticalOn?: Date;
    IsCritical?: boolean;
    LaborBillId?: number;
    AcknowledgedOn?: Date;
    AcknowledgementStatus?: AcknowledgmentStatuses;
}

export interface INotificationType {
    Id?: NotificationTypes;
    Description?: string;
}

export enum NotificationTypes {
    All = 0, // Only for filter use.

    Compliance = 1,
    Schedule = 2,
    PendingWorkOrder = 3,
    General = 4,
    EPA = 5
}

export interface IScope {
    Id?: number;
    ActiveOn?: Date;
    CriticalOn?: Date;
    DistrictId?: number;
    ProductId?: number;
    SubcontractorId?: number;
    LaborBillId?: number;
}

export interface IWorkOrder {
    Id?: number;
    SubcontractorId?: number;
    Job?: IJob;
    Customer?: IPerson;
    Crew?: ICrewMember;
    Office?: IOffice;
    ProjectCoordinator?: IProjectCoordinator;
    ProjectSupervisor?: IProjectSupervisor;
    Status?: TimeBlockStatuses;
    Type?: WorkOrderTypes;
    CompletionDate?: Date;
    ActualStartDate?: Date;
    InvoiceDate?: Date;
    ScheduledStart?: Date;
    ScheduledEnd?: Date;
    Items?: IItem[];
    PaymentRates?: IPaymentRates;
    Documents?: IDocument[];
}

export enum AcknowledgmentStatuses {
    Accepted = 1,
    Rejected = 2,
    Expired = 3,
}

export enum TimeBlockStatuses {
    Pending = 1,
    Accepted = 2,
    Rejected = 3,
    Canceled = 4,
    Rescheduled = 5,
    Completed = 6
}

export enum WorkOrderTypes {
    Appointment = 1,
    Service = 9,
    Measure = 10,
    ServiceInstall = 11,
}

export interface IJob {
    Id?: number;
    Product?: IProduct;
    ApptDate?: Date;
}
export interface IProjectCoordinator {
    Id?: number;
    Person?: IContact;
    MobilePhone?: string;
    Phone?: string;
    Email?: string;
}
export interface IProjectSupervisor {
    Id?: number;
    Contact?: IContact;
    MobilePhone?: string;
    Fax?: string;
    Email?: string;
}

export interface IItem {
    Id?: number;
    VendorCode?: string;
    LaborItem?: string;
    LaborSubItem?: string;
    Description?: string;
    Details?: string;
    Measure?: string;
    Quantity?: number;
    LaborRate?: number;
    TotalCost?: number;
}

export interface IPaymentRates {
    LaborAmount?: number;
    TripChargeAmount?: number;
    CashTicketAmount?: number;
    TotalAmount?: number;
}

export interface IStartPageCounts {
    NewLeads: number;
    NewWorkOrders: number;
    NewNotifications: number;
}

export interface ILeadResultReason {
    Id?: number;
    Code?: string;
    Description?: string;
    Active?: boolean;
    GroupCode?: string;
    TTLCountAsLead?: boolean;
    SortOrder?: number;
    TTLReason?: string;
    ApptsPulledTypeId?: number;
    MrtEligible?: boolean;
}

export interface ISubcontractorLeadActivity {
    Id?: number;
    CustomerId?: number;
    LeadId?: number;
    ActivityDate?: Date;
    ActivityBy?: string;
    ActivityType?: number;
    DispositionedAs?: string;
    Notes?: string;
    ApptDateTime?: Date;
    PhoneExt?: string;
    CustomerReached?: boolean;
}

export interface ITruckAccess {
    SubcontractorCrewMemberId: number;
    AccessEnabled: boolean;
    SOWEnabled: boolean;
    SubcontractorNotificationRecientTypeId: number;
}

export enum DocumentTypes {
    Photo = 78,
    Contract = 15,
    Contract_CA = 975,
    ContractChangeAuthorization = 21,
    CompletionCertificate = 289,
    DamageCheckList = 100,
    DamageWaiver = 194,
    DisclosureNotice = 65,
    EagleViewReport = 931,
    EPA_AcknowledgementForm = 665,
    EPA_Acknowledgement_Signed = 913,
    EPA_LRRP_Checklist_Signed = 914,
    EquipmentReceiptFromVendor = 976,
    EquipmentInvoiceFromContractor = 977,
    Hover = 974,
    HvacStartupCompletion = 188,
    LeaveBehindQuote = 861,
    LoadCalculationWorksheet = 199,
    NoticeOfCancellation = 8,
    OtherDocument = 13,
    Permit = 108,
    PermitCompliance = 340,
    PermitFeeReceipt = 910,
    PermitWaiver = 909,
    PhotoBefore = 906,
    PhotoInProgress = 907,
    PhotoAfter = 908,
    PhotoLeadSafe = 920,
    ProposalWorksheet = 11,
    PurchaseSummary = 978,
    SamsGiftCardDoc = 970,
    WaiverOfLein = 298,
}


export interface IWorkOrderDocument {
    DocumentStorageId: number;
    DocumentDescription: string;
    DocumentName: string;
    CreatedDate: Date;
    DocumentFormatId: number;
}

export interface IPacketDocument extends IDocument {
    PacketId: number;
    PacketTypeId: number;
    LaborBillId?: number;
    PhotosVerified?: boolean;
    VerifiedOn?: Date;
    VerifiedBy?: string;
}

export interface IDocumentInfo extends IDocument {
    Height: number;
    Width: number;
    PageCount: number;
    DownloadSize: number;
}

export interface IDocument {

    // #region DocumentStorage
    DocumentStorageId?: number;
    DocumentTypeId?: number;
    DocumentFormatId?: number;
    DocumentName?: string;
    Note?: string;
    CreatedBy?: string;
    CreatedDate?: Date;
    Active?: boolean;
    ScanDocId?: number;
    ChangeStamp?: any;
    BatchId?: number;
    // #endregion

    // #region DocumentFormat
    FileExt?: string;
    MaxSize?: number;
    IsImage?: boolean;
    MimeType?: string;
    // #endregion

    // #region DocumentType
    DocumentType?: string;
    HasPermissionRestrictions?: boolean;
    UploadToSertifi?: boolean;
    // #endregion

    Length?: number;
    FileName?: string;
}

export let documentExtensions = ".tiff,.tif,.doc,.docx,.xls,.xlsx,.txt,.rtf,.pdf";
export let photoExtensions = ".tiff,.tif,.jpg,.bmp,.png,.jpeg,.gif";

export interface ICalendar {
    Id: number;
    Days: ICalendarDay[];
    Trucks: ICalendarTruck[];
    WorkOrders: ICalendarWorkOrder[];
    Leads: ICalendarLead[];
}

export interface ICalendarDay {
    StartDate: Date;
    EndDate: Date;
    Hours: ICalendarHour[];
}

export interface ICalendarHour {
    StartDate: Date;
    EndDate: Date;
    Status: CalendarStatuses;
    Details: ICalendarDetail[];
}

export interface ICalendarDetail {
    LaborBillId: number;
    TimeBlockType: TimeBlockTypes;
}

export interface ICalendarTruck {
    Id: number;
    Description: string;
}

export interface ICalendarWorkOrder {
    Id: number;
    Customer: string;
    JobId: number;
    Status: TimeBlockStatuses;
}

export interface ICalendarLead {
    Id: number;
    Customer: string;
    LeadId: number;
    StartDate: Date;
}

export enum CalendarStatuses {
    Unavailable = 0,
    Available = 1,
    Job = 2
}

export enum TimeBlockTypes {
    Appointment = 1,
    Meeting = 2,
    RepCancellation = 3,
    Driving = 4,
    Temp = 5,
    TempDrive = 6,
    Unavailable = 7,
    Available = 8,
    Service = 9,
    Measure = 10,
    ServiceInstall = 11
}

export interface IDateRange {
    begin: Date;
    end: Date;
}

export interface ISchedulerDetailRange extends IDateRange {
    timeBlockType: TimeBlockTypes;
    laborBillId?: number;
}

export interface ISelectOption {
    Selected: boolean;
    Id: string;
    Text: string;
}