import { PageBase } from "./PageBase";
import { IDictionary } from "../Common/IDictionary";
import { TagSelector, IdSelector, IJQuerySelector, ClassSelector } from "../Common/Tag";
import { PageTitle, PageSubTitle, Link, Field, FieldLabel, Button, Span, Legend, Links, TextContent, Div, PageLoader } from "../Partials/Generics";
import { ILocalization } from "../Common/ILocalizations";
import { ServiceHandler } from "../Service/ServiceHandler";
import { Utilities } from "../Utilities/Utilities";
import { Helper } from "../Utilities/Helper";
import { Subscription } from "../Common/Subscription";


export class UserRegistrationPage extends PageBase {
    static tag = new TagSelector('userregistrationpage');
    constructor(private service: ServiceHandler, private localization: ILocalization, private forgetPasswordLink: string) {
        super(UserRegistrationPage.tag);
    }

    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;
        if (! await super.load(parameters)) return false;

        return true;
    }

    public onRegistered: Subscription = new Subscription();

    public async init(): Promise<boolean> {
        let self = this;
        if (! await super.init()) return false;
        if (!!self.element) {
            self.element
                .on('keyup', `${RegistrationPassword.tag.selector}, ${RegistrationPasswordConfirmation.tag.selector}`, function (e) {
                    PasswordValidation.update(
                        PasswordValidation.tag.get(self.element),
                        RegistrationPassword.tag.get(self.element).val() as string,
                        RegistrationPasswordConfirmation.tag.get(self.element).val() as string);
                })
                .on('keyup', `${RegistrationPassword.tag.selector}, ${RegistrationPasswordConfirmation.tag.selector}, ${RegistrationSubcontractorId.tag.selector}, ${RegistrationEmail.tag.selector}`, function (e) {
                    Utilities.SetDisabled(RegistrationButton.tag.get(self.element), !self._isFormValid());
                })
                .on('click', RegistrationButton.tag.selector, async function () {
                    PageLoader.showLoader(true);
                    if (await self.service.register(
                        Helper.toNumber(RegistrationSubcontractorId.tag.get(self.element).val()),
                        Helper.toString(RegistrationEmail.tag.get(self.element).val()),
                        Helper.toString(RegistrationPassword.tag.get(self.element).val()),
                        Helper.toString(RegistrationPasswordConfirmation.tag.get(self.element).val()),
                    )) {
                        // success
                        $('input', self.element).val(null);
                        Utilities.SetDisabled(RegistrationButton.tag.get(self.element), true);
                        self.onRegistered.trigger(self, null);
                    }
                    PageLoader.showLoader(false);
                });
            //.on('mouseenter', '#PasswordShow', function (e) {
            //    RegistrationPassword.tag.get(self.element).attr('type', 'text');
            //})
            //.on('mouseleave', '#PasswordShow', function (e) {
            //    RegistrationPassword.tag.get(self.element).attr('type', 'password');
            //})
            //.on('mouseenter', '#ConfirmationShow', function (e) {
            //    RegistrationPasswordConfirmation.tag.get(self.element).attr('type', 'text');
            //})
            //.on('mouseleave', '#ConfirmationShow', function (e) {
            //    RegistrationPasswordConfirmation.tag.get(self.element).attr('type', 'password');
            //})
        }

        return true;
    }

    _isFormValid(): boolean {
        let self = this;
        if (RegistrationEmail.tag.get(self.element).is(':invalid')) return false;
        if (RegistrationSubcontractorId.tag.get(self.element).is(':invalid')) return false;
        if (RegistrationPassword.tag.get(self.element).is(':invalid')) return false;
        if (RegistrationPasswordConfirmation.tag.get(self.element).is(':invalid')) return false;

        return PasswordValidation.validatePassword(RegistrationPassword.tag.get(self.element).val() as string, RegistrationPasswordConfirmation.tag.get(self.element).val() as string);
    }


    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        element.append(PageTitle.render(self.localization.page_UserRegistration));
        element.append(PageSubTitle.render(self.localization.page_UserRegistration_Subtitle));

        element.append(Div.render(
            Div.render(
                Field.render({},
                    FieldLabel.render({ text: self.localization.label_Email }),
                    RegistrationEmail.render(self.localization),
                ),
                Field.render({},
                    FieldLabel.render({ text: self.localization.label_SubcontractorId }),
                    RegistrationSubcontractorId.render(self.localization)
                ),
                Field.render({},
                    FieldLabel.render({ text: self.localization.label_Password }),
                    RegistrationPassword.render(self.localization),
                ),
                Field.render({},
                    FieldLabel.render({ text: self.localization.label_PasswordConfirmation }),
                    RegistrationPasswordConfirmation.render(self.localization)
                )
            ),
            PasswordValidation.render(self.localization)
        ));

        element.append(RegistrationButton.render(self.localization));
        element.append(TextContent.render(self.localization.notice_PasswordNoUpdate));



        element.append(Link.render(self.forgetPasswordLink)
            .append(Span.render(self.localization.link_ForgotPassword)));
        return self.element;
    }
}

class RegistrationButton {
    static tag = new IdSelector('register-btn', 'button');
    static render(localization: ILocalization) {
        return RegistrationButton.tag.create({ 'class': 'k-button', disabled: 'disabled' })
            .append(Span.render(localization.button_Register));
    }
}


class RegistrationEmail {
    static tag = new IdSelector('email', 'input');
    static render(localization: ILocalization): JQuery {
        return RegistrationEmail.tag.create({
            'class': 'k-textbox',
            type: 'text',
            required: 'required',
            placeholder: localization.placeholder_Email
        });
    }
}
class RegistrationSubcontractorId {
    static tag = new IdSelector('subcontractorid', 'input');
    static render(localization: ILocalization): JQuery {
        return RegistrationSubcontractorId.tag.create({
            'class': 'k-textbox',
            min: 0,
            max: 999999,
            type: 'text',
            required: 'required',
            placeholder: localization.placeholder_SubcontractorId
        });
    }
}


class RegistrationPassword {
    static tag = new IdSelector('password', 'input');
    static render(localization: ILocalization): JQuery {
        return RegistrationPassword.tag.create({
            'class': 'k-textbox',
            minlength: 8,
            maxlength: 25,
            //pattern: '(?=^.{6,25}$)(?=(?:.*?\d){1})(?=.*[a-z])(?=(?:.*?[A-Z]){1})(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%*()_+^&]*$',
            type: 'password',
            required: 'required',
            placeholder: localization.placeholder_Password
        });
    }
}
class RegistrationPasswordConfirmation {
    static tag = new IdSelector('password-confirmation', 'input');
    static render(localization: ILocalization): JQuery {
        return RegistrationPasswordConfirmation.tag.create({
            'class': 'k-textbox',
            minlength: 8,
            maxlength: 25,
            //pattern: '(?=^.{6,25}$)(?=(?:.*?\d){1})(?=.*[a-z])(?=(?:.*?[A-Z]){1})(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%*()_+^&]*$',
            type: 'password',
            required: 'required',
            placeholder: localization.placeholder_PasswordConfirmation
        });
    }
}

class PasswordValidation {
    static tag = new ClassSelector('password-validation', 'fieldset');
    static render(localization: ILocalization): JQuery {
        return PasswordValidation.tag.create()
            //.append(PasswordValidation_Label.render(localization))
            .append(Legend.render(localization.passwordValidator_Title))
            .append(PasswordValidation_Case.render(PasswordValidation_Case.letterTag, localization.passwordValidator_Letter))
            .append(PasswordValidation_Case.render(PasswordValidation_Case.capitalTag, localization.passwordValidator_Capital))
            .append(PasswordValidation_Case.render(PasswordValidation_Case.numberTag, localization.passwordValidator_Number))
            .append(PasswordValidation_Case.render(PasswordValidation_Case.symbolTag, localization.passwordValidator_Symbol))
            .append(PasswordValidation_Case.render(PasswordValidation_Case.lengthTag, localization.passwordValidator_Length))
            .append(PasswordValidation_Case.render(PasswordValidation_Case.confirmationTag, localization.passwordValidator_Confirmation));
    }

    static validateHasLowerCaseLetters(value: string): boolean {
        return !!value ? !!value.match(/[a-z]/g) : false;
    }

    static validateHasUpperCaseLetters(value: string): boolean {
        return !!value ? !!value.match(/[A-Z]/g) : false;
    }

    static validateHasNumber(value: string): boolean {
        return !!value ? !!value.match(/[0-9]/g) : false;
    }

    static validateHasSymbol(value: string): boolean {
        return !!value ? !!value.match(/[!@#$%*()_+^&]/g) : false;
    }

    static validateHasRequiredLength(value: string, len: number = 8): boolean {
        return !!value ? value.length >= len : false;
    }


    static update(element: JQuery, password: string, confirmation: string) {
        if (PasswordValidation.validateHasLowerCaseLetters(password)) {
            PasswordValidation_Case.letterTag.get(element).removeClass(PasswordValidation_Case.clsInvalid.cls).addClass(PasswordValidation_Case.clsValid.cls);
        }
        else {
            PasswordValidation_Case.letterTag.get(element).removeClass(PasswordValidation_Case.clsValid.cls).addClass(PasswordValidation_Case.clsInvalid.cls);
        }

        if (PasswordValidation.validateHasUpperCaseLetters(password)) {
            PasswordValidation_Case.capitalTag.get(element).removeClass(PasswordValidation_Case.clsInvalid.cls).addClass(PasswordValidation_Case.clsValid.cls);
        }
        else {
            PasswordValidation_Case.capitalTag.get(element).removeClass(PasswordValidation_Case.clsValid.cls).addClass(PasswordValidation_Case.clsInvalid.cls);
        }

        if (PasswordValidation.validateHasNumber(password)) {
            PasswordValidation_Case.numberTag.get(element).removeClass(PasswordValidation_Case.clsInvalid.cls).addClass(PasswordValidation_Case.clsValid.cls);
        }
        else {
            PasswordValidation_Case.numberTag.get(element).removeClass(PasswordValidation_Case.clsValid.cls).addClass(PasswordValidation_Case.clsInvalid.cls);
        }

        if (PasswordValidation.validateHasSymbol(password)) {
            PasswordValidation_Case.symbolTag.get(element).removeClass(PasswordValidation_Case.clsInvalid.cls).addClass(PasswordValidation_Case.clsValid.cls);
        }
        else {
            PasswordValidation_Case.symbolTag.get(element).removeClass(PasswordValidation_Case.clsValid.cls).addClass(PasswordValidation_Case.clsInvalid.cls);
        }

        if (PasswordValidation.validateHasRequiredLength(password)) {
            PasswordValidation_Case.lengthTag.get(element).removeClass(PasswordValidation_Case.clsInvalid.cls).addClass(PasswordValidation_Case.clsValid.cls);
        }
        else {
            PasswordValidation_Case.lengthTag.get(element).removeClass(PasswordValidation_Case.clsValid.cls).addClass(PasswordValidation_Case.clsInvalid.cls);
        }

        if (password === confirmation) {
            PasswordValidation_Case.confirmationTag.get(element).removeClass(PasswordValidation_Case.clsInvalid.cls).addClass(PasswordValidation_Case.clsValid.cls);
        }
        else {
            PasswordValidation_Case.confirmationTag.get(element).removeClass(PasswordValidation_Case.clsValid.cls).addClass(PasswordValidation_Case.clsInvalid.cls);
        }
    }

    static validatePassword(password: string, confirmation: string): boolean {
        return PasswordValidation.validateHasLowerCaseLetters(password) &&
            PasswordValidation.validateHasUpperCaseLetters(password) &&
            PasswordValidation.validateHasNumber(password) &&
            PasswordValidation.validateHasSymbol(password) &&
            PasswordValidation.validateHasRequiredLength(password) &&
            password === confirmation;
    }
}

class PasswordValidation_Label {
    static tag = new TagSelector('h3');
    static render(localization: ILocalization): JQuery {
        return PasswordValidation_Label.tag.create({ text: localization.passwordValidator_Title });
    }
}

class PasswordValidation_Case {
    static tag = new TagSelector('p');
    static clsValid = new ClassSelector('valid', 'p');
    static clsInvalid = new ClassSelector('invalid', 'p');

    static letterTag = new IdSelector('letter', 'p');
    static capitalTag = new IdSelector('capital', 'p');
    static numberTag = new IdSelector('number', 'p');
    static symbolTag = new IdSelector('symbol', 'p');
    static lengthTag = new IdSelector('length', 'p');
    static confirmationTag = new IdSelector('confirmation', 'p');

    static render(tag: IJQuerySelector, text: string): JQuery {
        return tag.create({ html: text });
    }
}