import dateFormat from "dateformat";
import { TagSelector, ClassSelector, IdSelector } from "../Common/Tag";
import { ILocalization } from "../Common/ILocalizations";
import { ICalendarWorkOrder, TimeBlockStatuses, ICalendarHour, CalendarStatuses, TimeBlockTypes, ICalendarDay, ICalendar, ISchedulerDetailRange, ICalendarLead } from "../Service/ServicesModels";
import { SchedulerDetailIcon, Span, Link, Button } from "./Generics";
import { IDictionary } from "../Common/IDictionary";


export class Scheduler {
    static tag = new TagSelector('scheduler');
    static render(calendar: ICalendar, localization: ILocalization, workOrderPageHashTag: string, selected: number = 0): JQuery {
        let element = Scheduler.tag.create();

        for (let i = 0; i < calendar.Days.length; i++) {
            let day = calendar.Days[i],
                dayElement = SchedulerDay.render(day, calendar.WorkOrders, calendar.Leads, localization, workOrderPageHashTag, i);
            if (i === selected) dayElement.addClass(SchedulerDay.clsSelected.cls);
            element.append(dayElement);
        }

        return element;
    }
}

export class SchedulerDetail {
    static tag = new TagSelector('scheduler-detail');
    static closeTag = new IdSelector('scheduler-close', 'button');
    static render(day: ICalendarDay, workOrders: ICalendarWorkOrder[], leads: ICalendarLead[], localization: ILocalization, workOrderPageHashTag: string, leadPageHashTag: string): JQuery {
        return SchedulerDetail.tag.create()
            .append(Button.render(SchedulerDetail.closeTag.id, `<i class='fa fa-close'></i> ${localization.button_Close}`))
            .append(SchedulerDay.renderHourlyView(day, workOrders, leads, localization, workOrderPageHashTag, leadPageHashTag));
    }
}

export class SchedulerDay {
    static tag = new TagSelector('scheduler-day');
    static clsSelected = new ClassSelector('selected', 'scheduler-day');
    static clsToday = new ClassSelector('today', 'scheduler-day');
    static clsWeekend = new ClassSelector('weekend', 'scheduler-day');

    static render(day: ICalendarDay, workOrders: ICalendarWorkOrder[], leads: ICalendarLead[], localization: ILocalization, workOrderPageHashTag: string, position: number): JQuery {
        let element = SchedulerDay.tag.create(),
            ranges = SchedulerDay.getRanges(day) || [],
            today = new Date().DateOnly(),
            date = day.StartDate.DateOnly();

        if (today.eq(date)) element.addClass(SchedulerDay.clsToday.cls);
        if (date.isWeekend()) element.addClass(SchedulerDay.clsWeekend.cls);
        element.append(SchedulerDay_Date.render(day.StartDate, localization, SchedulerDay.dateFormat(day.StartDate, position)));
        ranges.forEach((range) => {
            let workOrder = !!range.laborBillId ? workOrders.find(x => x.Id === range.laborBillId) : null;
            element.append(SchedulerDay_Detail.render(range.timeBlockType, range.begin, range.end, localization, workOrder));
        });
        SchedulerDay.getLeads(day, leads).forEach((lead) => {
            element.append(SchedulerDay_Detail.renderLead(lead, localization));
        });
        element.data('schedulerDay', { day: day, position: position } as ISchedulerDay);
        return element;
    }

    static renderHourlyView(day: ICalendarDay, workOrders: ICalendarWorkOrder[], leads: ICalendarLead[], localization: ILocalization, workOrderPageHashTag: string, leadPageHashTag: string): JQuery {
        let element = SchedulerDay.tag.create(),
            format = 'mmmm dd - dddd';

        element.append(SchedulerDay_Date.render(day.StartDate, localization, format));
        if (SchedulerDay.enableAllDay(day)) {
            element.append(SchedulerDay_AllDay.render(day, localization, format));
        }
        for (let i = 0; i < day.Hours.length; i++) {
            let hr = day.Hours[i];
            element.append(SchedulerHour.render(hr, workOrders, leads, localization, workOrderPageHashTag, leadPageHashTag));
        }
        return element;
    }
    static enableAllDay(day: ICalendarDay): boolean {
        for (let i = 0; i < day.Hours.length; i++) {
            let hr = day.Hours[i];
            if (hr.Status === CalendarStatuses.Job) return false;
        }
        return true;
    }
    static getRanges(day: ICalendarDay): ISchedulerDetailRange[] {
        let ranges: ISchedulerDetailRange[] = [],
            available: ISchedulerDetailRange = null,
            woRanges: IDictionary<ISchedulerDetailRange> = {};

        for (let i = 0; i < day.Hours.length; i++) {
            let hr = day.Hours[i];
            // set available range
            if (hr.Status === CalendarStatuses.Available) {
                if (!available) {
                    available = { timeBlockType: TimeBlockTypes.Available, begin: hr.StartDate, end: hr.EndDate };
                    ranges.push(available);
                }
                else {
                    available.begin = available.begin < hr.StartDate ? available.begin : hr.StartDate;
                    available.end = available.end > hr.EndDate ? available.end : hr.EndDate;
                }
            }
            else if (!!available) {
                available = null;
            }

            // set work order range
            if (!!hr.Details) {
                for (let d = 0; d < hr.Details.length; d++) {
                    let detail = hr.Details[d];
                    let r = detail.LaborBillId;
                    let range = woRanges[r];
                    if (!range) {

                        woRanges[r] = {
                            begin: hr.StartDate,
                            end: hr.EndDate,
                            timeBlockType: detail.TimeBlockType,
                            laborBillId: r
                        }
                        ranges.push(woRanges[r]);
                    }
                    else {
                        range.begin = range.begin < hr.StartDate ? range.begin : hr.StartDate;
                        range.end = range.end > hr.EndDate ? range.end : hr.EndDate;
                    }
                }
            }
        }
        return ranges;
    }
    static getLeads(day: ICalendarDay, leads: ICalendarLead[]): ICalendarLead[] {
        let d = day.StartDate.DateOnly();
        return leads.filter(x => { return d.eq(x.StartDate.DateOnly()); });
    }
    static dateFormat(startDate: Date, index: number = 0): string {
        return index === 0 || (startDate.getDate() === 1) ? 'mmmm dd - dddd' : 'mmmm dd - dddd';
    }
}
export interface ISchedulerDay {
    day: ICalendarDay;
    position: number;
}


export class SchedulerDay_Detail {
    static tag = new TagSelector('scheduler-daydetail');
    static cls_available = new ClassSelector('available');
    static cls_service = new ClassSelector('service');
    static cls_measure = new ClassSelector('measure');
    static cls_job = new ClassSelector('job');
    static cls_lead = new ClassSelector('lead');

    static render(timeblockType: TimeBlockTypes, start: Date, end: Date, localization: ILocalization, workOrder?: ICalendarWorkOrder): JQuery {
        let today = start.DateOnly(),
            isAllDay = start.eq(today.addHours(8)) && end.eq(today.addHours(20).addMinutes(-1)),
            range = !isAllDay ? `[${dateFormat(start, 'h:MM TT')} ${localization.to} ${dateFormat(end, 'h:MM TT')}]` : '';

        switch (timeblockType) {
            case TimeBlockTypes.Available:
                return SchedulerDay_Detail.tag.create({ 'class': SchedulerDay_Detail.cls_available.cls })
                    .append(SchedulerDetailIcon.render())
                    .append(Span.render(localization.scheduler_Available))
                    .append(Span.render(range));
            case TimeBlockTypes.Appointment:
                return SchedulerDay_Detail.tag.create({ 'class': SchedulerDay_Detail.cls_job.cls })
                    .append(SchedulerDetailIcon.render())
                    .append(Span.render(localization.scheduler_Job))
                    .append(Span.render(`: ${workOrder.JobId}`))
                    .append(Span.render(range));
            case TimeBlockTypes.Measure:
                return SchedulerDay_Detail.tag.create({ 'class': SchedulerDay_Detail.cls_measure.cls })
                    .append(SchedulerDetailIcon.render())
                    .append(Span.render(localization.scheduler_Measure))
                    .append(Span.render(`: ${workOrder.JobId}`))
                    .append(Span.render(range));
            case TimeBlockTypes.Service:
            case TimeBlockTypes.ServiceInstall:
                return SchedulerDay_Detail.tag.create({ 'class': SchedulerDay_Detail.cls_service.cls })
                    .append(SchedulerDetailIcon.render())
                    .append(Span.render(localization.scheduler_Service))
                    .append(Span.render(`: ${workOrder.JobId}`))
                    .append(Span.render(range));

            default:
                return SchedulerDay_Detail.tag.create();
        }
    }
    static renderLead(lead: ICalendarLead, localization: ILocalization): JQuery {
        return SchedulerDay_Detail.tag.create({ 'class': SchedulerDay_Detail.cls_lead.cls })
            .append(SchedulerDetailIcon.render())
            .append(Span.render(localization.scheduler_Lead))
            .append(Span.render(`: ${lead.LeadId}`))
            .append(Span.render(` @ ${dateFormat(lead.StartDate, 'h:MM TT')}`));
    }
}

export class SchedulerDay_Date {
    static tag = new TagSelector('scheduler-date');
    static render(dateTime: Date, localization: ILocalization, format: string = 'dd'): JQuery {
        return SchedulerDay_Date.tag.create({ text: dateFormat(dateTime, format) })
    }
}

export class SchedulerDay_AllDay {
    static tag = new TagSelector('scheduler-allday');
    static render(day: ICalendarDay, localization: ILocalization, format: string): JQuery {
        let hasAvailable = SchedulerDay_AllDay.isAnyAvailable(day);
        return SchedulerDay_AllDay.tag.create({ 'class': 'k-button', text: `${hasAvailable ? localization.scheduler_allDayUnavailable : localization.scheduler_allDayAvailable}` })
            .data('schedulerDay', { day: day, available: !hasAvailable, format: format } as ISchedulerDay_AllDay);
    }
    static isAnyAvailable(day: ICalendarDay): boolean {
        for (let i = 0; i < day.Hours.length; i++) {
            let hr = day.Hours[i];
            if (hr.Status === CalendarStatuses.Available) return true;
        }
        return false;
    }
}
export interface ISchedulerDay_AllDay {
    day: ICalendarDay;
    available: boolean;
    //format: string;
}

export class SchedulerHour {
    static tag = new TagSelector('scheduler-hour');
    static render(hour: ICalendarHour, workOrders: ICalendarWorkOrder[], leads: ICalendarLead[], localization: ILocalization, workOrderPageHashTag: string, leadPageHashTag: string): JQuery {
        let element = SchedulerHour.tag.create().
            append(SchedulerHour_Date.render(hour.StartDate, localization)),
            hourLeads = leads.filter(x => { return hour.StartDate.eq(x.StartDate); }) || [];

        if (hour.Status === CalendarStatuses.Available) element.append(SchedulerHour_Detail.render(localization, TimeBlockTypes.Available));
        else if (hourLeads.length === 0 && hour.Status === CalendarStatuses.Unavailable) element.append(SchedulerHour_Detail.render(localization, TimeBlockTypes.Unavailable));
        else if (!!hour.Details) {
            for (let i = 0; i < hour.Details.length; i++) {
                let detail = hour.Details[i];
                let workOrder = workOrders.find((x) => { return x.Id === detail.LaborBillId });
                if (!!workOrder) {
                    element.append(SchedulerHour_Detail.render(localization, detail.TimeBlockType, workOrder, workOrderPageHashTag));
                }
            }
        }
        hourLeads.forEach((lead) => {
            element.append(SchedulerHour_Detail.renderLead(lead, localization, leadPageHashTag));
        });
        return element
            .data('schedulerHour', hour);
    }
}

export class SchedulerHour_Date {
    static tag = new TagSelector('scheduler-datetime');
    static render(dateTime: Date, localization: ILocalization, format: string = 'h:MM TT'): JQuery {
        return SchedulerHour_Date.tag.create({ text: dateFormat(dateTime, format) });
    }
}

export class SchedulerHour_Detail {
    static tag = new TagSelector('scheduler-hourdetail');
    static cls_available = new ClassSelector('available', 'scheduler-hourdetail');
    static cls_unavailable = new ClassSelector('unavailable', 'scheduler-hourdetail');
    static cls_service = new ClassSelector('service', 'scheduler-hourdetail');
    static cls_measure = new ClassSelector('measure', 'scheduler-hourdetail');
    static cls_job = new ClassSelector('job', 'scheduler-hourdetail');
    static cls_lead = new ClassSelector('lead', 'scheduler-hourdetail');
    static cls_pending = new ClassSelector('pending', 'scheduler-hourdetail');

    static render(localization: ILocalization, timeBlockType: TimeBlockTypes, workOrder: ICalendarWorkOrder = null, workOrderPageHashTag: string = null): JQuery {

        let wo = (typeLabel: string, cls: string) => {
            let pending = workOrder.Status === TimeBlockStatuses.Pending;
            let x = SchedulerHour_Detail.tag.create({ 'class': cls })
                .append(SchedulerDetailIcon.render())
                .append(Link.render(`${workOrderPageHashTag}!id=${workOrder.Id}`)
                    .append(Span.render(pending ?
                        `${typeLabel}: ${workOrder.Id} - ${localization.scheduler_Pending}` :
                        `${typeLabel}: ${workOrder.Id} ${workOrder.Customer}`))
                )
                .data('schedulerDetail', workOrder);
            if (workOrder.Status === TimeBlockStatuses.Pending) x.addClass(SchedulerHour_Detail.cls_pending.cls);
            return x;
        };

        switch (timeBlockType) {
            case TimeBlockTypes.Available:
                return SchedulerHour_Detail.cls_available.create()
                    .append(SchedulerDetailIcon.render())
                    .append(Span.render(localization.scheduler_Available));
            //case TimeBlockTypes.Unavailable:
            //    return SchedulerHour_Detail.cls_available.create()
            //        .append(SchedulerDetailIcon.render())
            //        .append(Span.render(localization.scheduler_Available));
            case TimeBlockTypes.Appointment:
                return wo(localization.scheduler_Job, SchedulerHour_Detail.cls_job.cls);
            case TimeBlockTypes.Measure:
                return wo(localization.scheduler_Measure, SchedulerHour_Detail.cls_measure.cls);
            case TimeBlockTypes.Service:
            case TimeBlockTypes.ServiceInstall:
                return wo(localization.scheduler_Service, SchedulerHour_Detail.cls_service.cls);
            default:
                return SchedulerHour_Detail.cls_unavailable.create();

        }
    }
    static renderLead(lead: ICalendarLead, localization: ILocalization, leadPageHashTag: string): JQuery {
        return SchedulerHour_Detail.tag.create({ 'class': SchedulerHour_Detail.cls_lead.cls })
            .append(SchedulerDetailIcon.render())
            .append(Link.render(`${leadPageHashTag}!id=${lead.Id}`)
                .append(Span.render(`${localization.scheduler_Lead}: ${lead.LeadId} ${lead.Customer}`))
            ).data('schedulerLead', lead);
    }
}