import { PageBase } from "./PageBase";
import { IDictionary } from "../Common/IDictionary";
import { IWorkOrder, TimeBlockStatuses, ICPC } from "../Service/ServicesModels";
import { Helper } from "../Utilities/Helper";
import { ServiceHandler } from "../Service/ServiceHandler";
import { TagSelector } from "../Common/Tag";
import { ILocalization } from "../Common/ILocalizations";
import { WorkOrderPaymentRates, WorkOrderItems, WorkOrderDocumentsLink, WorkOrderAcknowledgement, WorkOrderCustomer, WorkOrderDetail, WorkOrderAcknowledgementButton, WorkOrderProjectCoordinator, WorkOrderProjectSupervisor, WorkOrderCPC, WorkOrderDeclineButton } from "../Partials/WorkOrders";
import { PageTitle } from "../Partials/Generics";
import { Utilities } from "../Utilities/Utilities";
import { Subscription } from "../Common/Subscription";



export class WorkOrderPage extends PageBase {

    constructor(private enableSOW: boolean, private localization: ILocalization, private service: ServiceHandler, private workOrderDocumentsPageHashTag: string, private cpc: ICPC, private mapRedirectURL: string = 'https://google.com/maps/place/') {
        super(new TagSelector('workorderpage'));

        this._workOrderTypes = [
            localization.workOrderType_Appointment,
            localization.workOrderType_Service,
            localization.workOrderType_Measure,
            localization.workOrderType_Install,
        ];
    }

    _workOrderTypes: string[] = [];

    _workOrder: IWorkOrder = null;
    public get workOrder(): IWorkOrder { return this._workOrder; }
    public set workOrder(val: IWorkOrder) {
        this._workOrder = val;
        this._workOrder.Items.sort((a, b) => { return Utilities.SortString(a.VendorCode, b.VendorCode); })
    }

    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;

        if (! await super.load(parameters)) return false;
        self.workOrder = await self.service.getWorkOrder(Helper.toNumber(parameters.id));
        return true;
    }

    public onRejectWorkOrder = new Subscription();
    public onAcknowledgeWorkOrder = new Subscription();

    public async init(): Promise<boolean> {
        let self = this;
        if (! await super.init()) return false;
        if (!!self.element) {
            self.element
                .off()
                .on('click', WorkOrderAcknowledgementButton.tag.selector, async function () {
                    self.onAcknowledgeWorkOrder.trigger(self, self.workOrder);
                })
                .on('click', WorkOrderDeclineButton.tag.selector, async function () {
                    self.onRejectWorkOrder.trigger(self, self.workOrder);
                });
        }

        return true;
    }

    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        element.append(PageTitle.render(self.localization.page_WorkOrder));
        element.append(WorkOrderDetail.render(self.workOrder, self.localization, self._workOrderTypes));
        element.append(WorkOrderCustomer.render(self.workOrder.Customer, self.workOrder.Status, self.mapRedirectURL, self.localization));

        if (self.workOrder.Status.isIn([TimeBlockStatuses.Pending])) {
            if (self.enableSOW)
                element.append(WorkOrderAcknowledgement.render(self.localization));
        }
        else {
            element.append(WorkOrderDocumentsLink.render(self.workOrder.Id, self.workOrderDocumentsPageHashTag, self.localization));
        }

        if (!!self.workOrder.ProjectCoordinator) {
            element.append(WorkOrderProjectCoordinator.render(self.workOrder.ProjectCoordinator, self.localization));
        }
        if (!!self.workOrder.ProjectSupervisor) {
            element.append(WorkOrderProjectSupervisor.render(self.workOrder.ProjectSupervisor, self.localization));
        }
        element.append(WorkOrderCPC.render(self.cpc, self.localization));
        element.append(WorkOrderItems.render(self.workOrder.Items, self.localization));
        element.append(WorkOrderPaymentRates.render(self.workOrder.PaymentRates, self.localization));
        return self.element;
    }
}
