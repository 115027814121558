import { WorkOrderSearchPage } from "../Pages/WorkOrderSearchPage";

export class TagSelector implements IJQuerySelector {

    constructor(private _tag: string) { }

    public get(parent?: JQuery): JQuery { return $(this.tag, parent); }
    public create(attributes: JQuery.PlainObject<any> = {}): JQuery { return $(`<${this.tag}>`, attributes) }

    public get tag(): string { return this._tag; }
    public get selector(): string { return this.tag; }
}

export class ClassSelector implements IJQuerySelector {
    constructor(private _cls: string, private _tag?: string) { }

    public get(parent?: JQuery): JQuery { return $(this.selector, parent); }
    public create(attributes: JQuery.PlainObject<any> = {}): JQuery {
        if (!!attributes && !!attributes.class) attributes.class += ` ${this.cls}`;
        else if (!!attributes) attributes.class = this.cls;
        return $(`<${this.tag}>`, attributes);
    }

    public get tag(): string { return this._tag; }
    public get cls(): string { return this._cls; }
    public get selector(): string { return `${this.tag}.${this.cls}`; }
}

export class IdSelector implements IJQuerySelector {
    static itemSelector(base: string, itemId: string | number): string { return `${base}-${itemId}`; }

    constructor(private _id: string, private _tag: string) { }

    public get(parent?: JQuery): JQuery { return $(this.selector, parent); }
    public create(attributes: JQuery.PlainObject<any> = {}): JQuery {
        if (!!attributes) attributes.id = this.id;
        return $(`<${this.tag}>`, attributes);
    }

    public get tag(): string { return this._tag; }
    public get id(): string { return this._id; }
    public get selector(): string { return `#${this.id}`; }
}


export interface IJQuerySelector {
    tag: string;
    get: (parent?: JQuery) => JQuery;
    create: (attributes?: JQuery.PlainObject<any>) => JQuery;
    selector: string;
}

