import { Subscription } from "../Common/Subscription";
import { IDictionary } from "../Common/IDictionary";
import { setTimeout } from "timers";

export module Utilities {

    // check for css :hover supports and save in a variable
    export let supportsTouch = (typeof Touch == "object");

    export let isMobile =
        (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4)))
        ;

    export let isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    export function getContainerFrame(): JQuery {
        let frame = null;
        let frames = $.grep(window.parent.$('iframe').toArray(), (element: HTMLFrameElement, e) => {
            return element.contentWindow === window;
        });
        if (frames.length > 0) {
            frame = $(frames[0]);
        }
        return frame as JQuery;
    }

    export function hideTab(tabId: string): void {
        $('#tab_' + tabId).hide();
    }
    export function showTab(tabId: string): void {
        $('#tab_' + tabId).show();
    }

    export function topWindow(): Window {
        let p = window;
        while (p.parent !== p) { p = p.parent as any; }
        return p;
    }

    export function cloneDeepData(subject: any, circularHistory: any[] = []) {
        function isCircular(s: any) {
            return circularHistory.indexOf(subject) !== -1;
        }
        try {
            if (!!subject) {
                if (Array.isArray(subject)) {
                    let d: any[] = [];
                    let o = subject as any[];
                    o.forEach((item, i, items) => {
                        if (item instanceof Object) d.push(cloneDeepData(item, circularHistory));
                        else if (item === undefined || item === null) d.push(item);
                        else if (typeof item === 'function' || item instanceof Subscription) { }
                        else d.push(item);
                    });
                    return d;
                }
                let d: any = {};
                Object.keys(subject).forEach((key, k, keys) => {
                    const p = subject[key];
                    if (p === undefined || p === null || p instanceof Date) {
                        d[key] = p;
                        //console.info("Ship.Utilities", "cloneDeepData", "undefined/null", p);
                    }
                    else if (typeof p === 'function' || p instanceof Subscription || p.__proto__.constructor.name === 'Subscription') {
                        // nothing, we are skipping this
                        //console.info("Ship.Utilities", "cloneDeepData", "function/subscription", p);
                    } else if (typeof p === 'object') {
                        //console.info("Ship.Utilities", "cloneDeepData", "object", p);
                        if (isCircular(p)) {
                            d[key] = null;
                            return;
                        }
                        circularHistory.push(p);
                        d[key] = cloneDeepData(p, circularHistory);
                    } else {
                        //console.info("Ship.Utilities", "cloneDeepData", "non-object", p);
                        d[key] = p;
                    }
                });
                return d;
            }
            return subject;
        } catch (ex) {
            //window.Ship.logger.exception(ex, "Ship.Utilities", "cloneDeepData");
        }
        return;
    }

    export function getTopPadding(element: JQuery): number {
        if (element.length === 0) return 0;
        let p = 0;
        if ((element[0] as any) !== document) {
            p = getPadding(element, 'padding-top') || 0;
            p += getTopPadding(element.parent());
        }
        return p;
    }
    export function getBottomPadding(element: JQuery): number {
        if (element.length === 0) return 0;
        let p = 0;
        if ((element[0] as any) !== document) {
            p = getPadding(element, 'padding-bottom') || 0;
            p += getBottomPadding(element.parent());
        }
        return p;
    }

    export function getPadding(element: JQuery, name: string) {
        if (!element) return 0;
        let s = element.css(name);
        return !!s ? parseInt(s.split('px')[0]) || 0 : 0;
    }

    export function toArray<T>(dict: IDictionary<T>): T[] {
        let out: T[] = [];
        if (!dict) return out;
        Object.keys(dict)
            .forEach((key, k, keys) => {
                out.push(dict[key]);
            });
        return out;
    }

    export function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    export function getPageViewableHeight(): number {
        let vph = $(window).height(),
            vpw = $(window).width(),
            headerHeight = $('header').height(),
            //footerHeight = $('footer').height(),
            spacerHeight = $('spacer').last().height(),
            pagePaddingTop = parseInt($('page').css('padding-top')),
            pagePaddingBottom = parseInt($('page').css('padding-bottom'));

        return vph - headerHeight - spacerHeight - pagePaddingTop - pagePaddingBottom;
    }

    export function formatFileSize(size: number): string {
        let i = Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    export function getFileList(input: JQuery): FileList {
        return (<HTMLInputElement>input.get(0)).files;
    }

    export function spaceOccupied(element: JQuery): IDimensions {
        let height = element.height(),
            width = element.width(),

            marginTop = parseInt(element.css('margin-top')),
            marginBottom = parseInt(element.css('margin-bottom')),
            marginLeft = parseInt(element.css('margin-left')),
            marginRight = parseInt(element.css('margin-right')),

            paddingTop = parseInt(element.css('padding-top')),
            paddingBottom = parseInt(element.css('padding-bottom')),
            paddingLeft = parseInt(element.css('padding-left')),
            paddingRight = parseInt(element.css('padding-right')),

            horizontalBuffer = marginLeft + marginRight + paddingLeft + paddingRight,
            verticalBuffer = marginTop + marginBottom + paddingTop + paddingBottom,
            widthUsed = width + horizontalBuffer,
            heightUsed = height + verticalBuffer
            ;

        return {
            widthUsed: widthUsed,
            heightUsed: heightUsed,

            horizontialBuffer: horizontalBuffer,
            verticalBuffer: verticalBuffer,

            width: width,
            height: height,

            marginTop: marginTop,
            marginBottom: marginBottom,
            marginLeft: marginLeft,
            marginRight: marginRight,

            paddingTop: paddingTop,
            paddingBottom: paddingBottom,
            paddingLeft: paddingLeft,
            paddingRight: paddingRight
        };
    }

    export interface IDimensions {
        widthUsed: number;
        heightUsed: number;

        horizontialBuffer: number;
        verticalBuffer: number;

        width: number;
        height: number;
        marginTop: number;
        marginBottom: number;
        marginLeft: number;
        marginRight: number;

        paddingTop: number;
        paddingBottom: number;
        paddingRight: number;
        paddingLeft: number;


    }

    /**
     * Inserts parameters into a string localized string format. 
     */
    export function formattedString(src: string, fields: IDictionary<string>, fieldStartTag: string = '<%', fieldEndTag: string = '%>'): string {
        let result = src;
        Object.keys(fields).forEach((key) => {
            result = result.replace(`${fieldStartTag}${key}${fieldEndTag}`, fields[key]);
        });
        return result;
    }

    export function All<T>(enums: any): T[] {
        let keys: T[] = [];
        for (let i in enums) {
            if (isNaN(Number(i))) keys.push(i as any);
        }
        return keys;
    }

    export function SetDisabled(element: JQuery, disabled: boolean) {
        element.attr('disabled', disabled ? 'disabled' : null);
    }

    export function SortString(a: string, b: string): number {
        return a == b ? 0 : (a > b ? 1 : -1);
    }
}
