import { PageBase } from "./PageBase";
import { IDictionary } from "../Common/IDictionary";
import { InfinateScrollView } from "../Views/InfinateScrollView";
import { Helper } from "../Utilities/Helper";
import { IPagedCollection, INotification, IPagingSorts } from "../Service/ServicesModels";
import { ServiceHandler } from "../Service/ServiceHandler";
import { TagSelector } from "../Common/Tag";
import { ILocalization } from "../Common/ILocalizations";
import { Loader } from "../Partials/Generics";
import { ActiveNotificationCard, AcknowledgeNotificationButton } from "../Partials/Notifications";
import { FilterSortSection, SortSelection } from "../Partials/Filters";
import { AppSession } from "../AppSession";


export class NotificationSearchPage extends PageBase {

    //static FiltersTag: string = "filters";
    //static SortTag: string = "sort";
    //static ItemsTag: string = "items";

    sorts: IPagingSorts[];


    constructor(private localization: ILocalization, private service: ServiceHandler, private notificationDocumentPageHashTag: string, private workOrderPageHashTag: string, private isPrinciple: boolean, private subcontractorCrewLeadId: number, id?: string) {
        super(new TagSelector('notificationsearchpage'));
        let self = this;

        self.sorts = [
            { sort: "ActiveOn desc", description: self.localization.dateNewestToOldest },
            { sort: "ActiveOn asc", description: self.localization.dateOldestToNewest },
            //{ sort: "Lead/Customer/LastName asc", description: self.localization.nameAscending },
            //{ sort: "Lead/Customer/LastName desc", description: self.localization.nameDescending },
        ];

        self.scroller = new NotificationSearchScroller(self, self.service, self.localization, self.notificationDocumentPageHashTag, workOrderPageHashTag);
    }

    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;
        if (!await super.load(parameters)) return false;
        self.scroller.clear();
        return true;
    }

    public async init(): Promise<boolean> {
        let self = this;
        if (!await super.init()) return false;

        if (!!self.element) {
            // We need to set a static viewable area;
            //self.resize()
            //$(window).on('resize', function (e) { self.resize(); });

            $(self.element)
                .off()
                .on('change', SortSelection.tag.selector, function (e) {
                    self.scroller.orderby = Helper.toString($(this).val() || null);
                    FilterSortSection.tag.get(self.element).blur();
                })
                .on('click', NotificationSearchScroller.tag.selector, function () {
                    FilterSortSection.tag.get(self.element).blur();
                })
                .on('click', function (e) {
                    let filters = FilterSortSection.tag.get(self.element);
                    if ($(e.target).is(filters) ||
                        $(e.target).is(filters.children('div'))) {
                        self.element.toggleClass('show-only-filters');
                    }
                    else if ($(e.target).is(filters.children('select'))) {
                        return false;
                    }
                    else {
                        self.element.removeClass('show-only-filters');
                    }
                })
                .on('change', `filters select`, function (e) {
                    self.element.removeClass('show-only-filters');
                });
        }
    }


    public resize() {
        let self = this;
        super.resize();
        if (!!self.element) {
            let items = NotificationSearchScroller.tag.get(self.element),
                height = self.calculateViewableHeight(InfinateScrollView.tag.get(self.element), FilterSortSection.tag.get(self.element));
            //items.height(height);
            self.scroller.resetHeight(height);
        }
    }

    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        element.append(FilterSortSection.render(
            /*isPrinciple*/ self.isPrinciple,
            /*title*/ self.localization,
            /*truckFilter*/ null,
            /*productFilter*/ null,
            /*statusFilter*/ null,
            /*notificationType*/ null,
            /*sort*/ { sort: self.scroller.orderby, sorts: self.sorts }));
        element.append(await self.scroller.render());

        return element;
    }

    // #region Scroller
    public get scroller(): NotificationSearchScroller {
        return this.children['scroller'] as NotificationSearchScroller;
    }
    public set scroller(val: NotificationSearchScroller) {
        this.setOrResetChild('scroller', val);
    }
    // #endregion
}


export class NotificationSearchScroller extends InfinateScrollView {
    static tag = new TagSelector("notificatons")

    constructor(parentPage: PageBase, private service: ServiceHandler, private localization: ILocalization, private notificationDocumentPageHashTag: string, private workOrderPageHashTag: string) {
        super(parentPage, NotificationSearchScroller.tag);

        this._orderby = AppSession.asString('NotificationAdminScroller', 'orderby') || 'ActiveOn desc';

    }

    _orderby: string = 'ActiveOn desc';
    _skip: number = 0;
    _take: number = 5;
    _totalCount: number = 1;

    public get orderby(): string { return this._orderby; }
    public set orderby(val: string) { if (this._orderby !== val) { this._orderby = val; AppSession.setData('NotificationAdminScroller', 'orderby', val); this.queryChanged(); } }

    public get skip(): number { return this._skip; }
    public get take(): number { return this._take; }
    public get totalCount(): number { return this._totalCount; }


    public async init(): Promise<boolean> {
        let self = this;
        if (!await super.init()) return false;

        if (!!self.element) {
            self.element
                .on('click', AcknowledgeNotificationButton.tag.selector, async function (e) {
                    let notification = $(this).closest(ActiveNotificationCard.tag.selector);
                    let loader = Loader.render(self.localization.loader_AcknowledgingNotification).appendTo(notification);
                    try {
                        let id = Helper.toNumber(notification.attr('data-id'));
                        let data = await self.service.acknowledgeNotification(id);
                        notification.replaceWith(ActiveNotificationCard.render(data, self.localization, self.notificationDocumentPageHashTag, self.workOrderPageHashTag));
                    } catch{ }
                    loader.remove();
                });
        }
        return true;
    }
    // #region fetch data

    public clear() {
        let self = this;

        // reseting fetch counts
        self._skip = 0;
        self._totalCount = 1;
        if (!!self.element) {
            self.element.empty();
        }
    }

    public queryChanged() {
        let self = this;
        self.clear();
        self.refresh();
    }
    public async fetchMorePages(): Promise<JQuery[]> {
        let self = this;
        let items: JQuery[] = [];

        if (self.skip > self.totalCount) { }
        else if (self.skip === self.totalCount) { }
        else {
            let notifcations = await self._getPagedNotifications();
            for (let i = 0; i < notifcations.items.length; i++) {
                let notification = notifcations.items[i];
                items.push(ActiveNotificationCard.render(notification, self.localization, self.notificationDocumentPageHashTag, self.workOrderPageHashTag));
            }

            self._totalCount = notifcations.totalCount;
            self._skip += notifcations.items.length || 1;
        }
        return items;
    }

    async _getPagedNotifications(): Promise<IPagedCollection<INotification>> {
        let self = this;
        try {
            let filter = "";
            //if (Helper.hasValue(self.productId)) filter += `Lead/Branch/Product/Id eq ${self.productId}`;
            //if (Helper.hasValue(self.status)) filter += !!filter ? ` and Status eq '${self.statusEnums[self.status]}'` : `Status eq '${self.statusEnums[self.status]}'`
            return await self.service.getPagedActiveNotifications(filter, self.orderby, self.skip, self.take);
        }
        catch (ex) {
            return { items: [], totalCount: 0 };
        }
    }
    // #endregion


}
