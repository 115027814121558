import { ViewBase } from "./ViewBase";
import { TagSelector } from "../Common/Tag";
import { promises } from "dns";

export class Footer extends ViewBase {
    static tag = new TagSelector('footer');

    constructor(private configuration: IFooterConfig) {
        super(Footer.tag);
    }


    public async render(): Promise<JQuery> {
        let self = this;

        let element = await super.render();

        if (!!element) {
            // Setup Main Content
            element
                .append($('<div>', { 'class': 'pagewidth' })
                    .append(await this._renderCopyright())
                    .append(await this._renderVersion())
                    .append(await this._renderPrivacyLinks()));
        }
        return element;
    }

    async _renderCopyright(): Promise<JQuery> {
        let self = this;
        let copyright = $('<copyright>', { 'class': 'left', text: self.configuration.copyright });
        return copyright;
    }

    async _renderPrivacyLinks(): Promise<JQuery> {
        let self = this;
        let links = $('<links>', { 'class': 'right' })
            .append($('<a>', { href: self.configuration.privacyPolicyLink.href, text: self.configuration.privacyPolicyLink.text }))
            .append($('<a>', { href: self.configuration.caPrivacyPolicyLink.href, text: self.configuration.caPrivacyPolicyLink.text }))
            .append($('<a>', { href: self.configuration.termsOfUseLink.href, text: self.configuration.termsOfUseLink.text }))
            .append($('<a>', { href: self.configuration.licenseLink.href, text: self.configuration.licenseLink.text }));

        return links;
    }

    async _renderVersion(): Promise<JQuery> {
        let self = this;
        return $('<version>', {html: `<b>Version: </b>${self.configuration.version}`})
    }
}

export interface IFooterConfig {
    copyright: string;
    privacyPolicyLink: { href: string; text: string; };
    caPrivacyPolicyLink: { href: string; text: string; };
    termsOfUseLink: { href: string; text: string; };
    licenseLink: { href: string; text: string; };
    version: string;
}