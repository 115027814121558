import { AppSession } from "../AppSession";
import { IDictionary } from "../Common/IDictionary";
import { ILocalization } from "../Common/ILocalizations";
import { TagSelector } from "../Common/Tag";
import { FilterSortSection, SortSelection, NotificationTypeFilter } from "../Partials/Filters";
import { Notification, NotificationCard, NotificationAddButton } from "../Partials/Notifications";
import { ServiceHandler } from "../Service/ServiceHandler";
import { IAdminNotification, IPagedCollection, IPagingSorts, NotificationTypes } from "../Service/ServicesModels";
import { Helper } from "../Utilities/Helper";
import { InfinateScrollView } from "../Views/InfinateScrollView";
import { PageBase } from "./PageBase";
import { ButtonGroup } from "../Partials/Generics";


export class NotificationAdminPage extends PageBase {
    static tag = new TagSelector('notificationadminpage');

    //static FiltersTag: string = "filters";
    //static SortTag: string = "sort";
    //static ItemsTag: string = "items";

    sorts: IPagingSorts[];
    notificationTypes: NotificationTypes[];


    constructor(private localization: ILocalization, private service: ServiceHandler, private notificationDocumentPageHashTag: string, private notificationEditPageHashTag: string) {
        super(NotificationAdminPage.tag);
        let self = this;

        self.sorts = [
            { sort: "Id desc", description: self.localization.dateNewestToOldest },
            { sort: "Id asc", description: self.localization.dateOldestToNewest },
            //{ sort: "Lead/Customer/LastName asc", description: self.localization.nameAscending },
            //{ sort: "Lead/Customer/LastName desc", description: self.localization.nameDescending },
        ];

        self.notificationTypes = [
            NotificationTypes.All,
            NotificationTypes.General,
            NotificationTypes.EPA
        ];

        self.scroller = new NotificationAdminScroller(self, self.service, self.localization, self.notificationDocumentPageHashTag, notificationEditPageHashTag);
    }

    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;
        if (!await super.load(parameters)) return false;
        self.scroller.clear();
        return true;
    }

    public async init(): Promise<boolean> {
        let self = this;
        if (!await super.init()) return false;

        if (!!self.element) {
            // We need to set a static viewable area;
            //self.resize()
            //$(window).on('resize', function (e) { self.resize(); });

            $(self.element)
                .off()
                .on('change', NotificationTypeFilter.tag.selector, function (e) {
                    self.scroller.notificationType = Helper.toNumber($(this).val() || null);
                    FilterSortSection.tag.get(self.element).blur();
                })
                .on('change', SortSelection.tag.selector, function (e) {
                    self.scroller.orderby = Helper.toString($(this).val() || null);
                    FilterSortSection.tag.get(self.element).blur();
                })
                .on('click', NotificationAdminScroller.tag.selector, function () {
                    FilterSortSection.tag.get(self.element).blur();
                })
                .on('click', NotificationAddButton.tag.selector, function () {
                    window.location.hash = self.notificationEditPageHashTag;
                })
                .on('click', function (e) {
                    let filters = FilterSortSection.tag.get(self.element);
                    if ($(e.target).is(filters) ||
                        $(e.target).is(filters.children('div'))) {
                        self.element.toggleClass('show-only-filters');
                    }
                    else if ($(e.target).is(filters.children('select'))) {
                        return false;
                    }
                    else {
                        self.element.removeClass('show-only-filters');
                    }
                })
                .on('change', `filters select`, function (e) {
                    self.element.removeClass('show-only-filters');
                });
        }
    }


    public resize() {
        let self = this;
        super.resize();
        if (!!self.element) {
            let items = NotificationAdminScroller.tag.get(self.element),
                height = self.calculateViewableHeight(InfinateScrollView.tag.get(self.element), FilterSortSection.tag.get(self.element));
            items.height(height);
            self.scroller.resetHeight(height);
        }
    }

    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        element.append(FilterSortSection.render(
            /*isPrinciple*/ false,
            /*title*/ self.localization,
            /*truckFilter*/ null,
            /*productFilter*/ null,
            /*statusFilter*/ null,
            /*notificationType*/ { notificationTypes: self.notificationTypes, selected: self.scroller.notificationType },
            /*sort*/ { sort: self.scroller.orderby, sorts: self.sorts })
            .append(ButtonGroup.render(NotificationAddButton.render(self.localization)))
        );
        element.append(await self.scroller.render());

        return element;
    }

    // #region Scroller
    public get scroller(): NotificationAdminScroller {
        return this.children['scroller'] as NotificationAdminScroller;
    }
    public set scroller(val: NotificationAdminScroller) {
        this.setOrResetChild('scroller', val);
    }
    // #endregion
}


export class NotificationAdminScroller extends InfinateScrollView {
    static tag = new TagSelector("notificatons")

    constructor(parentPage: PageBase, private service: ServiceHandler, private localization: ILocalization, private notificationDocumentPageHashTag: string, private notificationEditPageHashTag: string) {
        super(parentPage, NotificationAdminScroller.tag);

        this._orderby = AppSession.asString('NotificationAdminScroller', 'orderby') || 'Id desc';
        this._notificationType = AppSession.asNumber('NotificationAdminScroller', 'notificationType') || NotificationTypes.All;
    }

    _notificationType: NotificationTypes = 0;

    _orderby: string = 'Id desc';
    _skip: number = 0;
    _take: number = 5;
    _totalCount: number = 1;

    public get notificationType(): NotificationTypes { return this._notificationType; }
    public set notificationType(val: NotificationTypes) { if (this._notificationType !== val) { this._notificationType = val; AppSession.setData('NotificationAdminScroller', 'notificationType', val); this.queryChanged(); } }

    public get orderby(): string { return this._orderby; }
    public set orderby(val: string) { if (this._orderby !== val) { this._orderby = val; AppSession.setData('NotificationAdminScroller', 'orderby', val); this.queryChanged(); } }

    public get skip(): number { return this._skip; }
    public get take(): number { return this._take; }
    public get totalCount(): number { return this._totalCount; }


    public async init(): Promise<boolean> {
        let self = this;
        if (!await super.init()) return false;

        if (!!self.element) {
            self.element
                .on('click', NotificationCard.tag.selector, async function (e) {
                    let notification = $(this).data('notification') as IAdminNotification;
                    window.location.hash = `${self.notificationEditPageHashTag}!id=${notification.Id}`;
                });
        }
        return true;
    }
    // #region fetch data

    public clear() {
        let self = this;

        // reseting fetch counts
        self._skip = 0;
        self._totalCount = 1;
        if (!!self.element) {
            self.element.empty();
        }
    }

    public queryChanged() {
        let self = this;
        self.clear();
        self.refresh();
    }
    public async fetchMorePages(): Promise<JQuery[]> {
        let self = this;
        let items: JQuery[] = [];

        if (self.skip > self.totalCount) { }
        else if (self.skip === self.totalCount) { }
        else {
            let notifications = await self._getPagedNotifications();
            for (let i = 0; i < notifications.items.length; i++) {
                let notification = notifications.items[i];
                items.push(NotificationCard.render(notification));
            }

            self._totalCount = notifications.totalCount;
            self._skip += notifications.items.length || 1;
        }
        return items;
    }

    async _getPagedNotifications(): Promise<IPagedCollection<IAdminNotification>> {
        let self = this;
        try {
            let filter = "";
            if (Helper.hasValue(self._notificationType) && self._notificationType == NotificationTypes.All) {
                let active: string[] = [
                    `Type/Id eq '${Notification.getTypeEnum(NotificationTypes.EPA)}'`,
                    `Type/Id eq '${Notification.getTypeEnum(NotificationTypes.General)}'`
                ];
                let typeFilter = `(${active.join(' or ')})`;
                filter += !!filter ? ` and ${typeFilter}'` : typeFilter;
            }
            else if (Helper.hasValue(self._notificationType)) {
                let typeFilter = `Type/Id eq '${Notification.getTypeEnum(self._notificationType)}'`;
                filter += !!filter ? ` and ${typeFilter}` : typeFilter;
            }
            //if (Helper.hasValue(self.status)) filter += !!filter ? ` and Status eq '${self.statusEnums[self.status]}'` : `Status eq '${self.statusEnums[self.status]}'`
            return await self.service.getPagedNotifications(filter, self.orderby, self.skip, self.take);
        }
        catch (ex) {
            return { items: [], totalCount: 0 };
        }
    }
    // #endregion


}
