import { ViewBase } from "./ViewBase";
import { TagSelector } from "../Common/Tag";


export class Header extends ViewBase {
    static tag = new TagSelector('header');

    constructor(private configuration: IHeaderConfig) {
        super(Header.tag);

        //configuration.administrationArea.enable = true;
        //configuration.developerArea.enable = true;
        //configuration.trucks.enable = true;
        //configuration.workOrders.enable = true;
        //configuration.notifications.enable = true;
        //configuration.leads.enable = true;
    }

    public async render(): Promise<JQuery> {
        let self = this;

        let element = await super.render();

        if (!!element) {
            // Setup Main Content
            element
                .addClass("color_secondary")
                .append($('<a>', { href: `${self.configuration.home.href}`, 'class': 'invisibleLink' }))
                .append($('<logo>', { 'class': 'color2', style: 'height: 77px; width: 170px;' }))
                .append(await this._renderNavigation());

            if (self.configuration.logoff.enable)
                element.append(await this._renderLogin());

        }
        return element;
    }

    public async init(): Promise<boolean> {
        let self = this;
        if (!await super.init()) return false;

        if (!!self.element) {
            //if (!!Utilities.supportsTouch) {
            // touch device fix
            self.element
                .on('click', function (e) {
                    if ($(e.target).is('#hamburger')) {
                        $('#products', self.element).toggleClass('expanded');
                    }
                    else {
                        $('#products', self.element).removeClass('expanded');
                    }
                })
                .on('blur', '#hamburger', function () {
                    $('#products').removeClass('expanded');
                });

            //}
        }
    }

    async _renderNavigation(): Promise<JQuery> {
        let self = this;
        let navigationMenu = $('<div>', { id: 'products' })
            .append($('<i>', { id: 'hamburger', 'class': 'fa fa-bars', 'aria-hidden': true }));

        let pages = $('<ul>', {}).appendTo(navigationMenu);
        if (self.configuration.notifications.enable) pages.append(self._renderNotifications());
        if (self.configuration.leads.enable) pages.append(self._renderLeads());
        if (self.configuration.leadReferral.enable) pages.append(self._renderLeadReferral());
        if (self.configuration.workOrders.enable) pages.append(self._renderWorkOrders());
        if (self.configuration.scheduling.enable) pages.append(self._renderScheduling());
        if (self.configuration.trucks.enable) pages.append(self._renderTrucks());
        if (self.configuration.developerArea.enable) pages.append(self._renderDevelopers());
        if (self.configuration.administrationArea.enable) pages.append(self._renderAdministration());

        return navigationMenu;
    }

    async _renderLogin(): Promise<JQuery> {
        let self = this;

        let login = $('<div>', { 'class': 'dropdown right' })
            .append($('<a>', { href: self.configuration.logoff.href })
                .append($('<icon>', { 'class': 'human_profile' }))
                .append($('<label>', { text: self.configuration.logoff.label }))
            );

        return login;
    }

    _renderNotifications(): JQuery {
        let self = this;
        return $('<li>', {})
            .append($('<a>', { href: `${self.configuration.notifications.href}` })
                .append($('<icon>', { 'class': 'notifications' }))
                .append($('<label>', { text: 'Notifications' })))
            .append($('<ul>', { 'class': 'sub' })
                .append($('<li>', {})
                    .append($('<a>', { href: `${self.configuration.notifications.href}`, text: 'Search' }))
                )
            );
    }

    _renderLeads(): JQuery {
        let self = this;
        return $('<li>', {})
            .append($('<a>', { href: `${self.configuration.leads.href}` })
                .append($('<icon>', { 'class': 'leads' }))
                .append($('<label>', { text: `${self.configuration.leads.label}` })))
            .append($('<ul>', { 'class': 'sub' })
                .append($('<li>', {})
                    .append($('<a>', { href: `${self.configuration.leads.href}`, text: 'Search' }))
                )
            );
    }
    _renderLeadReferral(): JQuery {
        let self = this;
        return $('<li>', {})
            .append($('<a>', { href: `${self.configuration.leadReferral.href}`, target: `_blank` })
                .append($('<icon>', { 'class': 'leads' }))
                .append($('<label>', { text: `${self.configuration.leadReferral.label}` })))
            .append($('<ul>', { 'class': 'sub' })
                .append($('<li>', {})
                    .append($('<a>', { href: `${self.configuration.leadReferral.href}`, target: `_blank`, text: 'Submit' }))
                )
            );
    }

    _renderWorkOrders(): JQuery {
        let self = this;
        return $('<li>', {})
            .append($('<a>', { href: `${self.configuration.workOrders.href}` })
                .append($('<icon>', { 'class': 'work_orders' }))
                .append($('<label>', { text: `${self.configuration.workOrders.label}` })))
            .append($('<ul>', { 'class': 'sub' })
                .append($('<li>', {})
                    .append($('<a>', { href: `${self.configuration.workOrders.href}`, text: 'Search' }))
                )
            );
    }

    _renderScheduling(): JQuery {
        let self = this;
        return $('<li>', {})
            .append($('<a>', { href: `${self.configuration.scheduling.href}` })
                .append($('<icon>', { 'class': 'scheduling' }))
                .append($('<label>', { text: `${self.configuration.scheduling.label}` })))
            .append($('<ul>', { 'class': 'sub' })
                .append($('<li>', {})
                    .append($('<a>', { href: `${self.configuration.scheduling.href}`, text: 'View' }))
                )
            );
    }

    _renderTrucks(): JQuery {
        let self = this;
        return $('<li>', {})
            .append($('<a>', { href: `${self.configuration.trucks.href}` })
                .append($('<icon>', { 'class': 'trucks' }))
                .append($('<label>', { text: `${self.configuration.trucks.label}` })))
            .append($('<ul>', { 'class': 'sub' })
                .append($('<li>', {})
                    .append($('<a>', { href: `${self.configuration.trucks.href}`, text: 'View' }))
                )
            );
    }

    _renderDevelopers(): JQuery {
        let self = this;
        return $('<li>', {})
            .append($('<a>', { href: `${self.configuration.developerArea.href}` })
                .append($('<icon>', { 'class': 'developer' }))
                .append($('<label>', { text: `${self.configuration.developerArea.label}` })))
            .append($('<ul>', { 'class': 'sub' })
                .append($('<li>', {})
                    .append($('<a>', { href: `${self.configuration.developerArea.href}`, text: 'View' }))
                )
            );
    }

    _renderAdministration(): JQuery {
        let self = this;
        return $('<li>', {})
            .append($('<a>', { href: `${self.configuration.administrationArea.href}` })
                .append($('<icon>', { 'class': 'administration' }))
                .append($('<label>', { text: `${self.configuration.administrationArea.label}` })))
            .append($('<ul>', { 'class': 'sub' })
                .append($('<li>', {})
                    .append($('<a>', { href: `${self.configuration.administrationArea.href}`, text: 'View' }))
                )
                .append($('<li>', {})
                    .append($('<a>', { href: `${self.configuration.administrationArea.notifications.href}`, text: `${self.configuration.administrationArea.notifications.label}` }))
                )
                .append($('<li>', {})
                    .append($('<a>', { href: `${self.configuration.administrationArea.changeSubcontractor.href}`, text: `${self.configuration.administrationArea.changeSubcontractor.label}` }))
                )
            );
    }
}

export interface IHeaderConfig {
    home: { enable: boolean; href: string; label: string; };
    notifications: { enable: boolean; href: string; label: string; };
    leads: { enable: boolean; href: string; label: string; };
    leadReferral: { enable: boolean; href: string; label: string; };
    workOrders: { enable: boolean; href: string; label: string; };
    scheduling: { enable: boolean; href: string; label: string; }
    trucks: { enable: boolean; href: string; label: string; }
    developerArea: { enable: boolean; href: string; label: string; };
    administrationArea: {
        enable: boolean; href: string; label: string;
        notifications: { enable: boolean; href: string; label: string; };
        changeSubcontractor: { enable: boolean; href: string; label: string; };
    };
    logoff: { enable: boolean; href: string; label: string; };
}
