import { PageBase } from "./PageBase";
import { TagSelector } from "../Common/Tag";


export class UnauthorizedUserPage extends PageBase {

    constructor(private localization: IUnauthorizedUserPageLocalization, private loginLink: string, private registerHashTag: string, private userManualLink: string) {
        super(new TagSelector('unauthorized'));
    }

    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        $('<h1>', { text: 'Welcome to...' }).appendTo(element);
        $('<h4>', { text: `Transform SR Home Improvements - Subcontractor Portal` }).appendTo(element);

        $('<p>', { html: `<br/>If this is your first visit to the new Portal, don't worry...` }).appendTo(element);
        $('<p>', { html: `If you have <b>not registered</b> on contractors.searshomepro.com or here, then please do so.  Otherwise, then continue on to the login page.` }).appendTo(element);
        $('<p>', { html: `<br/><b>Your user name and password from SearsHomePro.com will still work here.</b>  Simply use your existing user name and password to login.` }).appendTo(element);
        $('<p>', { html: `If you don't remember your password, you can use the 'Forgot Password' link on the login screen to request a new one.<br/>` }).appendTo(element);

        $('<a>', { href: `${self.loginLink}` })
            .appendTo(element)
            .append($('<icon>', { 'class': 'human_profile' }))
            .append($('<label>', { text: self.localization.login }));

        $('<a>', { href: `${self.registerHashTag}` })
            .appendTo(element)
            .append($('<icon>', { 'class': 'register' }))
            .append($('<label>', { text: self.localization.register }));

        $('<a>', { href: `${self.userManualLink}`, target: `_blank` })
            .appendTo(element)
            .append($('<icon>', { 'class': 'manual' }))
            .append($('<label>', { text: self.localization.userManual }));

        return self.element;
    }
}
export interface IUnauthorizedUserPageLocalization {
    register: string;
    login: string;
    userManual: string;
}