import { PageBase } from "./PageBase";
import { TagSelector, IdSelector } from "../Common/Tag";
import { PageTitle, PageSubTitle, Field, FieldLabel, Button, Select, Loader, PageLoader } from "../Partials/Generics";
import { Helper } from "../Utilities/Helper";
import { ITruck_Sales, ITruck } from "../Service/ServicesModels";
import { ILocalization } from "../Common/ILocalizations";
import { ServiceHandler } from "../Service/ServiceHandler";
import { IDictionary } from "../Common/IDictionary";


export class ImpersonationPage extends PageBase {
    static tag = new TagSelector('impersonationpage');
    constructor(private service: ServiceHandler, private localization: ILocalization, private subcontractorId: number = null, private truckId: number = null, private appRoot: string) {
        super(ImpersonationPage.tag);
    }

    private trucks: ITruck_Sales[] = [];

    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;
        if (!await super.load(parameters)) return false;

        self.trucks = await self.service.getImpersonationTrucks(self.subcontractorId);

        return true;
    }
    public async init(): Promise<boolean> {
        let self = this;
        if (!await super.init()) return false;

        if (!!self.element) {
            self.element
                .on('change', ImpersonationSubcontractor.tag.selector, async function (e) {
                    let input = $(this),
                        loader = Loader.render().appendTo(input.closest(Field.tag.selector));
                    self.subcontractorId = Helper.toNumber(input.val());

                    await self.reloadTrucks();
                    loader.remove();
                })
                .on('change', ImpersonationUser.tag.selector, function (e) {
                    let input = $(this);
                    self.truckId = Helper.toNumber(input.val());
                })
                .on('click', ImpersonateButton.tag.selector, async function (e) {
                    let input = $(this);
                    PageLoader.showLoader(true);

                    if (!! await self.service.impersonateUser(self.subcontractorId, self.truckId)) {
                        window.location.href = self.appRoot
                    }
                });


        }
    }

    async reloadTrucks(): Promise<boolean> {
        let self = this;

        self.trucks = await self.service.getImpersonationTrucks(self.subcontractorId);
        ImpersonationUser.tag.get(self.element).replaceWith(ImpersonationUser.render(ImpersonationUser.options(self.trucks), self.truckId || 0));
        return true;
    }

    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        element.append(PageTitle.render('Impersonation'));
        element.append(PageSubTitle.render('Select subcontrator and truck to impersonate.'));
        element.append(Field.render({},
            FieldLabel.render({ html: `Subcontractor:` }),
            ImpersonationSubcontractor.render(self.subcontractorId, self.localization)
        ));
        element.append(Field.render({},
            FieldLabel.render({ html: `User:` }),
            ImpersonationUser.render(ImpersonationUser.options(self.trucks), self.truckId || 0)
        ));
        element.append(ImpersonateButton.render(self.localization));

        return element;
    }
}

class ImpersonationSubcontractor {
    static tag = new IdSelector('subcontractorid', 'input');
    static render(value: number, localization: ILocalization): JQuery {
        return ImpersonationSubcontractor.tag.create({ value: value, placeholder: `` });
    }
}

class ImpersonationUser {
    static tag = new IdSelector('userid', 'select');
    static render(options: IImpersonationOption[], truckId: number): JQuery {
        return Select.render<IImpersonationOption>(
            /*items*/ options,
            /*value*/(i) => !!i ? Helper.toString(i.id) : null,
            /*htmlContent*/(i) => i.htmlContent,
            /*isSelected*/(i) => truckId === (!!i ? i.id : null),
            /*isDisabled*/(i) => i.disabled,
            /*nullOption*/ undefined,
            /*tag*/ ImpersonationUser.tag
        );
    };
    static options(trucks: ITruck_Sales[]): IImpersonationOption[] {
        let options: IImpersonationOption[] = [];
        options.push({ id: 0, disabled: false, htmlContent: `0 - Principle` });
        trucks.forEach((truck) => {
            options.push({ id: truck.Id, disabled: false, htmlContent: `${truck.TruckNumber} - ${truck.FirstName} ${truck.LastName}` })
        });
        return options;
    }
}

class ImpersonateButton {
    static tag = new IdSelector('impersonate-btn', 'button');
    static render(localization: ILocalization): JQuery {
        return ImpersonateButton.tag.create({ 'class': 'k-button', html: `Impersonate` });
    }
}

interface IImpersonationOption {
    id: number;
    htmlContent: string;
    disabled: boolean;
}