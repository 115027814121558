import { PageBase } from "./PageBase";
import { IDictionary } from "../Common/IDictionary";
import { ServiceHandler } from "../Service/ServiceHandler";
import { Helper } from "../Utilities/Helper";
import { IWorkOrder, DocumentTypes, documentExtensions, photoExtensions, IWorkOrderDocument } from "../Service/ServicesModels";
import { Utilities } from "../Utilities/Utilities";
import { TagSelector } from "../Common/Tag";
import { ILocalization } from "../Common/ILocalizations";
import { Loader, PageTitle } from "../Partials/Generics";
import { WorkOrderDetail } from "../Partials/WorkOrders";
import { DocumentUploadSection, IUploadTypes, PacketDocuments, UploadedDocuments, DocumentUploadSelection, DocumentUploadTypeSelect, DocumentUploadButton, CancelDocumentUploadButton } from "../Partials/Documents";


export class WorkOrderDocumentsPage extends PageBase {

    constructor(private service: ServiceHandler, private localization: ILocalization, private documentViewerHashTag: string, private onFileSizeValidation: (e: string)=>void) {
        super(new TagSelector('wodocuments'));
        let self = this;

        self._workOrderTypes = [
            localization.workOrderType_Appointment,
            localization.workOrderType_Service,
            localization.workOrderType_Measure,
            localization.workOrderType_Install,
        ];

        self._documentTypes = [
            //{ id: -1, text: localization.documentTypes_NotSelected, limit: 0, ext: '', disabled: false },
            { id: null, text: localization.placeholder_DocumentType, limit: 0, ext: '', disabled: false },
            { id: DocumentTypes.PhotoBefore, text: localization.documentTypes_PhotoBefore, limit: 20000000, ext: photoExtensions, disabled: false },
            { id: DocumentTypes.PhotoInProgress, text: localization.documentTypes_PhotoInProgress, limit: 20000000, ext: photoExtensions, disabled: false },
            { id: DocumentTypes.PhotoAfter, text: localization.documentTypes_PhotoAfter, limit: 20000000, ext: photoExtensions, disabled: false },
            { id: DocumentTypes.OtherDocument, text: localization.documentTypes_OtherDocument, limit: 10000000, ext: documentExtensions, disabled: false },


            { id: DocumentTypes.WaiverOfLein, text: localization.documentTypes_WaiverOfLein, limit: 10000000, ext: documentExtensions, disabled: false },
            { id: DocumentTypes.PermitWaiver, text: localization.documentTypes_PermitWaiver, limit: 10000000, ext: documentExtensions, disabled: false },
            { id: DocumentTypes.PermitCompliance, text: localization.documentTypes_PermitCompliance, limit: 10000000, ext: documentExtensions, disabled: false },
            { id: DocumentTypes.CompletionCertificate, text: localization.documentTypes_CompletionCertificate, limit: 10000000, ext: documentExtensions, disabled: false },
            { id: DocumentTypes.HvacStartupCompletion, text: localization.documentTypes_HvacStartupCompletion, limit: 10000000, ext: documentExtensions, disabled: false },
            { id: DocumentTypes.DamageCheckList, text: localization.documentTypes_DamageCheckList, limit: 10000000, ext: documentExtensions, disabled: false },
            { id: DocumentTypes.DamageWaiver, text: localization.documentTypes_DamageWaiver, limit: 10000000, ext: documentExtensions, disabled: false },
            { id: DocumentTypes.EPA_Acknowledgement_Signed, text: localization.documentTypes_EPA_Acknowledgement_Signed, limit: 10000000, ext: documentExtensions, disabled: false },
            { id: DocumentTypes.EPA_LRRP_Checklist_Signed, text: localization.documentTypes_EPA_LRRP_Checklist_Signed, limit: 10000000, ext: documentExtensions, disabled: false },
        ];
    }

    _workOrderTypes: string[] = [];
    _documentTypes: IUploadTypes[] = [];


    _workOrder: IWorkOrder = null;
    public get workOrder(): IWorkOrder { return this._workOrder; }

    _packetDocuments: IWorkOrderDocument[];
    _uploadedDocuments: IWorkOrderDocument[];

    //get uploadType(): kendo.ui.DropDownList { return this.kendoControls['uploadType'] as kendo.ui.DropDownList; }
    //set uploadType(val: kendo.ui.DropDownList) { this.kendoControls['uploadType'] = val; }

    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;
        if (!await super.load(parameters)) return false;
        self._workOrder = await self.service.getWorkOrder(Helper.toNumber(parameters.id));

        return true;
    }

    public async init(): Promise<boolean> {
        let self = this;
        if (!await super.init()) return false;

        if (!!self.element) {
            let clearUploadForm = () => {
                DocumentUploadSelection.tag.get(self.element).val(null);
                DocumentUploadTypeSelect.tag.get(self.element).val(null);
            };

            let enableActions = () => {
                let uploadBtn = DocumentUploadButton.tag.get(self.element),
                    cancelBtn = CancelDocumentUploadButton.tag.get(self.element),
                    files = DocumentUploadSelection.tag.get(self.element),
                    documentType = DocumentUploadTypeSelect.tag.get(self.element),
                    enable = !!files.val() &&
                        !!documentType.val() &&
                        documentType.val() !== '';

                Utilities.SetDisabled(uploadBtn, !enable);
                Utilities.SetDisabled(cancelBtn, !enable);
            };

            self.element
                .on('click', DocumentUploadButton.tag.selector, async function (e) {
                    let files = Utilities.getFileList(DocumentUploadSelection.tag.get(self.element)),
                        typeId = Helper.toNumber(DocumentUploadTypeSelect.tag.get(self.element).val()),
                        type = self._documentTypes.find((v) => { return v.id === typeId; });
                    await self.uploadDocuments(type, files);
                    clearUploadForm();
                    await self.reloadUploadedDocuments();
                })
                .on('click', CancelDocumentUploadButton.tag.selector, function () {
                    clearUploadForm();
                    enableActions();
                })
                .on('change', DocumentUploadTypeSelect.tag.selector, function (e) {
                    let files = DocumentUploadSelection.tag.get(self.element),
                        typeId = Helper.toNumber(DocumentUploadTypeSelect.tag.get(self.element).val()),
                        type = self._documentTypes.find((v) => { return v.id === typeId; });
                    files.attr('accept', type.ext);
                    files.attr('disabled', type.id > 0 ? null : 'disabled');
                    files.val(null);
                    enableActions();
                })
                .on('change', DocumentUploadSelection.tag.selector, function () {
                    enableActions();
                });
        }

        self.reloadPacketDocuments();
        self.reloadUploadedDocuments();
    }

    public async reloadPacketDocuments(): Promise<boolean> {
        let self = this;
        let section = PacketDocuments.tag.get(self.element);
        let loader = Loader.render(self.localization.loader_PacketDocuments).appendTo(section);
        try {
            self._packetDocuments = await self.service.getWorkOrderPacketDocuments(self.workOrder.Id);
            if (!!self.element) {
                section.replaceWith(await PacketDocuments.render(self.service, self._packetDocuments, self.localization, self.documentViewerHashTag));
            }
            return true;
        }
        catch (ex) {
            loader.remove();
            return false;
        }
    }

    public async reloadUploadedDocuments(): Promise<boolean> {
        let self = this;
        let section = UploadedDocuments.tag.get(self.element);
        let loader = Loader.render(self.localization.loader_UploadedDocuments).appendTo(section);
        try {
            self._uploadedDocuments = await self.service.getWorkOrderUploadedDocuments(self.workOrder.Id);
            if (!!self.element) {
                section.replaceWith(await UploadedDocuments.render(self.service, self._uploadedDocuments, self.localization, self.documentViewerHashTag));
            }
            return true;
        }
        catch (ex) {
            loader.remove();
            return false;
        }
    }

    public async uploadDocuments(uploadType: IUploadTypes, files: FileList): Promise<boolean> {
        let self = this;
        let uploadSection = DocumentUploadSection.tag.get(self.element);
        let loader = Loader.render(self.localization.loader_UploadingDocuments).appendTo(uploadSection);
        try {
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                if (!!file.size && file.size <= uploadType.limit) {
                    await self.service.uploadWorkOrderDocument(self.workOrder.Id, uploadType.id, file);
                }
                else {
                    self.onFileSizeValidation(Utilities.formattedString(self.localization.validation_FileSizeExceeded, { fileName: file.name, limit: Utilities.formatFileSize(uploadType.limit) }));
                }
            }
            loader.remove();
            return true;
        }
        catch (ex) {
            loader.remove();
        }
    }

    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        //element.append(await self.renderMenu());
        element
            .append(PageTitle.render(self.localization.page_WorkOrderDocuments))
            .append(WorkOrderDetail.render(self.workOrder, self.localization, self._workOrderTypes))
            .append(DocumentUploadSection.render(self.localization, self._documentTypes))
            .append(await PacketDocuments.render(self.service, self._packetDocuments, self.localization, self.documentViewerHashTag))
            .append(await UploadedDocuments.render(self.service, self._uploadedDocuments, self.localization, self.documentViewerHashTag));

        return element;
    }
}


