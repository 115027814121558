
import '../prototypes/date';
import '../prototypes/objects';
import { IDictionary } from '../Common/IDictionary';

export module Helper {
    export function hasValue(value?: any): boolean {
        if (value === undefined) return false;
        if (value === null) return false;
        if (typeof value === 'string' && value.trim().length < 1) return false;
        return true;
    }
    export function toNumber(value?: any): number {
        if (value === undefined) return null;
        if (value === null) return null;
        if (isNaN(value)) return null;
        if (value === "") return null;
        if (typeof value === "number") return value;
        if (typeof value === "string") return parseInt(value);
        return null;
    }
    export function toBoolean(value?: any): boolean {
        if (value === undefined) return null;
        if (value === null) return null;
        if (value === "") return null;
        if (typeof value === "boolean") return value;
        if (typeof value === "number" && value === 0) return false;
        if (typeof value === "number" && value > 0) return true;
        if (typeof value === "string" && value.toLowerCase() === "true") return true;
        if (typeof value === "string" && value.toLowerCase() === "false") return false;
        return null;
    }
    export function toDate(value?: any): Date {
        if (value === undefined) return null;
        if (value === null) return null;
        if (value === "") return null;
        if (value instanceof Date) return value;
        if (typeof value === "string") {
            const d = new Date(value);
            return Helper.isValidDate(d) ? d : null;
        }
        return null;
    }

    export function isValidDate(d: Date) {
        return d instanceof Date && !isNaN(d as any);
    }

    export function toString(value?: any): string {
        if (value === undefined) return null;
        if (value === null) return null;
        if (value === "") return null;
        if (value === "null") return null;
        if (typeof value === "string") return value.trim();
        return value.toString().trim();
    }

    export function toShipDate(value?: Date): string {
        let date = Helper.toDate(value);
        if (value === null) return null;
        return date.toEasternISOString();
    }

    export function FormControlEnabled(enabled: boolean) {
        return (Helper.toBoolean(enabled) === true) ? "" : " disabled";
    }

    export function FormControlChecked(checked: boolean) {
        return (Helper.toBoolean(checked) === true) ? " checked" : ""
    }

    export function FormControlSelected(selected: boolean) {
        return (Helper.toBoolean(selected) === true) ? " selected" : ""
    }

    export function hasActiveModal(): boolean {
        return $('.modal-dialog').length > 0;
    }

    export function hasActiveDialog(): boolean {
        return $('.dialog').length > 0;
    }

    export function hasActiveSplash(): boolean {
        return $('.ship-splash:visible').length > 0;
    }

    export function hasActiveLead(): boolean {
        return $('.outbound-lead:visible').length > 0;
    }
    export function parseArray<T>(array: any[], typeConstructor: (data?: any) => T): T[] {
        let output = [] as T[];
        array.forEach((item, i, items) => {
            output.push(typeConstructor(item));
        });
        return output;
    }
    export function dayOfWeek(date: Date): string {
        let days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
        return days[date.getDay()];
    }
    export function hashCode(value: string): number {
        let hash = 0, i, chr;
        if (value.length === 0) return hash;
        for (i = 0; i < value.length; i++) {
            chr = value.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
    export function parseQueryString(queryString: string): IDictionary<string> {
        let query: IDictionary<string> = {};
        if (!queryString) return query;
        let pairs = (queryString[0] === '?' || queryString[0] === '!' ? queryString.substr(1) : queryString).split('&');
        for (let i = 0; i < pairs.length; i++) {
            let pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

    export function toQueryString(parameters: IDictionary<string | number | boolean>, marker: string = '?'): string {
        if (!!parameters) {
            let parts: string[] = [];
            Object.keys(parameters).forEach((key) => {
                let v = parameters[key];
                if (!!v)
                    parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(parameters[key].toString())}`)
            });

            return marker + parts.join('&');
        }
        return '';
    }

    export function contains(items: any[], item: any): boolean {
        return items.lastIndexOf(item) > -1;
    }
}

