import { TagSelector, IJQuerySelector } from "../Common/Tag";
import { IOffice, ISkillSet, ICrewMember, IJob, ITruck, PhoneTypes } from "../Service/ServicesModels";
import { ILocalization } from "../Common/ILocalizations";
import { IDictionary } from "../Common/IDictionary";
import { Legend, NoResults, TruckIcon, CardTitle, CrewMemberIcon } from "./Generics";
import { TruckAccess } from "../Views/TruckAccess";
import { Address, PhoneNumber, MapLink, Email } from "./Contacts";
import { Helper } from "../Utilities/Helper";


export class TruckSkill {
    static tag = new TagSelector('skill');
    static render(skill: string, trades: string[], tag: IJQuerySelector = TruckSkill.tag): JQuery {
        return tag.create({ text: `${skill} : ${trades.join(', ')}` });
    }
}

export class TruckMarket {
    static tag = new TagSelector('market');
    static render(market: string, districts: string[], tag: IJQuerySelector = TruckMarket.tag): JQuery {
        return tag.create({ text: `${market} : ${districts.join(', ')}` });
    }
}

export class TruckSkills {
    static tag = new TagSelector('skills');
    static render(skills: ISkillSet[], localization: ILocalization, tag: IJQuerySelector = TruckSkills.tag): JQuery {
        let set = tag.create();
        if (!!skills && skills.length > 0) {
            // Convert skillsets into a usable form.
            let sd: IDictionary<string[]> = {};
            skills.forEach((ss) => {
                if (!!sd[ss.Trade.Description]) sd[ss.Trade.Description].push(ss.Skill.Description);
                else sd[ss.Trade.Description] = [ss.Skill.Description];
            });
            Object.keys(sd).forEach((k) => {
                set.append(TruckSkill.render(k, sd[k]));
            });
        }
        else {
            set.append(NoResults.render(localization.noResults_TruckSkills));
        }
        return set;
    }
}

export class TruckMarkets {
    static tag = new TagSelector('markets');
    static render(markets: IOffice[], localization: ILocalization, tag: IJQuerySelector = TruckMarkets.tag): JQuery {
        let set = tag.create();

        if (!!markets && markets.length > 0) {
            // Convert markets into a usable form.
            let md: IDictionary<string[]> = {};
            markets.forEach((m) => {
                let p = m.Description.indexOf('(');
                let office = p > -1 ? m.Description.substr(0, p).trim() : m.Description;

                if (!!md[m.District.Description]) md[m.District.Description].push(office);
                else md[m.District.Description] = [office];
            });
            Object.keys(md).forEach((k) => {
                set.append(TruckMarket.render(k, md[k]));
            });
        }
        else {
            set.append(NoResults.render(localization.noResults_TruckMarkets));
        }
        return set;
    }
}

export class TruckSkillsSection {
    static tag = new TagSelector('fieldset');
    static render(skills: ISkillSet[], localization: ILocalization, tag: IJQuerySelector = TruckSkillsSection.tag): JQuery {
        return tag.create()
            .append(Legend.render(localization.section_TruckSkills))
            .append(TruckSkills.render(skills, localization));
    }
}

export class TruckMarketsSection {
    static tag = new TagSelector('fieldset');
    static render(markets: IOffice[], localization: ILocalization, tag: IJQuerySelector = TruckMarketsSection.tag): JQuery {
        return tag.create()
            .append(Legend.render(localization.section_TruckMarkets))
            .append(TruckMarkets.render(markets, localization));
    }
}

export class TruckAccessSection {
    static tag = new TagSelector('fieldset');
    static async render(crewMember: ICrewMember, localization: ILocalization, getOrAddTruckAccessControl: (crewMember: ICrewMember) => TruckAccess, tag: IJQuerySelector = TruckAccessSection.tag): Promise<JQuery> {
        return tag.create()
            .append(Legend.render(localization.section_TruckPortalAccess))
            .append(await getOrAddTruckAccessControl(crewMember).render());
    }
}

export class TruckCrewMember {
    static tag = new TagSelector('crewmember');
    static render(crewMember: ICrewMember, localization: ILocalization, tag: IJQuerySelector = TruckCrewMember.tag): JQuery {
        return tag.create({ 'data-id': crewMember.Id })
            .append(CrewMemberIcon.render())
            .append(CardTitle.render(`${crewMember.LastName}, ${crewMember.FirstName}`));
    }
}

export class Truck {
    static tag = new TagSelector('truck');
    static async render(crewMember: ICrewMember, localization: ILocalization, mapRedirectURL: string, getOrAddTruckAccessControl: (crewMember: ICrewMember) => TruckAccess, tag: IJQuerySelector = Truck.tag): Promise<JQuery> {
        let truck = tag.create({ 'data-id': crewMember.Id })
            .append(TruckIcon.render())
            .append(CardTitle.render(`${crewMember.LastName}, ${crewMember.FirstName}`))
            .append(Address.render(crewMember.Truck.Address))
            .append(TruckContactInfo.render(crewMember.Truck, localization)
                .append(MapLink.render(crewMember.Truck.Address, localization.button_OpenMap, localization.button_OpenMap, mapRedirectURL))
            );
        if (crewMember.Truck.EmailIsUnique) {
            truck.append(await TruckAccessSection.render(crewMember, localization, getOrAddTruckAccessControl))
        }
        truck.append(TruckMarketsSection.render(crewMember.Truck.Markets, localization));
        truck.append(TruckSkillsSection.render(crewMember.Truck.SkillSets, localization));
        return truck;
    }
}

export class TruckContactInfo {
    static tag = new TagSelector('div');
    static render(truck: ITruck, localization: ILocalization, tag: IJQuerySelector = TruckContactInfo.tag): JQuery {
        let element = tag.create();
        if (Helper.hasValue(truck.Phone))
            element.append(PhoneNumber.render({ PhoneNumber: truck.Phone, Type: PhoneTypes.Mobile }, localization));
        if (Helper.hasValue(truck.Email))
            element.append(Email.render(truck.Email));
        if (Helper.hasValue(truck.Email2))
            element.append(Email.render(truck.Email2));

        return element;
    }
}

export class SubcontractorCrewMember {
    static async render(crewMember: ICrewMember, localization: ILocalization, mapRedirectURL: string, getOrAddTruckAccessControl: (crewMember: ICrewMember) => TruckAccess): Promise<JQuery> {
        return !!crewMember.Truck ? await Truck.render(crewMember, localization, mapRedirectURL, getOrAddTruckAccessControl) : TruckCrewMember.render(crewMember, localization);
    }
}