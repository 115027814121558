import { Helper } from "./Utilities/Helper";
import { IDictionary } from "./Common/IDictionary";

declare global {
    interface Window {
        AppSession: IDictionary<string>;
    }
}
export class AppSession {
    static getData(control: string, property: string) { return window.AppSession[`${control}_${property}`]; }
    //static setData(control: string, property: string, value: any) { window.AppSession[`${control}_${property}`] = value; }

    static asString(control: string, property: string) { return Helper.toString(AppSession.getData(control, property)); }
    static asNumber(control: string, property: string) { return Helper.toNumber(AppSession.getData(control, property)); }
    static asBool(control: string, property: string) { return Helper.toBoolean(AppSession.getData(control, property)); }
    static asDate(control: string, property: string) { return Helper.toDate(AppSession.getData(control, property)); }

    static setData(control: string, property: string, val: string | number | boolean | Date) {
        if (!!val) {
            window.AppSession[`${control}_${property}`] = val.toString();
        }}
}