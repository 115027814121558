
export class Diagnotics {

    //https://developer.mozilla.org/en-US/docs/Web/API/Screen_Capture_API/Using_Screen_Capture
    static async screenCapture(displayMediaOptions: any) {
        let captureStream = null;

        try {
            captureStream = await (navigator.mediaDevices as any).getDisplayMedia(displayMediaOptions);
        } catch (err) {
            console.error("Error: " + err);
        }
        return captureStream;
    }
}