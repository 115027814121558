

import { Helper } from './Helper'


export class Phone {

    constructor(public phoneNumber: string, public areaCode?: string, public dnis?: string, public ext?: string, public countryCode?: string, public remainder?: string, private original?: string) {

    }
    public static formatPhone(src: string | number): string {
        let p = Phone.parse(src);
        return !!p ? p.toDisplay() : null;
    }
    public static getDialNumber(src: string | number, dialLongDistance = false): string {
        let p = Phone.parse(src);
        return !!p ? p.toDialNumber(dialLongDistance) : null;
    }

    public static parse = (src: string | number): Phone => {
        const phoneReg = /(?:(?:(?:(\d{4})#){0,1}(?:(?:\+|(?:9\+)|(?:011)|(?:9011))[^\d#\+\n]{0,2}(\d{2,3})){0,1}9*1*[^\d#\+\n]{0,2}(\d{3})[^\d#\+\n]{0,2}9*1*[^\d#\+\n]{0,2}(\d{3})[^\d#\+\n]{0,2}(\d{4,5}))|9(\d{7})(?:[^\d]|$)|(\d{3})(?:-)(\d{4})(?:[^\d]|$)|(\d{3,7})(?:[^\d]|$))(?:[^\d#\+\n]{0,2}(?:(?:ext)|(?:extn)|(?:ete))[^\d#\+\n]{0,2}(\d*)){0,1}/i;
        if (src === undefined || src === null || src.toString().toLowerCase() === 'nan')
            return null;
        let num = src.toString();
        let match = phoneReg.exec(num);
        if (!match) return null;
        return new Phone(
            /*phoneNumber:*/(match[4] + match[5]) || match[6] || (match[7] + match[8]) || match[9] || null,
            /*areaCode:*/ match[3] || null,
            /*dnis:*/ match[1] || null,
            /*ext:*/ match[10] || null,
            /*countryCode:*/ match[2] || null,
            /*remainder:*/ num.substring(num.indexOf(match[0]) + match[0].length).trim(),
            /*original:*/ num
        );
    }

    public toDisplay(): string {
        let d = this;
        if (!d || !d.phoneNumber) return null;

        const p = d.phoneNumber.replace(/(\d\d\d)(\d\d\d\d)/, '$1-$2');
        let o = "";

        if (!!d.countryCode) o += `+${d.countryCode} `;
        if (!!d.areaCode) o += `(${d.areaCode}) `;
        if (!!d.phoneNumber) o += p;
        if (!!d.dnis) o += ` DNIS: ${d.dnis}`;
        if (!!d.ext) o += ` ext. ${d.ext}`;
        if (!!d.remainder) o += ` ${d.remainder}`;
        return o;
    }

    public toDialNumber(dialLongDistance = false): string {
        let d = this;
        let longDistance = dialLongDistance ? '1' : '';
        if (!d || !d.phoneNumber) return null;

        if (!!d.countryCode && !!d.areaCode && !!d.phoneNumber) return `9011${d.countryCode}${d.areaCode}${d.phoneNumber}`;
        else if (!!d.areaCode && !!d.phoneNumber) return `9${longDistance}${d.areaCode}${d.phoneNumber}`;
        else if (!!d.phoneNumber && d.phoneNumber.length === 7) return `9${d.phoneNumber}`;
        else if (!!d.phoneNumber) return d.phoneNumber;
        return null;
    }

    public getDnis(g: any): number {
        return Helper.toNumber(this.dnis);
    }

}
