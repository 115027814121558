
import dateFormat from "dateformat";
import { TagSelector, IdSelector, ClassSelector } from "../Common/Tag";
import { IWorkOrder, IPerson, TimeBlockStatuses, IPaymentRates, IItem, IProjectCoordinator, IProjectSupervisor, PhoneTypes, ICPC } from "../Service/ServicesModels";
import { ILocalization } from "../Common/ILocalizations";
import { Helper } from "../Utilities/Helper";
import { Address, PhoneNumber, MapLink, Email } from "./Contacts";
import { Legend, CardTitle, CardContent, LinkContent, DocumentsIcon, Bold, Span } from "./Generics";


export class WorkOrderCard {

    static tag = new TagSelector('workorder-card');
    static canceledWorkOrder = new ClassSelector('canceled', 'workorder-card');
    static newWorkOrder = new ClassSelector('new', 'workorder-card');

    static render = (wo: IWorkOrder, l: ILocalization): JQuery => {

        let pending = wo.Status.isIn([TimeBlockStatuses.Pending]),
            title = pending ? `Pending in ${wo.Customer.Address.City}, ${wo.Customer.Address.State} : ${wo.Job.Product.FriendlyName}` : `${wo.Customer.LastName}, ${wo.Customer.FirstName} : ${wo.Job.Product.FriendlyName}`;

        let card = WorkOrderCard.tag.create({ 'data-id': wo.Id })
            .append(CardTitle.render(title))
            .append(CardContent.render(`${l.label_Job} ${wo.Job.Id}`))
            .append(CardContent.render(`${l.label_WorkOrder} ${wo.Id}`))
            .append(CardContent.render(`${l.label_StartOn} ${dateFormat(Helper.toDate(wo.ScheduledStart), 'dddd mm/dd')} ${l.to} ${dateFormat(Helper.toDate(wo.ScheduledEnd), 'dddd mm/dd')}`))
            ;

        if (wo.Status.isIn([TimeBlockStatuses.Canceled, TimeBlockStatuses.Rejected])) {
            card.addClass(WorkOrderCard.canceledWorkOrder.cls);
        }
        if (pending) {
            card.addClass(WorkOrderCard.newWorkOrder.cls);
        }

        return card;
    }
}


export class WorkOrderDocumentsLink {
    static tag = new TagSelector('a');
    static render(id: number, page: string, localization: ILocalization): JQuery {
        return WorkOrderDocumentsLink.tag.create({ href: `${page}!id=${id}` })
            .append(DocumentsIcon.render())
            .append(LinkContent.render(localization.documents));
    }
}

export class WorkOrderDetail {
    static tag = new TagSelector('workorder-detail');
    static render = (wo: IWorkOrder, l: ILocalization, workOrderTypes: string[]): JQuery => WorkOrderDetail.tag.create()
        .append(CardContent.render()
            .append(Bold.render(`${l.label_Job} `))
            .append(Span.render(`${wo.Job.Id} : ${wo.Job.Product.FriendlyName}`))
        )
        .append(CardContent.render()
            .append(Bold.render(`${l.label_WorkOrder} `))
            .append(Span.render(`${wo.Id} : ${workOrderTypes[wo.Type]}`))
        )
        .append(CardContent.render()
            .append(Bold.render(`${l.label_StartOn} `))
            .append(Span.render(`${dateFormat(Helper.toDate(wo.ScheduledStart), 'dddd mm/dd | hh:MM TT')} ${l.to} ${dateFormat(Helper.toDate(wo.ScheduledEnd), 'dddd mm/dd | hh:MM TT')}`))
        );
}

export class WorkOrderAcknowledgement {
    static tag = new IdSelector('acknowledgement', 'fieldset');
    static render(localization: ILocalization): JQuery {
        return WorkOrderAcknowledgement.tag.create()
            //.append(Legend.render(localization.section_Acknowledgement))
            .append(WorkOrderAcknowledgementStatement.render(localization))
            .append(WorkOrderAcknowledgementButton.render(localization))
            .append(WorkOrderDeclineButton.render(localization));
    }
}

export class WorkOrderAcknowledgementStatement {
    static tag = new TagSelector('b');
    static render(localization: ILocalization): JQuery {
        return WorkOrderAcknowledgementStatement.tag.create({ text: localization.acknowledgementStatement });
    }
}

export class WorkOrderAcknowledgementButton {
    static tag = new IdSelector('acknowledge-btn', 'button');
    static render(localization: ILocalization): JQuery {
        return WorkOrderAcknowledgementButton.tag.create({ 'class': 'k-button k-green', text: localization.button_Acknowledgement });
    }
}

export class WorkOrderDeclineButton {
    static tag = new IdSelector('reject-btn', 'button');
    static render(localization: ILocalization): JQuery {
        return WorkOrderDeclineButton.tag.create({ 'class': 'k-button k-red', text: localization.button_Decline });
    }
}

export class WorkOrderCustomer {
    static tag = new TagSelector('workorder-customer');
    static render(customer: IPerson, status: TimeBlockStatuses, mapUrl: string, localization: ILocalization): JQuery {
        let c: JQuery = WorkOrderCustomer.tag.create();
        if (status.isIn([TimeBlockStatuses.Accepted, TimeBlockStatuses.Completed, TimeBlockStatuses.Rescheduled])) {
            c.append($('<h4>', { text: `${customer.LastName}, ${customer.FirstName}` }))
            c.append(Address.render(customer.Address));
            customer.Phones.forEach((p) => { c.append(PhoneNumber.render(p, localization)); });
            c.append(MapLink.render(customer.Address, localization.button_OpenMap, localization.button_OpenMap, mapUrl))
        }
        else {
            //c.append(Address.renderPending(customer.Address));
            c.append(Address.render(customer.Address));
        }
        return c;
    }
}


export class WorkOrderPaymentRates {
    static tag = new IdSelector('workorder-paymentrates', 'fieldset');
    static render(paymentRates: IPaymentRates, localization: ILocalization): JQuery {
        return WorkOrderPaymentRates.tag.create()
            .append(Legend.render(localization.section_PaymentRates))
            .append(PaymentRateValue.render(paymentRates.LaborAmount, localization.label_Labor))
            .append(PaymentRateValue.render(paymentRates.TripChargeAmount, localization.label_TripCharge))
            .append(PaymentRateValue.render(paymentRates.CashTicketAmount, localization.label_CashTickets))
            .append(PaymentRateValue.render(paymentRates.TotalAmount, localization.label_Total).addClass('total'));
    }
}

export class PaymentRateValue {
    static tag = new TagSelector('paymentratevalue');
    static render(amount: number, label: string): JQuery {
        return PaymentRateValue.tag.create({ html: `<value>${label}</value><amount>${amount.formatUSD()}</amount>` });
    }
}

export class WorkOrderItems {
    static tag = new IdSelector('workorder-items', 'fieldset');
    static render(items: IItem[], localization: ILocalization): JQuery {
        let woitems = WorkOrderItems.tag.create()
            .append(Legend.render(localization.section_Items));
        items.forEach((i) => { woitems.append(WorkOrderItem.render(i, localization)); });
        return woitems;
    }
}

export class WorkOrderItem {
    static tag = new TagSelector('workorder-item');
    static render(item: IItem, localization: ILocalization): JQuery {
        let detail = `<div>${item.Details || localization.noDetailDescription}</div>`;
        return WorkOrderItem.tag.create({ html: `<h4>${item.VendorCode} : ${item.LaborItem}</h4><p>${item.LaborSubItem || ''}</p><p>${localization.measure}: ${item.Measure}</p><p>${localization.quantity}: ${item.Quantity.format()}</p>${detail}` })
    }
}

export class WorkOrderProjectCoordinator {
    static tag = new IdSelector('project-coordinator', 'fieldset');
    static render(pc: IProjectCoordinator, localization: ILocalization): JQuery {
        let self = this,
            element = WorkOrderProjectCoordinator.tag.create();

        element
            .append(Legend.tag.create({ html: localization.section_ProjectCoordinator }))
            .append(Span.tag.create({ html: `${pc.Person.FirstName} ${pc.Person.LastName}` }))

        if (!!pc.Phone) element.append(PhoneNumber.render({ PhoneNumber: pc.Phone, Type: PhoneTypes.Business }, localization));
        if (!!pc.MobilePhone) element.append(PhoneNumber.render({ PhoneNumber: pc.MobilePhone, Type: PhoneTypes.Mobile }, localization));
        if (!!pc.Email) element.append(Email.render(pc.Email));
        return element;
    }
}

export class WorkOrderProjectSupervisor {
    static tag = new IdSelector('project-supervisor', 'fieldset');
    static render(ps: IProjectSupervisor, localization: ILocalization): JQuery {
        let self = this,
            element = WorkOrderProjectSupervisor.tag.create();

        element
            .append(Legend.tag.create({ html: localization.section_ProjectSupervisor }))
            .append(Span.tag.create({ html: `${ps.Contact.FirstName} ${ps.Contact.LastName}` }))
        if (!!ps.MobilePhone) element.append(PhoneNumber.render({ PhoneNumber: ps.MobilePhone, Type: PhoneTypes.Mobile }, localization));
        if (!!ps.Fax) element.append(PhoneNumber.render({ PhoneNumber: ps.Fax, Type: PhoneTypes.Fax }, localization));
        if (!!ps.Email) element.append(Email.render(ps.Email));

        return element;
    }
}

export class WorkOrderCPC {
    static tag = new IdSelector('cpc', 'fieldset');
    static render(cpc: ICPC, localization: ILocalization): JQuery {
        return WorkOrderCPC.tag.create()
            .append(Legend.tag.create({ html: localization.section_CPC }))
            .append(PhoneNumber.render({ PhoneNumber: cpc.PhoneNumber, Type: PhoneTypes.Business }, localization))
            .append(PhoneNumber.render({ PhoneNumber: cpc.Fax, Type: PhoneTypes.Fax }, localization))
            ;
    }
}