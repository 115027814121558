import { PageBase } from "./PageBase";
import { IDictionary } from "../Common/IDictionary";
import { Helper } from "../Utilities/Helper";
import { IAppUser } from "../App";
import { TagSelector } from "../Common/Tag";


export class ErrorPage extends PageBase {

    constructor(private user: IAppUser) {
        super(new TagSelector('errorpage'));
    }

    _message: string;
    _eventData: any;

    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;

        if (! await super.load(parameters)) return false;
        self._message = Helper.toString(parameters.message);
        self._eventData = parameters.eventData;
    }


    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        $('<h2>', { text: `It appears that we encountered a problem...` }).appendTo(element);
        $('<p>', { text: `${self._message}` }).appendTo(element);
        if (!!self.user && self.user.isDeveloper) (await self._renderStackTrace()).appendTo(element);

        return element;
    }

    public async _renderStackTrace(): Promise<JQuery> {
        let self = this;
        return $('<div>', {})
            .append($('<p>', { text: `(${self._eventData.exceptionType || self._eventData.ExceptionType}) ${self._eventData.exceptionMessage || self._eventData.ExceptionMessage}` }))
            .append($('<p>', { text: self._eventData.stackTrace || self._eventData.StackTrace }));
    }
}