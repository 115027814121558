

export class Subscription {
    private _subscriptions: ISubscription[];

    /** This creates a NON-DOM event handler. This enable event triggers that are not tied to a DOM Element. */
    constructor() {
        this._subscriptions = [];
    }

    /**
     * Trigger this event.  This will call the subscribed handlers for this event.
     * @param caller
     * @param eventData
     */
    public trigger(caller: any, eventData: any): void {
        let self = this;
        let len = this._subscriptions.length;
        for (var i = 0; i < len; i++) {
            let subscription = self._subscriptions[i];
            try {
                subscription.handler(caller, eventData);
            }
            catch (e) {
                    console.error(e);
            }
        }
    };

    /**
     * ***Important*** - DO NOT USE THIS DIRECTLY.  Use the "Controls" addSubscription method.  This
     * will aid the automatic clean-up of subscriptions when a control is disposed.
     * @param subscriber the control subscribing to this non-dom event.
     * @param handler
     */
    public subscribe(subscriber: any, handler: (caller: any, eventData: any)=>void): Subscription {
        let len = this._subscriptions.length;
        for (var i = 0; i < len; i++) {
            let s = this._subscriptions[i];
            if (s.subscriber === subscriber && s.handler !== handler) {
                s.handler = handler;
                return;
            }
            else if (s.subscriber === subscriber) {
                return;
            }
        }
        this._subscriptions.push({ subscriber: subscriber, handler: handler });
        return this;
    }

    /**
     * Releases all registered handlers for the subscriber.
     * @param subscriber
     */
    public unsubscribe(subscriber: any): void {
        let len = this._subscriptions.length;
        for (var i = 0; i < len; i++) {
            let s = this._subscriptions[i];
            if (s.subscriber === subscriber) {
                this._subscriptions.slice(i, 1);
                return;
            }
        }
    }
}

interface ISubscription {
    subscriber: any;
    handler: (caller: any, eventData: any) => void;
}