import { PageBase } from "./PageBase";
import { IDictionary } from "../Common/IDictionary";
import { IStartPageCounts, LeadStatuses, ISubcontractor, PhoneTypes } from "../Service/ServicesModels";
import { ServiceHandler } from "../Service/ServiceHandler";
import { Helper } from "../Utilities/Helper";
import { TagSelector } from "../Common/Tag";
import { ILocalization } from "../Common/ILocalizations";
import { PhoneNumber, Email } from "../Partials/Contacts";

export class StartPage extends PageBase {
    static tag = new TagSelector('startpage');

    constructor(private localization: ILocalization, private isSales: boolean, private subcontractorCrewLeadId: number, private service: ServiceHandler, private notificationsHashtag: string, private leadsHashtag: string, private workOrdersHashtag: string, private schedulingHashtag: string, private userManualLink: string, private leadReferralLink: string) {
        super(StartPage.tag);
    }

    _counts: IStartPageCounts = {
        NewLeads: 0,
        NewNotifications: 0,
        NewWorkOrders: 0
    };
    _subcontractor: ISubcontractor = null;

    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;
        if (! await super.load(parameters)) return false;

        self._counts = await self.service.getCounts();
        self._subcontractor = await self.service.getSubcontractor();
    }

    public async init(): Promise<boolean> {
        let self = this;
        if (! await super.init()) return false;


        return true;
    }


    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        if (!self._subcontractor.IsInCompliance) {
            $('<out-of-compliance>', {})
                .append($('<i>', { 'class': 'fa fa-exclamation' }))
                .append($('<p>', { text: self.localization.out_of_Compliance }))
                .append($('<i>', { 'class': 'fa fa-exclamation' }))
                .appendTo(element);
        }

        element.append(await self._renderSubcontractor());

        let notifications = $('<a>', { href: `${self.notificationsHashtag}` })
            .appendTo(element)
            .append($('<icon>', { 'class': 'notifications' }))
            .append($('<label>', { text: self.localization.header_Notifications }));
        if (self._counts.NewNotifications > 0) $('<count>', { text: self._counts.NewNotifications }).appendTo(notifications);

        if (self.isSales) {
            let leadsParameters: string[] = [`status=${LeadStatuses.AllActive}`];
            if (self.subcontractorCrewLeadId > 0) leadsParameters.push(`truck=${self.subcontractorCrewLeadId}`);
            let leads = $('<a>', { href: `${self.leadsHashtag}!${leadsParameters.join('&')}` })
                .appendTo(element)
                .append($('<icon>', { 'class': 'leads' }))
                .append($('<label>', { text: self.localization.header_Leads }));
            if (self._counts.NewLeads > 0) $('<count>', { text: self._counts.NewLeads }).appendTo(leads);
        }

        let workOrders = $('<a>', { href: `${self.workOrdersHashtag}` })
            .appendTo(element)
            .append($('<icon>', { 'class': 'work_orders' }))
            .append($('<label>', { text: self.localization.header_WorkOrders }));
        if (self._counts.NewWorkOrders > 0) $('<count>', { text: self._counts.NewWorkOrders }).appendTo(workOrders);

        let scheduling = $('<a>', { href: `${self.schedulingHashtag}` })
            .appendTo(element)
            .append($('<icon>', { 'class': 'scheduling' }))
            .append($('<label>', { text: self.localization.header_Scheduling }));

        let manual = $('<a>', { href: `${self.userManualLink}`, target: `_blank` })
            .appendTo(element)
            .append($('<icon>', { 'class': 'manual' }))
            .append($('<label>', { text: self.localization.header_UserManual }));

        let leadReferral = $('<a>', { href: `${self.leadReferralLink}/${self._subcontractor.Id}`, target: `_blank` })
            .appendTo(element)
            .append($('<icon>', { 'class': 'leads' }))
            .append($('<label>', { text: self.localization.header_LeadReferral }));
        return self.element;
    }

    public async _renderSubcontractor(): Promise<JQuery> {
        let self = this;
        let name = Helper.hasValue(self._subcontractor.Contact.BusinessName) ? self._subcontractor.Contact.BusinessName :
            `${self._subcontractor.Contact.LastName}, ${self._subcontractor.Contact.FirstName} ${self._subcontractor.Contact.MiddleName}`;

        let sub = $('<subcontractor>', {})
            .append($('<h4>', { text: `${name}` }))
            .append(await self._renderAddress());

        let phones = $('<div>', {}).appendTo(sub);
        if (Helper.hasValue(self._subcontractor.WorkPhone))
            phones.append(PhoneNumber.render({ PhoneNumber: self._subcontractor.WorkPhone, Type: PhoneTypes.Business }, self.localization));

        if (Helper.hasValue(self._subcontractor.MobilePhone))
            phones.append(PhoneNumber.render({ PhoneNumber: self._subcontractor.MobilePhone, Type: PhoneTypes.Mobile }, self.localization));

        if (Helper.hasValue(self._subcontractor.Fax))
            phones.append(PhoneNumber.render({ PhoneNumber: self._subcontractor.Fax, Type: PhoneTypes.Fax }, self.localization));

        if (Helper.hasValue(self._subcontractor.Pager))
            phones.append(PhoneNumber.render({ PhoneNumber: self._subcontractor.Pager, Type: PhoneTypes.Pager }, self.localization));

        if (Helper.hasValue(self._subcontractor.Email))
            phones.append(Email.render(self._subcontractor.Email));

        $('<id>', { text: `${self.localization.label_Id} ${self._subcontractor.Id}` }).appendTo(sub);

        return sub;
    }
    async _renderAddress(): Promise<JQuery> {
        let self = this;
        let address = $('<address>', {});

        $('<p>', { text: self._subcontractor.Address.StreetLine }).appendTo(address);
        if (!!self._subcontractor.Address.StreetLine2) {
            $('<p>', { text: self._subcontractor.Address.StreetLine2 }).appendTo(address);
        }
        $('<p>', { text: `${self._subcontractor.Address.City}, ${self._subcontractor.Address.State} ${self._subcontractor.Address.Zip}` }).appendTo(address);


        return address;
    }
}