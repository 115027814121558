import { PageBase } from "./PageBase";
import { IDictionary } from "../Common/IDictionary";
import { TagSelector } from "../Common/Tag";

export class AdministrationPage extends PageBase {

    constructor() {
        super(new TagSelector('administrationpage'));
    }

    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;
        if (! await super.load(parameters)) return false;


        return true;
    }

    public async init(): Promise<boolean> {
        let self = this;
        if (! await super.init()) return false;


        return true;
    }


    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;


        return self.element;
    }
}