
//import * as jQuery from "jquery";
import $ from 'jquery';
window.$ = window.jQuery = $;

//import "jquery-ui";

//import "@progress/kendo-ui";

// Import prototypes
import "./Prototypes/Html";
import "./Prototypes/Date";
import "./Prototypes/Strings";
import "./Prototypes/Objects";
import "./Prototypes/Numbers";
import "./Prototypes/jQueryExtensions";
import "./Prototypes/Array";

import '../node_modules/@progress/kendo-ui/js/kendo.dialog';
import '../node_modules/@progress/kendo-ui/js/kendo.datetimepicker';
import '../node_modules/@progress/kendo-ui/js/kendo.comboBox';
import '../node_modules/@progress/kendo-ui/js/kendo.dropdownlist';

import { NetworkMonitor } from "./Views/NetworkMonitor";
import { Control } from "./Common/Control";
import { Diagnotics } from "./Utilities/Diagnostic";
import { LeadSearchPage } from "./Pages/LeadSearchPage";
import { NotFoundPage } from "./Pages/NotFoundPage";
import { Header } from "./Views/Header";
import { Footer } from "./Views/Footer";
import { NotificationSearchPage } from "./Pages/NotificationSearchPage";
import { WorkOrderSearchPage } from "./Pages/WorkOrderSearchPage";
import { TrucksPage } from "./Pages/TrucksPage";
import dateFormat, { DateFormatI18n } from "dateformat";
import { LeadsPage } from "./Pages/LeadsPage";
import { DeveloperPage } from "./Pages/DeveloperPage";
import { AdministrationPage } from "./Pages/AdministrationPage";
import { PageNavigation } from "./Views/PageNavigation";
import { IDictionary } from "./Common/IDictionary";
import { WorkOrderPage } from "./Pages/WorkOrderPage";
import { StartPage } from "./Pages/StartPage";
import { ServiceHandler } from "./Service/ServiceHandler";
import { UnauthorizedUserPage } from "./Pages/UnauthorizedUserPage";
import { DocumentViewPage } from "./Pages/DocumentViewerPage";
import { WorkOrderDocumentsPage } from "./Pages/WorkOrderDocumentsPage";
import { LeadsDocumentsPage } from "./Pages/LeadsDocumentsPage";
import { ErrorPage } from "./Pages/ErrorPage";
import { ILocalization, defaults, Localization } from "./Common/ILocalizations";
import { SchedulerPage } from "./Pages/SchedulerPage";
import { UserRegistrationPage } from './Pages/UserRegistrationPage';
import { ICPC, IWorkOrder } from './Service/ServicesModels';
import { ImpersonationPage } from './Pages/ImpersonationPage';
import { NotificationAdminPage } from './Pages/NotificationAdminPage';
import { NotificationEditPage } from './Pages/NotificationEditPage';



class App extends Control {
    constructor(public config: IAppConfig) {
        super();
        let self = this;
        dateFormat.i18n = self.config.calendars;
    }

    public async init(): Promise<boolean> {
        let self = this;
        try {
            self.config.localization = Localization.parse(self.config.localization);

            await self._initNetworkMonitor();
            self._initService();
            self._initPageNavigation();
            self._initHeader();
            self._initFooter();

            if (!await super.init()) return false;
            return true;
        }
        catch (ex) {
            console.log(ex);
            return false;
        }
    }

    // #region Service

    _service: ServiceHandler = null;
    _initService() {
        let self = this;
        self._service = new ServiceHandler(self.config.links.tokenRefresh.href, self.config.serviceRoot, self.config.token);

        self.addSubscription('onAuthorizationExpired', self._service.onAuthorizationExpired, (caller, eventData) => {
            let dialog = $('<div>', {}).appendTo($('body'));
            self.kendoControls['onValidationError_dialog'] = dialog.kendoDialog({
                title: "Expired Session",
                closable: false,
                modal: true,
                content: `<p>Your login has expired.  Attempt to refresh?</p>`,
                actions: [
                    { text: 'Refresh', primary: true }
                ],
                close: (e) => {
                    self.kendoControls['onValidationError_dialog'].destroy();
                    dialog.remove();
                    window.location.reload();
                }
            }).data('kendoDialog');
        });

        self.addSubscription('onValidationError', self._service.onValidationError, (caller, eventData) => {
            let dialog = $('<div>', {}).appendTo($('body')),
                content: string[] = [`<p>${eventData.message || eventData.Message}</p>`];
            if (!!eventData && !!eventData.ModelState) {
                content = [];
                Object.keys(eventData.ModelState).forEach((key) => {
                    content.push(`<p>${eventData.ModelState[key]}</p>`)
                });
            }
            self.kendoControls['onValidationError_dialog'] = dialog.kendoDialog({
                title: "Validation Error",
                closable: true,
                modal: true,
                content: content.join(''),
                actions: [
                    { text: 'Close', primary: true }
                ],
                close: (e) => {
                    self.kendoControls['onValidationError_dialog'].destroy();
                    dialog.remove();
                }
            }).data('kendoDialog');
        });

        self.addSubscription('onSystemError', self._service.onSystemError, (caller, eventData) => {
            self.navigation.showPage(self.navigation.getPage('error'), { eventData: eventData, message: eventData.message || eventData.Message });
        });

    }

    // #endregion 

    // #region Header

    public get header(): Header {
        return this.children['header'] as Header;
    }
    public set header(val: Header) {
        this.setOrResetChild('header', val);
    }

    public async _initHeader() {
        let self = this,
            localization = self.config.localization,
            links = self.config.links,
            user = self.config.user,
            subcontractorId = user ? user.subcontractorId : 0;

        if (!!user) {
            self.header = new Header({
                home: { enable: true, href: self.config.appRoot, label: null },
                logoff: { enable: true, href: links.logout.href, label: links.logout.text },
                administrationArea: {
                    enable: user.isAdmin, href: '#administration', label: localization.header_Administration,
                    changeSubcontractor: { enable: user.isDeveloper || user.isAdmin, href: '#impersonation', label: localization.header_ChangeSubcontractor },
                    notifications: { enable: user.isDeveloper || user.isAdmin, href: '#notifications_admin', label: localization.header_Notifications }
                },
                developerArea: { enable: user.isDeveloper, href: '#developer', label: localization.header_Developer },
                leads: { enable: user.isSales /*|| user.isPrinciple*/, href: '#leads_search', label: localization.header_Leads },
                leadReferral: { enable: true, href: `${links.leadReferral.href}/${subcontractorId}`, label: localization.header_LeadReferral },
                notifications: { enable: user.isTruck || user.isPrinciple, href: '#notifications_search', label: localization.header_Notifications },
                trucks: { enable: user.isPrinciple, href: '#trucks', label: localization.header_Trucks },
                workOrders: { enable: user.isTruck || user.isPrinciple, href: '#work_orders_search', label: localization.header_WorkOrders },
                scheduling: { enable: user.isTruck || user.isPrinciple, href: '#scheduling', label: localization.header_Scheduling }
            });
        }
        else {
            self.header = new Header({
                home: { enable: false, href: self.config.appRoot, label: null },
                logoff: { enable: false, href: links.logout.href, label: links.logout.text },
                administrationArea: {
                    enable: false, href: '#administration', label: localization.header_Administration,
                    changeSubcontractor: { enable: false, href: '#impersonation', label: localization.header_ChangeSubcontractor },
                    notifications: { enable: false, href: '#notifications_admin', label: localization.header_Notifications }
                },
                developerArea: { enable: false, href: '#developer', label: localization.header_Developer },
                leads: { enable: false, href: '#leads_search', label: localization.header_Leads },
                leadReferral: { enable: false, href: `${links.leadReferral.href}`, label: localization.header_LeadReferral },
                notifications: { enable: false, href: '#notifications_search', label: localization.header_Notifications },
                trucks: { enable: false, href: '#trucks', label: localization.header_Trucks },
                workOrders: { enable: false, href: '#work_orders_search', label: localization.header_WorkOrders },
                scheduling: { enable: false, href: '#scheduling', label: localization.header_Scheduling }
            });
        }
        self.header.get().replaceWith(await self.header.render());
    }

    // #endregion

    // #region Footer

    public get footer(): Footer {
        return this.children['footer'] as Footer;
    }
    public set footer(val: Footer) {
        this.setOrResetChild('footer', val);
    }

    public async _initFooter() {
        let self = this;

        self.footer = new Footer({
            copyright: self.config.copyright,
            caPrivacyPolicyLink: self.config.links.caPrivacyPolicy,
            licenseLink: self.config.links.license,
            privacyPolicyLink: self.config.links.privacyPolicy,
            termsOfUseLink: self.config.links.termsOfUse,
            version: self.config.version
        });
        self.footer.get().replaceWith(await self.footer.render());
    }

    // #endregion

    // #region page navigation


    public get navigation(): PageNavigation {
        return this.children['navigation'] as PageNavigation;
    }
    public set navigation(val: PageNavigation) {
        this.setOrResetChild('navigation', val);
    }

    _initPageNavigation() {
        let self = this,
            localization = self.config.localization;

        self.navigation = new PageNavigation(self.config.user, localization);

        self.navigation.mapPage('not_found', () => { return new NotFoundPage(); });
        self.navigation.mapPage('error', () => { return new ErrorPage(self.config.user); });
        self.navigation.mapPage('developer', () => { return new DeveloperPage(); });
        self.navigation.mapPage('administration', () => { return new AdministrationPage(); });
        self.navigation.mapPage('unauthorized', () => {
            return new UnauthorizedUserPage(
                { login: self.config.links.login.text, register: 'Register', userManual: self.config.localization.header_UserManual },
                self.config.links.login.href,
                '#register',
                self.config.links.userManual.href,
            );
        });
        self.navigation.mapPage('register', () => {
            let pg = new UserRegistrationPage(self._service, localization, self.config.links.forgotPassword.href);
            self.addSubscription('Successful_Registration', pg.onRegistered, (caller, eventdata) => {
                let dialog = $('<div>', {}).appendTo($('body'));
                self.kendoControls['onRegistered_dialog'] = dialog.kendoDialog({
                    title: "Success",
                    closable: true,
                    modal: true,
                    content: `<p>${localization.success_Registration}</p>`,
                    actions: [
                        { text: 'Close', primary: true }
                    ],
                    close: (e) => {
                        self.kendoControls['onRegistered_dialog'].destroy();
                        dialog.remove();
                        window.location.href = '#';
                    }
                }).data('kendoDialog');
            });
            return pg;
        });
        self.navigation.mapPage('start', () => {
            return new StartPage(
                localization,
                self.config.user.isSales /*|| self.config.user.isPrinciple*/,
                self.config.user.subcontractorCrewLeadId,
                self._service,
                '#notifications_search',
                '#leads_search',
                '#work_orders_search',
                '#scheduling',
                self.config.links.userManual.href,
                self.config.links.leadReferral.href,
            );
        });
        self.navigation.mapPage('leads', () => {
            return new LeadsPage(
                localization,
                self._service,
                '#leads_documents'
            );
        });
        self.navigation.mapPage('leads_search', () => {
            return new LeadSearchPage(
                localization,
                self._service,
                '#leads',
                self.config.user.isPrinciple,
                self.config.user.subcontractorCrewLeadId
            );
        });
        self.navigation.mapPage('notifications_search', () => {
            return new NotificationSearchPage(
                localization,
                self._service,
                '#document_viewer',
                '#work_order',
                self.config.user.isPrinciple,
                self.config.user.subcontractorCrewLeadId
            );
        });
        self.navigation.mapPage('work_orders_search', () => {
            return new WorkOrderSearchPage(
                localization,
                self._service,
                '#work_order',
                self.config.user.isPrinciple,
                self.config.user.subcontractorCrewLeadId
            );
        });
        self.navigation.mapPage('work_order', () => {
            let pg = new WorkOrderPage(
                self.config.user.isPrinciple || self.config.user.isSOW,
                localization,
                self._service,
                '#work_order_documents',
                self.config.cpc
            );
            self.addSubscription('Reject_Confirm', pg.onRejectWorkOrder, (caller, eventdata) => {

                let wo = eventdata as IWorkOrder;
                let dialog = $('<div>', {}).appendTo($('body'));
                self.kendoControls['onRejectWorkOrder_dialog'] = dialog.kendoDialog({
                    title: "Confirming Action",
                    closable: true,
                    modal: true,
                    content: `<p>${localization.confirm_WoRejection}</p>`,
                    actions: [
                        {
                            text: `Yes, decline.`,
                            primary: true,
                            action: async () => {
                                if (await self._service.rejectWorkOrder(wo.Id)) {
                                    window.location.href = '#';
                                }
                            }
                        },
                        { text: 'Cancel' }
                    ],
                    close: (e) => {
                        self.kendoControls['onRejectWorkOrder_dialog'].destroy();
                        dialog.remove();
                    }
                }).data('kendoDialog');
            });

            self.addSubscription('AcknowledgeConfirm', pg.onAcknowledgeWorkOrder, (caller, eventdata) => {

                let wo = eventdata as IWorkOrder;
                let dialog = $('<div>', {}).appendTo($('body'));
                self.kendoControls['onAcknowledgeWorkOrder_dialog'] = dialog.kendoDialog({
                    title: "Confirming Action",
                    closable: true,
                    modal: true,
                    content: `<p>${localization.confirm_WoAcknowledge}</p>`,
                    actions: [
                        {
                            text: `Yes, acknowledge.`,
                            primary: true,
                            action: async () => {
                                if (self._service.acknowledgeWorkOrder(wo.Id)) {
                                    pg.workOrder = await self._service.getWorkOrder(pg.workOrder.Id);
                                    pg.refresh();
                                }
                            }
                        },
                        { text: 'Cancel' }
                    ],
                    close: (e) => {
                        self.kendoControls['onAcknowledgeWorkOrder_dialog'].destroy();
                        dialog.remove();
                    }
                }).data('kendoDialog');
            });
            return pg;
        });
        self.navigation.mapPage('trucks', () => {
            return new TrucksPage(self._service, localization);
        });
        self.navigation.mapPage('document_viewer', () => {
            return new DocumentViewPage(self._service);
        });
        self.navigation.mapPage('work_order_documents', () => {
            return new WorkOrderDocumentsPage(
                self._service,
                localization,
                '#document_viewer',
                (validationError) => {
                    let dialog = $('<div>', {}).appendTo($('body'));
                    self.kendoControls['onWordOrderDocumentValidationError_dialog'] = dialog.kendoDialog({
                        title: "Validation Error",
                        closable: false,
                        modal: true,
                        content: `<p>${validationError}</p>`,
                        actions: [
                            { text: 'Ok', primary: true }
                        ],
                        close: (e) => {
                            self.kendoControls['onWordOrderDocumentValidationError_dialog'].destroy();
                            dialog.remove();
                        }
                    }).data('kendoDialog');
                }
            );
        });
        self.navigation.mapPage('leads_documents', () => {
            return new LeadsDocumentsPage(
                self._service,
                localization,
                '#document_viewer',
                (validationError) => {
                    let dialog = $('<div>', {}).appendTo($('body'));
                    self.kendoControls['onLeadDocumentValidationError_dialog'] = dialog.kendoDialog({
                        title: "Validation Error",
                        closable: false,
                        modal: true,
                        content: `<p>${validationError}</p>`,
                        actions: [
                            { text: 'Ok', primary: true }
                        ],
                        close: (e) => {
                            self.kendoControls['onLeadDocumentValidationError_dialog'].destroy();
                            dialog.remove();
                        }
                    }).data('kendoDialog');
                }
            );
        });
        self.navigation.mapPage('scheduling', () => {
            return new SchedulerPage(self._service, localization, '#work_order', '#leads', self.config.user.isPrinciple, self.config.user.subcontractorCrewLeadId);
        });
        self.navigation.mapPage('impersonation', () => {
            return new ImpersonationPage(self._service, localization, self.config.user.subcontractorId, self.config.user.subcontractorCrewLeadId, self.config.appRoot);
        });
        self.navigation.mapPage('notifications_admin', () => {
            return new NotificationAdminPage(localization, self._service, '#document_viewer', '#notification_edit');
        });
        self.navigation.mapPage('notification_edit', () => {
            return new NotificationEditPage(localization, self._service, self.config.user.samAccount);
        });
    }
    // #endregion

    // #region Offline Detection
    public get networkMonitor(): NetworkMonitor {
        return this.children['networkMonitor'] as NetworkMonitor;
    }
    public set networkMonitor(val: NetworkMonitor) {
        this.setOrResetChild('networkMonitor', val);
    }

    async _initNetworkMonitor(): Promise<boolean> {
        let self = this,
            localization = self.config.localization;
        self.networkMonitor = new NetworkMonitor(localization);
        self.networkMonitor.get().replaceWith(await self.networkMonitor.render());
        self.networkMonitor.init();
        return true;
    }
    // #endregion

    public async capture() {
        return await Diagnotics.screenCapture(undefined);
    }
}


interface IAppConfig {
    user: IAppUser;
    appRoot: string;
    serviceRoot: string; // '~/api/'
    token: string;
    company: string;
    copyright: string;
    links: IAppLinks;
    calendars: DateFormatI18n;
    localization: ILocalization;
    cpc: ICPC;
    version: string;
}



export interface IAppUser {
    name: string;
    samAccount: string;
    subcontractorId: number;
    subcontractorCrewLeadId: number;

    isDeveloper: boolean;
    isPrinciple: boolean;
    isTruck: boolean;
    isSOW: boolean;
    isSales: boolean;
    isAdmin: boolean;
}

export interface IAppLinks {
    tokenRefresh: { href: string; text: string; };
    login: { href: string; text: string; };
    logout: { href: string; text: string; };
    privacyPolicy: { href: string; text: string; };
    caPrivacyPolicy: { href: string; text: string; };
    termsOfUse: { href: string; text: string; };
    license: { href: string; text: string; };
    userManual: { href: string; text: string; };
    leadReferral: { href: string; text: string; };

    forgotPassword: { href: string; text: string; }
}


declare global {
    interface Window {
        AppConfig: IAppConfig;
        App: App;
        $: JQueryStatic;
        jQuery: JQueryStatic;
        MySessionParameters: IDictionary<string>;
    }

    let App: IAppConfig;
}

$(window).ready(function () {
    window.AppSession = {};
    window.MySessionParameters = {};
    window.App = new App(window.AppConfig);
    window.App.init().then(success => { if (!success) alert("Application failed to load properly."); });
});