import { ViewBase } from "./ViewBase";
import { TagSelector } from "../Common/Tag";
import { ILocalization } from "../Common/ILocalizations";

export class NetworkMonitor extends ViewBase {
    static tag = new TagSelector('networkmonitor');
    constructor(private localization: ILocalization) {
        super(NetworkMonitor.tag);
    }


    public get online(): boolean { return navigator.onLine;}

    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!!element) {
            element
                .append($('<i>', { 'class': 'fa fa-wifi' }))
                .append($('<span>', { text: `${self.online ? self.localization.online : self.localization.offline}` }));
        }
        if (!self.online) element.addClass('offline');

        return element;
    }

    public async init(): Promise<boolean> {
        let self = this;
        try {
            window.addEventListener('online', (e) => {
                //self.online = true;
                self.refresh();
            });

            window.addEventListener('offline', (e) => {
                //self.online = false;
                self.refresh();
            });
        }
        catch { return false; }
        return await super.init();
    }

    
}