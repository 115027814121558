import { PageBase } from "./PageBase";
import { IDictionary } from "../Common/IDictionary";
import { TagSelector, ClassSelector } from "../Common/Tag";
import { ServiceHandler } from "../Service/ServiceHandler";
import { ILocalization } from "../Common/ILocalizations";
import { ICalendar, ServiceModels, ICalendarHour, CalendarStatuses, ICalendarDay, ITruck_Sales } from "../Service/ServicesModels";
import { Scheduler, SchedulerDay, SchedulerHour, SchedulerDay_AllDay, ISchedulerDay_AllDay, SchedulerDetail, ISchedulerDay, SchedulerHour_Detail } from "../Partials/Scheduler";
import { Loader, Div, Link } from "../Partials/Generics";
import { FilterSortSection, TruckFilter } from "../Partials/Filters";
import { Helper } from "../Utilities/Helper";
import { Utilities } from "../Utilities/Utilities";
import { AppSession } from "../AppSession";

export class SchedulerPage extends PageBase {
    static tag = new TagSelector('schedulerpage');
    static clsDetailView = new ClassSelector('detailview');

    constructor(private service: ServiceHandler, private localization: ILocalization, private workOrderPageHashTag: string, private leadPageHashTag: string, private isPrinciple: boolean, private _truckId: number = 0) {
        super(SchedulerPage.tag);
        let self = this;
        self.refreshParameters();

    }

    private _calendar: ICalendar;
    private _trucks: ITruck_Sales[] = [];

    get truckId(): number { return this._truckId; }
    set truckId(val: number) { if (this._truckId !== val) { this._truckId = val; AppSession.setData('SchedulerPage', 'truckId', val); this._reload(); } }

    get selectedIndex(): number { return AppSession.asNumber('SchedulerPage', 'selectedIndex') || 0; }
    set selectedIndex(val: number) { AppSession.setData('SchedulerPage', 'selectedIndex', val); }

    public refreshParameters() {
        let self = this;

        self._truckId = AppSession.asNumber('SchedulerPage', 'truckId') || self._truckId || 0;
    }

    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;
        if (! await super.load(parameters)) return false;
        self._trucks = await self.service.getLookupTrucks();

        if (self.truckId === 0 && !!self._trucks && self._trucks.length > 0) {
            // default truckId to first truck.
            self._truckId = self._trucks[0].Id;
        }

        await self._reload();
        //self._calendar = ServiceModels.parseCalendar(await self.service.getCalendar(self.truckId));
        // Update truckId for princples.
        self._truckId = self._calendar.Id;
        AppSession.setData('SchedulerPage', 'truckId', self._truckId);

        return true;
    }

    public async init(): Promise<boolean> {
        let self = this;
        if (! await super.init()) return false;

        if (!!self.element) {
            self.element

                .on('change', TruckFilter.tag.selector, function (e) {
                    self.truckId = Helper.toNumber($(this).val() || null);
                    FilterSortSection.tag.get(self.element).blur();
                })
                .on('click', Scheduler.tag.selector, function () {
                    FilterSortSection.tag.get(self.element).blur();
                })
                .on('click', function (e) {
                    let filters = FilterSortSection.tag.get(self.element);
                    if ($(e.target).is(filters) ||
                        $(e.target).is(filters.children('div'))) {
                        self.element.toggleClass('show-only-filters');
                    }
                    else if ($(e.target).is(filters.children('select'))) {
                        return false;
                    }
                    else {
                        self.element.removeClass('show-only-filters');
                    }
                })
                .on('change', `${FilterSortSection.tag.selector} select`, function (e) {
                    self.element.removeClass('show-only-filters');
                })

                .on('click', `${Scheduler.tag.selector} ${SchedulerDay.tag.selector}`, function (e) {
                    // blocking SchedulerHour triggers.
                    //if (e.target.tagName.toLowerCase() === SchedulerDay.tag.selector.toLowerCase()) {
                    let element = $(e.currentTarget),
                        day = element.data('schedulerDay') as ISchedulerDay;
                    self.selectedIndex = day.position;
                    self.element.addClass(SchedulerPage.clsDetailView.cls);
                    SchedulerDay.tag.get(self.element).removeClass(SchedulerDay.clsSelected.cls);
                    element.addClass(SchedulerDay.clsSelected.cls);
                    self._refreshSchedulerDetailDay();
                    //}
                })
                .on('click', SchedulerDetail.closeTag.selector, function (e) {
                    self.element.removeClass(SchedulerPage.clsDetailView.cls);
                })
                .on('click', SchedulerDay_AllDay.tag.selector, async function (e) {
                    let $t = $(this),
                        scheduler = Scheduler.tag.get(self.element),
                        detail = SchedulerDetail.tag.get(scheduler),
                        loader = Loader.render().appendTo(detail),
                        range = $t.data('schedulerDay') as ISchedulerDay_AllDay,
                        day = $(SchedulerDay.tag.get(scheduler)[self._calendar.Days.indexOf(range.day)]);
                    if (await self.service.updateCalendar(self._calendar.Id, range.day.StartDate, range.day.EndDate, range.available)) {
                        for (let i = 0; i < range.day.Hours.length; i++) {
                            let hr = range.day.Hours[i];
                            hr.Status = range.available ? CalendarStatuses.Available : CalendarStatuses.Unavailable;
                        }
                        self._refreshSchedulerSelectedDay();
                        self._refreshSchedulerDetailDay();
                        self.element.removeClass(SchedulerPage.clsDetailView.cls);
                    }
                    loader.remove();
                })
                .on('click', SchedulerHour_Detail.cls_available.selector, async function (e) {
                    let element = $(this).closest(SchedulerHour.tag.selector),
                        hour = element.data('schedulerHour') as ICalendarHour,
                        loader = Loader.render().appendTo(element);
                    if (await self.service.updateCalendar(self._calendar.Id, hour.StartDate, hour.EndDate, false)) {
                        hour.Status = CalendarStatuses.Unavailable;
                        self._refreshSchedulerSelectedDay();
                        self._refreshSchedulerDetailHour(element);
                    }
                    loader.remove();
                })

                .on('click', SchedulerHour_Detail.cls_unavailable.selector, async function (e) {
                    let element = $(this).closest(SchedulerHour.tag.selector),
                        hour = element.data('schedulerHour') as ICalendarHour,
                        loader = Loader.render().appendTo(element);
                    if (await self.service.updateCalendar(self._calendar.Id, hour.StartDate, hour.EndDate, false)) {
                        hour.Status = CalendarStatuses.Available;
                        self._refreshSchedulerSelectedDay();
                        self._refreshSchedulerDetailHour(element);
                    }
                    loader.remove();
                })
                ;


            self._scrollToToday();
        }

        return true;
    }

    _scrollToToday() {
        let self = this;
        if (!!self.element) {
            let selected = document.getElementsByClassName(SchedulerDay.clsSelected.cls);
            if (self.selectedIndex > 0 && !!selected && selected.length > 0) {
                selected[0].scrollIntoView(true);
                return;
            }

            let today = document.getElementsByClassName(SchedulerDay.clsToday.cls);
            if (!!today && today.length > 0) {
                today[0].scrollIntoView(true)
            }
        }
    }

    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        element.append(FilterSortSection.render(
            /*isPrinciple*/ self.isPrinciple,
            /*title*/ self.localization,
            /*truckFilter*/ { trucks: self._trucks, selected: self.truckId, enableAllTrucks: false },
            /*productFilter*/ null,
            /*statusFilter*/ null,
            /*notificationType*/ null,
            /*sort*/ null,
        ));
        element.append(Div.render(
            Scheduler.render(self._calendar, self.localization, self.workOrderPageHashTag, self.selectedIndex),
            SchedulerDetail.render(self._calendar.Days[self.selectedIndex], self._calendar.WorkOrders, self._calendar.Leads, self.localization, self.workOrderPageHashTag, self.leadPageHashTag)
        ).addClass('control-container'));
        //element.append(Scheduler.render(self._calendar, self.localization, self.workOrderPageHashTag));
        //element.append(SchedulerDetail.render(self._calendar.Days[self._selectedIndex], self._calendar.WorkOrders, self.localization, self.workOrderPageHashTag));

        if (self.selectedIndex > 0) element.addClass(SchedulerPage.clsDetailView.cls);
        return self.element;
    }

    public resize() {
        let self = this;
        super.resize();
        if (!!self.element) {
            let scheduler = Scheduler.tag.get(self.element),
                details = SchedulerDetail.tag.get(self.element),
                container = $('div.control-container', self.element),
                height = self.calculateViewableHeight(container, FilterSortSection.tag.get(self.element)),
                schedulerSpace = Utilities.spaceOccupied(scheduler),
                detailsSpace = Utilities.spaceOccupied(details);

            //console.log(height);
            scheduler.height(height - schedulerSpace.verticalBuffer);
            details.height(height - detailsSpace.verticalBuffer);
            container.height(height);
        }
    }

    async _reload() {
        let self = this;
        self._calendar = ServiceModels.parseCalendar(await self.service.getCalendar(self.truckId));

        Scheduler.tag.get(self.element).replaceWith(Scheduler.render(self._calendar, self.localization, self.workOrderPageHashTag));
        self._refreshSchedulerDetailDay();
        self._scrollToToday();
    }
    _refreshSchedulerSelectedDay() {
        let self = this,
            dayElement = $(SchedulerDay.tag.get(Scheduler.tag.get(self.element))[self.selectedIndex]),
            day = self._calendar.Days[self.selectedIndex];

        dayElement.replaceWith(SchedulerDay.render(day, self._calendar.WorkOrders, self._calendar.Leads, self.localization, self.workOrderPageHashTag, self.selectedIndex));
        dayElement.addClass(SchedulerDay.clsSelected.cls);
        self.resize();
    }

    _refreshSchedulerDetailDay() {
        let self = this,
            detailElement = SchedulerDetail.tag.get(self.element),
            day = self._calendar.Days[self.selectedIndex];
        detailElement.replaceWith(SchedulerDetail.render(day, self._calendar.WorkOrders, self._calendar.Leads, self.localization, self.workOrderPageHashTag, self.leadPageHashTag));
        self.resize();
    }

    _refreshSchedulerDetailHour(hourElement: JQuery) {
        let self = this,
            hour = hourElement.data('schedulerHour') as ICalendarHour;
        hourElement.replaceWith(SchedulerHour.render(hour, self._calendar.WorkOrders, self._calendar.Leads, self.localization, self.workOrderPageHashTag, self.leadPageHashTag));
    }
}

