import { IDictionary } from "./IDictionary";
import { Subscription } from "./Subscription";

export class Control {
    _subscriptions: IDictionary<Subscription> = {};
    public get subscriptions(): IDictionary<Subscription> { return this._subscriptions; }

    _children: IDictionary<Control> = {};
    _kendoControls: IDictionary<IDestroyableControl> = {};

    /** Contains all child controls.  This is important in order to handle initialization of controls and their disposal.
     * Strong typed references can be included in controls, but they must reference the instance in the children's dictionary.
     * 
     * For example: 
     * public get child():ChildType { 
     *      return this.children['child'] as ChildType; 
     * } 
     * public set child(val:ChildType){
     *      if (!!this.child && this.child !== val){
     *      this.child.dispose(); 
     *      this.children['child'] = val;
     *      }
     *      else {
     *          this.children['child'] = val;
     *      }
     *  }
     * */
    public get children(): IDictionary<Control> { return this._children; }

    public get kendoControls(): IDictionary<IDestroyableControl> { return this._kendoControls; }

    /**
     * Sets or resets a child control.  If it resets, then the previous control is dispose prior to setting the child.
     * @param name
     * @param val
     */
    public setOrResetChild(name: string, val: Control) {
        if (!!this.children[name] && this.children[name] !== val) {
            if (!!this.children[name]) this.children[name].dispose();
            this.children[name] = val;
        }
        else {
            this.children[name] = val;
        }
    }

    /**
     * Registers a subscription for this control.
     * @param name Unique name for the event on this control.
     * @param subscription The subscription to register.
     * @param handler The handler called by the subscription when the event triggers.
     */
    public addSubscription(name: string, subscription: Subscription, handler: (caller: any, eventData: any) => void) {
        this.subscriptions[name] = subscription.subscribe(this, handler);
    }

    /** Initializes the view control.  This should be called after all the render method by the
    * initiating caller. */
    public async init(): Promise<boolean> {
        try {
            Object.keys(this.children)
                .forEach(async (key) => {
                    let control = this.children[key];
                    if (!!control && control instanceof Control) {
                        let ok = await control.init();
                        /** We are haulting execution here to prevent doing bad things.  
                         * I'm expecting the calling control to see that we didn't finish
                         * and take appropriate action to recover and/or notify us via error reporting.
                         **/
                        if (!ok) return false;
                    }
                });

            return true;
        }
        catch{ return false; }
    }

    /** Disposes all child controls and subscriptions.  NOTE - Requires that all disposable controls be registered in the children dictionary. */
    public dispose(): void {
        Object.keys(this.kendoControls)
            .forEach((key) => {
                try {
                    let c = this.kendoControls[key];
                    c.destroy();
                }
                catch { }
            });

        Object.keys(this.children)
            .forEach((key) => {
                try {
                    let c = this.children[key];
                    c.dispose();
                }
                catch { }
            });

        Object.keys(this.subscriptions)
            .forEach((key) => {
                try {
                    let c = this.subscriptions[key];
                    c.unsubscribe(this);
                }
                catch { }
            });
    }
}

export interface IDestroyableControl {
    destroy: () => void;
}