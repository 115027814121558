export { }

declare global {
    interface Date {
        setYear: (year: number) => any;
        toEasternISOString: () => string;
        DateOnly: () => Date;
        getDayDiff: (diffDate: Date) => number;
        getMinsDiff: (diffDate: Date) => number;
        addMinutes: (minutes: number) => Date;
        addHours: (hours: number) => Date;
        addDays: (days: number) => Date;
        toShipHour: () => string;
        isSameDay: (first: Date, second: Date) => any;
        isWeekend: () => boolean;
        addSeconds: (num: number) => Date;
        addMonths: (num: number) => Date;
        getMonthPosition: () => number;
        getDayPosition: () => number;
        toShipFullDate: () => string;
        toTimeSpan: (diffDate?: Date) => string;
        toDate: () => Date;
        eq: (compare: Date) => boolean;
    }
}


Date.prototype.DateOnly = function (): Date {
    var d = new Date(this);
    d.setHours(0, 0, 0, 0);
    return d;
};
Date.prototype.isSameDay = function (first: Date, second: Date): boolean {
    return (first.DateOnly().valueOf() === second.DateOnly().valueOf());
};
Date.prototype.isWeekend = function (): boolean {
    return this.getDay() === 0 || this.getDay() === 6;
};

Date.prototype.getDayDiff = function (diffDate: Date): number {
    if (diffDate instanceof Date) {
        return Math.abs(Math.round((this - (diffDate as any)) / (1000 * 60 * 60 * 24)))
    }
    //Ship.logger.log(LogType.verbose, "Invalid date comparison.");
    return null;
}


Date.prototype.getMinsDiff = function (diffDate: Date): number {
    if (diffDate instanceof Date) {
        return Math.abs(Math.round((this - (diffDate as any)) / (1000 * 60)))
    }
    // Ship.logger.log(LogType.verbose, "Invalid date comparison.");
    return null;
}
Date.prototype.toTimeSpan = function (diffDate?: Date): string {
    let self = this as Date;
    if (!diffDate) diffDate = new Date();
    if (diffDate instanceof Date) {
        let ts: number = Math.abs((diffDate.getTime() - self.getTime()));

        const s = 1000, m = 60 * s, h = 60 * m, d = 24 * h;

        const days = Math.floor(ts / d); ts -= d * days;
        const hours = Math.floor(ts / h); ts -= h * hours;
        const minutes = Math.floor(ts / m); ts -= m * minutes;
        const seconds = Math.floor(ts / s); ts -= s * seconds;

        return `${!!days ? days + ":" : ""}${hours}:${minutes.padZeros(2)}:${seconds.padZeros(2)}`;
    }
    return null;
}

Date.prototype.addDays = function (num): Date {
    if (num === null) num = 0;
    var value = this.valueOf();
    value += 86400000 * num;
    return new Date(value);
}

Date.prototype.addSeconds = function (num): Date {
    if (num === null) num = 0;
    var value = this.valueOf();
    value += 1000 * num;
    return new Date(value);
}

Date.prototype.addMinutes = function (num): Date {
    if (num === null) num = 0;
    var value = this.valueOf();
    value += 60000 * num;
    return new Date(value);
}

Date.prototype.addHours = function (num): Date {
    if (num === null) num = 0;
    var value = this.valueOf();
    value += 3600000 * num;
    return new Date(value);
}

Date.prototype.addMonths = function (num): Date {
    if (num === null) num = 0;
    var value = new Date(this.valueOf());

    var mo = this.getMonth();
    var yr = this.getFullYear();

    mo = (mo + num) % 12;
    if (0 > mo) {
        yr += (this.getMonth() + num - mo - 12) / 12;
        mo += 12;
    }
    else
        yr += ((this.getMonth() + num - mo) / 12);

    value.setMonth(mo);
    value.setYear(yr);
    return value;
}

Date.prototype.toShipHour = function (): string {
    var hr = this.getHours();
    var hours = (hr < 13) ? this.getHours().padZeros(2) : (hr - 12).padZeros(2);
    var minutes = this.getMinutes().padZeros(2);
    var am_pm = (hr < 12 || hr === 24) ? "AM" : "PM";
    return hours + ":" + minutes + " " + am_pm;
}
Date.prototype.toShipFullDate = function (): string {
    return this.toLocaleDateString() + " " + this.toShipHour();
}
Date.prototype.getMonthPosition = function (): number {
    var zeroPosition = new Date();
    zeroPosition.setDate(1);
    var months;
    months = (this.getFullYear() - zeroPosition.getFullYear()) * 12;
    months -= zeroPosition.getMonth();
    months += this.getMonth();
    return months;
}
Date.prototype.getDayPosition = function (): number {
    return this.getDate() - 1;
}
Date.prototype.toEasternISOString = function (): string {
    //return this.addHours(-5).toISOString();
    // etc. '2016-02-29T11:18:41.171Z'

    // Using this because our db assumes local time.
    let year = (this.getFullYear() as number).toString();
    let month = ((this.getMonth() as number) + 1).padZeros(2);
    let day = (this.getDate() as number).padZeros(2);
    let hours = (this.getHours() as number).padZeros(2);
    let minutes = (this.getMinutes() as number).padZeros(2);
    let seconds = (this.getSeconds() as number).padZeros(2);
    let milliseconds = (this.getMilliseconds() as number).padZeros(3);

    return year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':' + seconds + '.' + milliseconds + 'Z';
}

var DateFromPosition = function (monthPosition: any, dayPosition: any): Date {
    var zeroPosition = new Date();
    zeroPosition.setDate(1);
    return zeroPosition.addMonths(monthPosition).addDays(dayPosition);
}
var DateFromWeekPosition = function (weekPosition: any): Date {
    var zeroPosition = new Date();
    zeroPosition.setDate(1);
    return zeroPosition.addDays(weekPosition * 7);
}

Date.prototype.toDate = function (): Date {
    if (this instanceof Date) return this;
    return (this as string).toDate();
}

/** Compares to Date/Times for equality */
Date.prototype.eq = function (compare: Date): boolean {
    if (!compare) return false;
    return (this as Date).getTime() === compare.getTime();
}