import { ViewBase } from "./ViewBase";
import { ICrewMember, NotificationRecipientTypes, ITruckAccess } from "../Service/ServicesModels";
import { ServiceHandler } from "../Service/ServiceHandler";
import { Helper } from "../Utilities/Helper";
import '../../node_modules/@progress/kendo-ui/js/kendo.buttonGroup';
import '../../node_modules/@progress/kendo-ui/js/kendo.comboBox';
import { IdSelector, IJQuerySelector } from "../Common/Tag";
import { Loader } from "../Partials/Generics";
import { ILocalization } from "../Common/ILocalizations";


export class TruckAccess extends ViewBase {

    constructor(private crewMember: ICrewMember, private service: ServiceHandler, private localization: ILocalization) {
        super(new IdSelector(`truckaccess-${crewMember.Id}`, 'truckaccess'));

        this._enableAccessTag = new IdSelector(`enableaccess-grp-${crewMember.Id}`, 'div');
        this._enableSowTag = new IdSelector(`enablesow-grp-${crewMember.Id}`, 'div');
        this._recipentTypeTag = new IdSelector(`recipienttype-${crewMember.Id}`, 'select');

        this._notificationReciepentTypes = [
            { id: NotificationRecipientTypes.PrincipleOnly, text: localization.notificationRecipientType_PrincipleOnly },
            { id: NotificationRecipientTypes.TruckOnly, text: localization.notificationRecipientType_TruckOnly },
            { id: NotificationRecipientTypes.PrincipleAndTruck, text: localization.notificationRecipientType_PrincipleAndTruck },
        ];
    }

    _notificationReciepentTypes: { id: NotificationRecipientTypes, text: string }[] = [];
    _enableAccessTag: IJQuerySelector;
    _enableSowTag: IJQuerySelector;
    _recipentTypeTag: IJQuerySelector;

    get enableAccess(): kendo.ui.ButtonGroup { return this.kendoControls['enableAccess'] as kendo.ui.ButtonGroup; }
    get enableSOW(): kendo.ui.ButtonGroup { return this.kendoControls['enableSOW'] as kendo.ui.ButtonGroup; }
    get recipientType(): kendo.ui.ComboBox { return this.kendoControls['recipientType'] as kendo.ui.ComboBox; }

    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();

        element
            .append(self._enableAccessTag.create()
                .append($('<button>', { text: self.localization.button_TruckAccess_Enable, 'class': 'yes-answer' }))
                .append($('<button>', { text: self.localization.button_TruckAccsss_Disable, 'class': 'no-answer' }))
            )
            .append(self._enableSowTag.create({ style: `${self.crewMember.Truck.IsPortalEnabled ? '' : 'display: none;'}` })
                .append($('<button>', { text: self.localization.button_SOW_Enable, 'class': 'yes-answer' }))
                .append($('<button>', { text: self.localization.button_SOW_Disable, 'class': 'no-answer' }))
            )
            .append(self._recipentTypeTag.create({ style: `${self.crewMember.Truck.IsPortalEnabled && self.crewMember.Truck.IsSOWEnabled ? '' : 'display: none;'}` }));

        return element;
    }

    public async init(): Promise<boolean> {
        let self = this;
        if (! await super.init()) return false;

        if (!!self.element) {

            self.kendoControls['recipientType'] = self._recipentTypeTag.get(self.element).kendoComboBox({
                dataSource: self._notificationReciepentTypes,
                dataTextField: 'text',
                dataValueField: 'id',
                value: Helper.toString(self.crewMember.Truck.NotificationRecipientType),
                change: (e) => {
                    let v = Helper.toNumber(e.sender.value());
                    if (self.crewMember.Truck.NotificationRecipientType !== v)
                        self._updateAccess(self.crewMember.Id, self.crewMember.Truck.IsPortalEnabled, self.crewMember.Truck.IsSOWEnabled, Helper.toNumber(e.sender.value()));
                },


            }).data('kendoComboBox');

            if (self.crewMember.Truck.IsPortalEnabled && self.crewMember.Truck.IsSOWEnabled) self.recipientType.wrapper.show();
            else self.recipientType.wrapper.hide();

            self.kendoControls['enableSOW'] = self._enableSowTag.get(self.element).kendoButtonGroup({
                index: self.crewMember.Truck.IsSOWEnabled ? 0 : 1,
                select: (e) => {
                    if (e.indices == 1) {
                        if (self.crewMember.Truck.IsSOWEnabled)
                            self._updateAccess(self.crewMember.Id, self.crewMember.Truck.IsPortalEnabled, false, NotificationRecipientTypes.PrincipleOnly);
                    }
                    else {
                        if (!self.crewMember.Truck.IsSOWEnabled)
                            self._updateAccess(self.crewMember.Id, self.crewMember.Truck.IsPortalEnabled, true, self.crewMember.Truck.NotificationRecipientType);
                    }
                }
            }).data('kendoButtonGroup');

            self.kendoControls['enableAccess'] = self._enableAccessTag.get(self.element).kendoButtonGroup({
                index: self.crewMember.Truck.IsPortalEnabled ? 0 : 1,
                select: (e) => {
                    if (e.indices == 1) {
                        if (self.crewMember.Truck.IsPortalEnabled)
                            self._updateAccess(self.crewMember.Id, false, false, NotificationRecipientTypes.PrincipleOnly);
                    }
                    else {
                        if (!self.crewMember.Truck.IsPortalEnabled)
                            self._updateAccess(self.crewMember.Id, true, self.crewMember.Truck.IsSOWEnabled, self.crewMember.Truck.NotificationRecipientType);
                    }
                }
            }).data('kendoButtonGroup');
        }
        return true;
    }

    async _updateAccess(id: number, isAccessEnabled: boolean, isSOWEnabled: boolean, recipientType: NotificationRecipientTypes): Promise<boolean> {
        let self = this;
        let loader = Loader.render(self.localization.loader_Working).appendTo(self.element);
        try {
            let truckAccess: ITruckAccess = await self.service.updateTruckAccess(id, isAccessEnabled, isSOWEnabled, recipientType);

            self.crewMember.Truck.IsPortalEnabled = Helper.toBoolean(truckAccess.AccessEnabled);
            self.crewMember.Truck.IsSOWEnabled = Helper.toBoolean(truckAccess.SOWEnabled);
            self.crewMember.Truck.NotificationRecipientType = Helper.toNumber(truckAccess.SubcontractorNotificationRecientTypeId);
        }
        catch (ex) {

        }

        loader.remove();
        self.refresh();
        return true;
    }
}