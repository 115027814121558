import { PageBase } from "./PageBase";
import { TagSelector } from "../Common/Tag";
import { ILocalization } from "../Common/ILocalizations";
import { ServiceHandler } from "../Service/ServiceHandler";
import { IDictionary } from "../Common/IDictionary";
import { Helper } from "../Utilities/Helper";
import { PageTitle, PageSubTitle, Field, FieldLabel, Span, Div, Loader, Page, PageLoader } from "../Partials/Generics";
import { IAdminNotification, NotificationTypes, IScope } from "../Service/ServicesModels";
import { NotificationDescriptionInput, NotificationMessageInput, NotificationDocumentLinkInput, NotificationTypeInput, NotificationDocumentStorageSection, NotificationAddScopeSection, NotificationScopesSection, NotificationSaveButton, NotificationScopeAddButton, NotificationScope, NotificationDocumentStorageIdInput, NotificationActiveOnInput, NotificationCriticalOnInput, Notification, NotificationDistrictInput, NotificationProductInput, NotificationSubcontractorInput, NotificationLaborBillInput, NotificationDocumentFileInput } from "../Partials/Notifications";
import { Utilities } from "../Utilities/Utilities";
import { DocumentUploadButton, DocumentUploadSelection } from "../Partials/Documents";


export class NotificationEditPage extends PageBase {
    static tag = new TagSelector('notificationeditpage');

    constructor(private localization: ILocalization, private service: ServiceHandler, private username: string) {
        super(NotificationEditPage.tag);
    }

    _notification: IAdminNotification;

    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;

        if (! await super.load(parameters)) return false;
        let id = Helper.toNumber(parameters.id);
        if (Helper.hasValue(id)) {
            self._notification = await self.service.getNotification(id);
        }
        else {
            self._notification = {
                Id: null,
                CreatedBy: self.username,
                CreatedOn: new Date(),
                Description: null,
                DocumentStorageId: null,
                DocumentUrl: null,
                Message: null,
                Scopes: [],
                SubcontractorId: null,
                Type: NotificationTypes.General,
            };
        }
        return true;
    }

    get activeOn(): kendo.ui.DateTimePicker { return this.kendoControls['activeOn'] as any; }
    set activeOn(val: kendo.ui.DateTimePicker) { this.kendoControls['activeOn'] = val; }

    get criticalOn(): kendo.ui.DateTimePicker { return this.kendoControls['criticalOn'] as any; }
    set criticalOn(val: kendo.ui.DateTimePicker) { this.kendoControls['criticalOn'] = val; }


    public async init(): Promise<boolean> {
        let self = this;
        if (! await super.init()) return false;
        if (!!self.element) {

            let resetAddScope = () => {
                let addScopeSection = NotificationAddScopeSection.tag.get(self.element);
                $('input', addScopeSection).val(null);
                Utilities.SetDisabled(NotificationScopeAddButton.tag.get(self.element), true);
                if (!!self.activeOn) self.activeOn.value(null);
                if (!!self.criticalOn) self.criticalOn.value(null);
            };

            self.element
                .off()
                .on('change', NotificationDescriptionInput.tag.selector, function (e) {
                    Utilities.SetDisabled(NotificationSaveButton.tag.get(self.element), false);
                    self._notification.Description = Helper.toString($(this).val());
                })
                .on('change', NotificationMessageInput.tag.selector, function (e) {
                    Utilities.SetDisabled(NotificationSaveButton.tag.get(self.element), false);
                    self._notification.Message = Helper.toString($(this).val());
                })
                .on('change', NotificationDocumentLinkInput.tag.selector, function (e) {
                    Utilities.SetDisabled(NotificationSaveButton.tag.get(self.element), false);
                    self._notification.DocumentUrl = Helper.toString($(this).val());
                })
                .on('change', NotificationTypeInput.tag.selector, function (e) {
                    Utilities.SetDisabled(NotificationSaveButton.tag.get(self.element), false);
                    self._notification.Type = Helper.toNumber($(this).val());
                })
                .on('change', NotificationDocumentStorageIdInput.tag.selector, function (e) {
                    Utilities.SetDisabled(NotificationSaveButton.tag.get(self.element), false);
                    self._notification.DocumentStorageId = Helper.toNumber($(this).val());
                })
                .on('change', `${NotificationAddScopeSection.tag.selector} input`, function (e) {
                    Utilities.SetDisabled(NotificationScopeAddButton.tag.get(self.element), false);
                })
                .on('change', `${NotificationDocumentStorageSection.tag.selector} input`, function (e) {
                    Utilities.SetDisabled(DocumentUploadButton.tag.get(self.element), false);
                })
                .on('click', NotificationSaveButton.tag.selector, async function (e) {
                    PageLoader.showLoader(true);
                    self._notification = await self.service.saveNotification(self._notification);
                    self.refresh();
                    PageLoader.showLoader(false);
                })
                .on('click', NotificationScopeAddButton.tag.selector, function (e) {
                    let addScopeSection = NotificationAddScopeSection.tag.get(self.element),
                        scopesSection = NotificationScopesSection.tag.get(self.element),
                        loader = Loader.render().appendTo(addScopeSection);
                    let scope: IScope = {
                        Id: null,
                        ActiveOn: !Utilities.isMobile ? self.activeOn.value() : Helper.toDate(NotificationActiveOnInput.tag.get(self.element).val()),
                        CriticalOn: !Utilities.isMobile ? self.criticalOn.value() : Helper.toDate(NotificationCriticalOnInput.tag.get(self.element).val()),
                        DistrictId: Helper.toNumber(NotificationDistrictInput.tag.get(self.element).val()),
                        ProductId: Helper.toNumber(NotificationProductInput.tag.get(self.element).val()),
                        SubcontractorId: Helper.toNumber(NotificationSubcontractorInput.tag.get(self.element).val()),
                        LaborBillId: Helper.toNumber(NotificationLaborBillInput.tag.get(self.element).val()),
                    };
                    self._notification.Scopes.push(scope);
                    scopesSection.append(NotificationScope.render(scope, self.localization));
                    //addScopeSection.replaceWith(NotificationAddScopeSection.render(Utilities.isMobile, self.localization));
                    resetAddScope();
                    Utilities.SetDisabled(NotificationSaveButton.tag.get(self.element), false);
                    loader.remove();
                })
                .on('click', NotificationScope.deleteScopeTag.selector, function (e) {
                    let element = $(this).closest(NotificationScope.tag.selector),
                        scope = element.data('notificationScope') as IScope,
                        i = self._notification.Scopes.indexOf(scope);

                    self._notification.Scopes.splice(i, 1);
                    element.remove();
                    Utilities.SetDisabled(NotificationSaveButton.tag.get(self.element), false);
                })
                .on('click', DocumentUploadButton.tag.selector, async function (e) {
                    let uploadSection = NotificationDocumentStorageSection.tag.get(self.element),
                        loader = Loader.render().appendTo(uploadSection),
                        files = Utilities.getFileList(NotificationDocumentFileInput.tag.get(self.element));

                    let doc = await self.service.uploadNotificationDocument(self._notification.Id, files[0]);
                    self._notification.DocumentStorageId = doc.DocumentStorageId;
                    uploadSection.replaceWith(NotificationDocumentStorageSection.render(self._notification, self.localization));
                    loader.remove();
                })

            if (!Utilities.isMobile) {
                self.activeOn = NotificationActiveOnInput.tag.get(self.element)
                    .kendoDateTimePicker({
                        value: null,
                        dateInput: true,
                        //min: minScheduleDate,
                        change: (e) => {
                            let v = e.sender.value();
                            Utilities.SetDisabled(NotificationScopeAddButton.tag.get(self.element), !v);
                        }
                    })
                    .data('kendoDateTimePicker');

                self.criticalOn = NotificationCriticalOnInput.tag.get(self.element)
                    .kendoDateTimePicker({
                        value: null,
                        dateInput: true,
                        //min: minScheduleDate,
                        change: (e) => {
                            let v = e.sender.value();
                            Utilities.SetDisabled(NotificationScopeAddButton.tag.get(self.element), !v);
                        }
                    })
                    .data('kendoDateTimePicker');
            }
            ;
        }

        return true;
    }

    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        element.append(PageTitle.render(self.localization.page_NotificationEdit));
        element.append(PageSubTitle.render(self.localization.page_NotificationEdit_Subtitle))

        element.append(Div.render(
            Div.render(
                Field.render({},
                    FieldLabel.render({ html: `${self.localization.label_Description}` }),
                    NotificationDescriptionInput.render(self._notification.Description, self.localization),
                ),
                Field.render({},
                    FieldLabel.render({ html: `${self.localization.label_Message}` }),
                    NotificationMessageInput.render(self._notification.Message, self.localization),
                ),
                Field.render({},
                    FieldLabel.render({ html: `${self.localization.label_DocumentURL}` }),
                    NotificationDocumentLinkInput.render(self._notification.DocumentUrl, self.localization),
                ),
                Field.render({},
                    FieldLabel.render({ html: `${self.localization.label_NotificationType}` }),
                    Div.render(
                        NotificationTypeInput.render(self._notification.Type === NotificationTypes.General, NotificationTypes.General, self.localization),
                        Span.render(this.localization.notificationTypesGeneral)
                    ),
                    Div.render(
                        NotificationTypeInput.render(self._notification.Type === NotificationTypes.EPA, NotificationTypes.EPA, self.localization),
                        Span.render(this.localization.notificationTypesEPA)
                    ),
                ),
            ),
            NotificationDocumentStorageSection.render(self._notification, self.localization),
            //NotificationSaveButton.render(self.localization)
        ).addClass("col-xs-12 col-md-6"));

        element.append(Div.render(
            NotificationScopesSection.render(self._notification.Scopes, self.localization),
            NotificationAddScopeSection.render(Utilities.isMobile, self.localization)
        ).addClass("col-xs-12 col-md-6"));


        //element.append(Field.render({},
        //    FieldLabel.render({ html: `${self.localization.label_Description}` }),
        //    NotificationDescriptionInput.render(self._notification.Description, self.localization)
        //));
        //element.append(Field.render({},
        //    FieldLabel.render({ html: `${self.localization.label_Message}` }),
        //    NotificationMessageInput.render(self._notification.Message, self.localization)
        //));
        //element.append(Field.render({},
        //    FieldLabel.render({ html: `${self.localization.label_DocumentURL}` }),
        //    NotificationDocumentLinkInput.render(self._notification.DocumentUrl, self.localization)
        //));
        //element.append(Field.render({},
        //    FieldLabel.render({ html: `${self.localization.label_NotificationType}` }),
        //    Div.render(
        //        NotificationTypeInput.render(self._notification.Type === NotificationTypes.General, NotificationTypes.General, self.localization),
        //        Span.render(this.localization.notificationTypesGeneral)
        //    ),
        //    Div.render(
        //        NotificationTypeInput.render(self._notification.Type === NotificationTypes.EPA, NotificationTypes.EPA, self.localization),
        //        Span.render(this.localization.notificationTypesEPA)
        //    ),

        //));

        //element.append(NotificationDocumentStorageSection.render(self._notification, self.localization));
        //element.append(NotificationAddScopeSection.render(Utilities.isMobile, self.localization));
        //element.append(NotificationScopesSection.render(self._notification.Scopes, self.localization));
        element.append(NotificationSaveButton.render(self.localization));

        return self.element;
    }
}