import { PageBase } from "./PageBase";
import { IDictionary } from "../Common/IDictionary";
import { ServiceHandler } from "../Service/ServiceHandler";
import { Helper } from "../Utilities/Helper";
import { ISubcontractorLead, DocumentTypes, documentExtensions, photoExtensions, IWorkOrderDocument } from "../Service/ServicesModels";
import { Utilities } from "../Utilities/Utilities";
import { TagSelector } from "../Common/Tag";
import { ILocalization } from "../Common/ILocalizations";
import { Loader, PageTitle } from "../Partials/Generics";
import { DocumentUploadSelection, DocumentUploadTypeSelect, DocumentUploadButton, CancelDocumentUploadButton, UploadedDocuments, DocumentUploadSection } from "../Partials/Documents";
import { LeadDetail } from "../Partials/Leads";


export class LeadsDocumentsPage extends PageBase {

    constructor(private service: ServiceHandler, private localization: ILocalization, private documentViewerHashTag: string, private onFileSizeValidation: (e: string) => void) {
        super(new TagSelector('leaddocuments'));

    }

    _documentTypes: IUploadTypes[] = [
        //{ id: -1, text: 'Select Type', limit: 0, ext: '', disabled: false },
        { id: null, text: this.localization.placeholder_DocumentType, limit: 0, ext: '', disabled: false },
        { id: DocumentTypes.Contract, text: 'Contract', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.Contract_CA, text: 'Contract (CA)', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.ContractChangeAuthorization, text: 'Contract Change Authorization', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.CompletionCertificate, text: 'Completion Certificate', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.DisclosureNotice, text: 'Disclosure Notice', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.EPA_AcknowledgementForm, text: 'EPA Acknowledgement', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.EPA_Acknowledgement_Signed, text: 'EPA Acknowledgement - Signed', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.EPA_LRRP_Checklist_Signed, text: 'EPA LRRP Checklist - Signed', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.EquipmentInvoiceFromContractor, text: 'Equipment Invoice from Contractor', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.EquipmentReceiptFromVendor, text: 'Equipment Receipt from Vendor', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.LeaveBehindQuote, text: 'Quote Left Behind', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.LoadCalculationWorksheet, text: 'Load Calculation Worksheet', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.NoticeOfCancellation, text: 'Notice of Cancellation', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.OtherDocument, text: 'Other Document', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.Permit, text: 'Permit', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.PermitFeeReceipt, text: 'Permit Fee Receipt', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.PermitWaiver, text: 'Permit Waiver', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.PhotoBefore, text: 'Photo - Before', limit: 20000000, ext: photoExtensions, disabled: false },
        { id: DocumentTypes.PhotoInProgress, text: 'Photo - In Progress', limit: 20000000, ext: photoExtensions, disabled: false },
        { id: DocumentTypes.PhotoAfter, text: 'Photo - After', limit: 20000000, ext: photoExtensions, disabled: false },
        { id: DocumentTypes.PhotoLeadSafe, text: 'Photo - Lead Safe', limit: 20000000, ext: photoExtensions, disabled: false },
        { id: DocumentTypes.ProposalWorksheet, text: 'Proposal Worksheet', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.PurchaseSummary, text: 'Purchase Summary', limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.SamsGiftCardDoc, text: "Sam's Gift Card", limit: 10000000, ext: documentExtensions, disabled: false },
        { id: DocumentTypes.WaiverOfLein, text: 'Waiver of Lein', limit: 10000000, ext: documentExtensions, disabled: false },
    ];


    _lead: ISubcontractorLead = null;
    public get lead(): ISubcontractorLead { return this._lead; }

    _uploadedDocuments: IWorkOrderDocument[];

    //get uploadType(): kendo.ui.ComboBox { return this.kendoControls['uploadType'] as kendo.ui.ComboBox; }
    //set uploadType(val: kendo.ui.ComboBox) { this.kendoControls['uploadType'] = val; }

    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;
        if (!await super.load(parameters)) return false;
        self._lead = await self.service.getSubcontractorLead(Helper.toNumber(parameters.id));

        return true;
    }

    public async init(): Promise<boolean> {
        let self = this;
        if (!await super.init()) return false;

        if (!!self.element) {
            let clearUploadForm = () => {
                DocumentUploadSelection.tag.get(self.element).val(null);
                DocumentUploadTypeSelect.tag.get(self.element).val(null);
            };

            let enableActions = () => {
                let uploadBtn = DocumentUploadButton.tag.get(self.element),
                    cancelBtn = CancelDocumentUploadButton.tag.get(self.element),
                    files = DocumentUploadSelection.tag.get(self.element),
                    documentType = DocumentUploadTypeSelect.tag.get(self.element),
                    enable = !!files.val() &&
                        !!documentType.val() &&
                        documentType.val() !== '';

                Utilities.SetDisabled(uploadBtn, !enable);
                Utilities.SetDisabled(cancelBtn, !enable);
            };
            self.element
                .on('click', DocumentUploadButton.tag.selector, async function (e) {
                    let files = Utilities.getFileList(DocumentUploadSelection.tag.get(self.element)),
                        typeId = Helper.toNumber(DocumentUploadTypeSelect.tag.get(self.element).val()),
                        type = self._documentTypes.find((v) => { return v.id === typeId; });
                    await self.uploadDocuments(type, files);
                    clearUploadForm();
                    await self.reloadUploadedDocuments();
                })
                .on('click', CancelDocumentUploadButton.tag.selector, function () {
                    clearUploadForm();
                    enableActions();
                })
                .on('change', DocumentUploadTypeSelect.tag.selector, function (e) {
                    let files = DocumentUploadSelection.tag.get(self.element),
                        typeId = Helper.toNumber(DocumentUploadTypeSelect.tag.get(self.element).val()),
                        type = self._documentTypes.find((v) => { return v.id === typeId; });
                    files.attr('accept', type.ext);
                    files.attr('disabled', type.id > 0 ? null : 'disabled');
                    files.val(null);
                    enableActions();
                })
                .on('change', DocumentUploadSelection.tag.selector, function () {
                    enableActions();
                });
        }

        self.reloadUploadedDocuments();
    }

    public async reloadUploadedDocuments(): Promise<boolean> {
        let self = this;
        let section = UploadedDocuments.tag.get(self.element);
        let loader = Loader.render(self.localization.loader_UploadedDocuments).appendTo(section);
        try {
            self._uploadedDocuments = await self.service.getSubcontractorLeadUploadedDocuments(self.lead.Id);
            if (!!self.element) {
                section.replaceWith(await UploadedDocuments.render(self.service, self._uploadedDocuments, self.localization, self.documentViewerHashTag));
            }
            return true;
        }
        catch (ex) {
            loader.remove();
            return false;
        }
    }

    public async uploadDocuments(uploadType: IUploadTypes, files: FileList): Promise<boolean> {
        let self = this;
        let uploadSection = DocumentUploadSection.tag.get(self.element);
        let loader = Loader.render(self.localization.loader_UploadingDocuments).appendTo(uploadSection);
        try {
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                if (!!file.size && file.size <= uploadType.limit) {
                    await self.service.uploadSubcontractorLeadDocument(self.lead.Id, uploadType.id, file);
                }
                else {
                    self.onFileSizeValidation(Utilities.formattedString(self.localization.validation_FileSizeExceeded, { fileName: file.name, limit: Utilities.formatFileSize(uploadType.limit) }));
                }
            }
            loader.remove();
            return true;
        }
        catch (ex) {
            loader.remove();
        }
    }

    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        //element.append(await self.renderMenu());
        element
            .append(PageTitle.render(self.localization.page_LeadDocuments))
            .append(LeadDetail.render(self.lead, self.localization))
            .append(DocumentUploadSection.render(self.localization, self._documentTypes))
            .append(await UploadedDocuments.render(self.service, self._uploadedDocuments, self.localization, self.documentViewerHashTag));


        return element;
    }
}

interface IUploadTypes {
    id: number;
    text: string;
    limit: number;
    disabled: boolean;
    ext: string;
}