import dateFormat from "dateformat";
import { TagSelector, IJQuerySelector, ClassSelector, IdSelector } from "../Common/Tag";
import { ISubcontractorLead, LeadStatuses, IPerson, ISubcontractorLeadActivity, ILeadResultReason } from "../Service/ServicesModels";
import { ILocalization } from "../Common/ILocalizations";
import { Helper } from "../Utilities/Helper";
import { CardTitle, CardContent, CalendarIcon, Div, Span, TextContent, Bold, DocumentsIcon, LinkContent, Legend, ButtonGroup, Option, Select, ButtonContent, Field, FieldLabel } from "./Generics";
import { Address, PhoneNumber, MapLink } from "./Contacts";


export class Lead {

    static getStatusDescription(leadStatus: LeadStatuses, localization: ILocalization): string {
        let descriptions = [
            localization.leadStatusesNewLead,
            localization.leadStatusesAcknowledged,
            localization.leadStatusesLeadCanceled,
            localization.leadStatusesScheduled,
            localization.leadStatusesSold,
            localization.leadStatusesNoSale,
            localization.leadStatusesAllActive,
        ];
        return descriptions[leadStatus];
    }

    static getStatusEnum(leadStatus: LeadStatuses): string {
        let enums = [
            'NewLead',
            'Acknowledged',
            'LeadCanceled',
            'Scheduled',
            'Sold',
            'NoSale',
        ];
        return enums[leadStatus];
    }
}

export class LeadCard {
    static tag = new TagSelector('lead');

    static canceledLead = new ClassSelector('canceled', 'lead');
    static newLead = new ClassSelector('new', 'lead');
    static soldLead = new ClassSelector('sold', 'lead');

    static render(lead: ISubcontractorLead, localization: ILocalization, tag: IJQuerySelector = LeadCard.tag): JQuery {
        let card = tag.create({ 'data-id': lead.Id })
            .append(CardTitle.render(`${lead.Lead.Customer.LastName}, ${lead.Lead.Customer.FirstName} : ${Lead.getStatusDescription(lead.Status, localization)}`))
            .append(CardContent.render(`${lead.Lead.Customer.Address.City}, ${lead.Lead.Customer.Address.State}`))
            .append(CardContent.render(`${lead.Lead.Branch.Product.FriendlyName} ${localization.label_ReceivedOn} ${lead.CreatedOn.toDate().toLocaleDateString()}`))

        if (lead.Status.isIn([LeadStatuses.Scheduled])) {
            card.append(LeadAppointmentCallout.render(lead.Lead.ApptDateTime, localization));
        }

        if (lead.Status.isIn([LeadStatuses.LeadCanceled])) {
            card.addClass(LeadCard.canceledLead.cls);
        }
        if (lead.Status.isIn([LeadStatuses.NewLead])) {
            card.addClass(LeadCard.newLead.cls);
        }
        if (lead.Status.isIn([LeadStatuses.Sold])) {
            card.addClass(LeadCard.soldLead.cls);
        }
        return card;
    }
}

export class LeadAppointmentCallout {
    static tag = new TagSelector('appointment');
    static render(apptDateTime: Date, localization: ILocalization, tag: IJQuerySelector = LeadAppointmentCallout.tag): JQuery {
        return tag.create()
            .append(CalendarIcon.render())
            .append(
                Div.render(
                    TextContent.render(localization.callout_Appointment),
                    TextContent.render(dateFormat(Helper.toDate(apptDateTime), 'dddd mm/dd | h:MM TT'))
                )
            );
    }
}

export class LeadDetail {
    static tag = new TagSelector('lead-detail');
    static render(lead: ISubcontractorLead, localization: ILocalization): JQuery {
        return LeadDetail.tag.create()
            .append(
                TextContent.render()
                    .append(Bold.render(`${lead.Lead.Branch.Product.FriendlyName} ${localization.label_Lead} `))
                    .append(Span.render(`${lead.Lead.Id} `))
                    .append(Bold.render(`${localization.label_ReceivedOn}`))
                    .append(Span.render(dateFormat(Helper.toDate(lead.CreatedOn), 'm/dd/yyyy')))
            );
    }
}

export class LeadCustomer {
    static tag = new TagSelector('customer');
    static render(customer: IPerson, status: LeadStatuses, apptDateTime: Date, mapUrl: string, localization: ILocalization): JQuery {
        let details = LeadCustomer.tag.create()
            .append(CardTitle.render(`${customer.LastName}, ${customer.FirstName}`))
            .append(Address.render(customer.Address))
            ;
        customer.Phones.forEach((p) => { details.append(PhoneNumber.render(p, localization)); });

        if (status.isIn([LeadStatuses.Scheduled])) {
            details.append(LeadAppointmentCallout.render(apptDateTime, localization));
        }

        details.append(MapLink.render(customer.Address, localization.button_OpenMap, localization.button_OpenMap, mapUrl));
        return details;

    }
}

export class LeadDocumentsLink {
    static tag = new TagSelector('a');
    static render(id: number, page: string, localization: ILocalization): JQuery {
        return LeadDocumentsLink.tag.create({ href: `${page}!id=${id}` })
            .append(DocumentsIcon.render())
            .append(LinkContent.render(localization.documents));
    }
}

export class LeadActions {
    static tag = new IdSelector('actions', 'actions');
    static render(): JQuery {
        return LeadActions.tag.create({ 'class': 'btn-grp' });
    }
}

export class LeadActionCancelButton {
    static tag = new IdSelector('cancellead', 'button');
    static render(localization: ILocalization): JQuery {
        return LeadActionCancelButton.tag.create({ 'class': 'k-button' })
            .append(ButtonContent.render(localization.button_CancelLead));
    }
}
export class LeadActionScheduleButton {
    static tag = new IdSelector('scheduleappt', 'button');
    static render(localization: ILocalization): JQuery {
        return LeadActionScheduleButton.tag.create({ 'class': 'k-button' })
            .append(ButtonContent.render(localization.button_ScheduleAppointment));
    }
}
export class LeadActionQuoteButton {
    static tag = new IdSelector('quoted', 'button');
    static render(localization: ILocalization): JQuery {
        return LeadActionQuoteButton.tag.create({ 'class': 'k-button' })
            .append(ButtonContent.render(localization.button_Quoted));
    }
}

export class LeadActionAddCommentButton {
    static tag = new IdSelector('addcomments', 'button');
    static render(localization: ILocalization): JQuery {
        return LeadActionAddCommentButton.tag.create({ 'class': 'k-button' })
            .append(ButtonContent.render(localization.button_AddComments));
    }
}

export class LeadCancelSection {
    static tag = new IdSelector('cancelsection', 'fieldset');
    static render(reasons: ILeadResultReason[], localization: ILocalization): JQuery {
        return LeadCancelSection.tag.create({ style: 'display: none;' })
            .append(Field.render({},
                FieldLabel.render({ text: localization.label_LeadCancelationReason }),
                LeadCancelReasonSelect.render(LeadCancelReasonSelect.getOptions(reasons, localization), localization)
            ))
            //.append(LeadCancelReasonSelect.render(LeadCancelReasonSelect.getOptions(reasons, localization), localization))
            .append(ButtonGroup.render(
                LeadCancelResetButton.render(localization),
                LeadCancelButton.render(localization)));
    }
}

export class LeadCancelResetButton {
    static tag = new IdSelector('cancellead-cancel-btn', 'button');
    static render(localization: ILocalization): JQuery {
        return LeadCancelResetButton.tag.create({ type: 'button', 'class': 'k-button red', text: `${localization.button_Reset}` });
    }
}

export class LeadCancelButton {
    static tag = new IdSelector('cancellead-btn', 'button');
    static render(localization: ILocalization): JQuery {
        return LeadCancelButton.tag.create({
            type: 'button',
            'class': 'k-button',
            text: localization.button_CancelLead,
            disabled: 'disabled'
        });
    }
}

export class LeadCancelReasonSelect {
    static tag = new IdSelector('nogood-reason', 'select');
    static render(options: ILeadCancelReasonOption[], localization: ILocalization): JQuery {
        return Select.render<ILeadCancelReasonOption>(
            /*items*/ options,
            /*value*/(i) => Helper.toString(i.id),
            /*htmlContent*/(i) => i.htmlContent,
            /*isSelected*/(i) => i.id === null,
            /*isDisabled*/(i) => i.disabled,
            /*nullOption*/ undefined,
            /*tag*/ LeadCancelReasonSelect.tag,
            /*attr*/ {
                placeholder: localization.placeholder_LeadCancelReason,
                title: localization.title_LeadCancelReason
            }
        );
    }
    static getOptions(reasons: ILeadResultReason[], localization: ILocalization): ILeadCancelReasonOption[] {
        let options: ILeadCancelReasonOption[] = [],
            l = localization;

        // Add all products option
        options.push({ id: null, htmlContent: localization.placeholder_LeadCancelReason, disabled: false });

        if (!!reasons) {
            reasons.forEach((reason) => {
                options.push({ id: reason.Code, htmlContent: `${reason.Description}` });
            });
        }
        return options;
    }
}

export interface ILeadCancelReasonOption {
    id: string,
    htmlContent: string;
    disabled?: boolean;
}

export class LeadScheduleSection {
    static tag = new IdSelector('schedulesection', 'fieldset');
    static render(isMobile: boolean, hasAppointment: boolean, localization: ILocalization): JQuery {
        return LeadScheduleSection.tag.create({ style: 'display: none;' })
            .append(Field.render({},
                FieldLabel.render({ text: localization.label_LeadScheduleApptDate }),
                LeadScheduleApptDateInput.render(isMobile, localization)
            ))
            //.append(LeadScheduleApptDateInput.render(isMobile, localization))
            .append(ButtonGroup.render(
                LeadScheduleResetButton.render(localization),
                LeadScheduleButton.render(hasAppointment, localization)
            ));
    }
}

export class LeadScheduleApptDateInput {
    static tag = new IdSelector('schedule-date', 'input');
    static render(isMobile: boolean, localization: ILocalization): JQuery {
        return LeadScheduleApptDateInput.tag.create({
            placeholder: localization.placeholder_AppointmentDateTime,
            title: localization.title_AppointmentDateTime,
            required: true,
            type: isMobile ? 'datetime-local' : 'textbox',
            step: 'any',
            'class': isMobile ? 'k-textbox' : ''
        });
    }
}

export class LeadScheduleResetButton {
    static tag = new IdSelector('schedule-cancel-btn', 'button');
    static render(localization: ILocalization): JQuery {
        return LeadScheduleResetButton.tag.create({ type: 'button', 'class': 'k-button red', text: `${localization.button_Reset}` });
    }
}

export class LeadScheduleButton {
    static tag = new IdSelector('schedule-btn', 'button');
    static render(hasAppointment: boolean, localization: ILocalization): JQuery {
        return LeadScheduleButton.tag.create({
            type: 'button',
            'class': 'k-button',
            text: hasAppointment ? localization.button_RescheduleAppointment : localization.button_ScheduleAppointment,
            disabled: 'disabled'
        });
    }
}

export class LeadQuotedSection {
    static tag = new IdSelector('quotedsection', 'fieldset');
    static render(localization: ILocalization): JQuery {
        return LeadQuotedSection.tag.create({ style: 'display: none;' })
            .append(Field.render({},
                FieldLabel.render({ text: localization.label_LeadQuoteTax }),
                LeadQuoteTaxInput.render(localization)
            ))
            .append(Field.render({},
                FieldLabel.render({ text: localization.label_LeadQuoteSubtotal }),
                LeadQuoteSubtotalInput.render(localization)
            ))
            //.append(LeadQuoteTaxInput.render(localization))
            //.append(LeadQuoteSubtotalInput.render(localization))
            .append(ButtonGroup.render(
                LeadQuoteResetButton.render(localization),
                LeadQuoteNoSaleButton.render(localization),
                LeadQuoteSaleButton.render(localization)
            ));
    }
}

export class LeadQuoteTaxInput {
    static tag = new IdSelector('quote-tax', 'input');
    static render(localization: ILocalization): JQuery {
        return LeadQuoteTaxInput.tag.create({
            placeholder: localization.placeholder_QuoteTaxAmount,
            title: localization.title_QuoteTaxAmount,
            required: true,
            type: 'number',
            min: 0,
            step: .01,
            'class': 'k-textbox'
        });
    }
}

export class LeadQuoteSubtotalInput {
    static tag = new IdSelector('quote-subtotal', 'input');
    static render(localization: ILocalization): JQuery {
        return LeadQuoteSubtotalInput.tag.create({
            placeholder: localization.placeholder_QuoteSubtotal,
            title: localization.title_QuoteSubTotal,
            required: true,
            type: 'number',
            min: 0,
            step: .01,
            'class': 'k-textbox'
        });
    }
}

export class LeadQuoteSaleButton {
    static tag = new IdSelector('quote-sale-btn', 'button');
    static render(localization: ILocalization): JQuery {
        return LeadQuoteSaleButton.tag.create({ type: 'button', 'class': 'k-button green', text: `${localization.button_Sale}`, disabled: 'disabled' });
    }
}

export class LeadQuoteNoSaleButton {
    static tag = new IdSelector('quote-nosale-btn', 'button');
    static render(localization: ILocalization): JQuery {
        return LeadQuoteNoSaleButton.tag.create({ type: 'button', 'class': 'k-button', text: `${localization.button_NoSale}`, disabled: 'disabled' });
    }
}

export class LeadQuoteResetButton {
    static tag = new IdSelector('quote-cancel-btn', 'button');
    static render(localization: ILocalization): JQuery {
        return LeadQuoteResetButton.tag.create({ type: 'button', 'class': 'k-button red', text: `${localization.button_Reset}` });
    }
}

export class LeadAddCommentsSection {
    static tag = new IdSelector('addcommentsection', 'fieldset');
    static render(localization: ILocalization): JQuery {
        return LeadAddCommentsSection.tag.create({ style: 'display: none;' })
            .append(Field.render({},
                FieldLabel.render({ text: localization.label_LeadCommentAdd }),
                LeadCommentInput.render(localization)
            ))
            //.append(LeadCommentInput.render(localization))
            .append(ButtonGroup.render(
                LeadAddCommentsResetButton.render(localization),
                LeadAddCommentsButton.render(localization)
            ));
    }
}

export class LeadCommentInput {
    static tag = new IdSelector('comment', 'input');
    static render(localization: ILocalization): JQuery {
        return LeadCommentInput.tag.create({ type: 'text', 'class': 'k-textbox', placeholder: localization.placeholder_NewComments });
    }
}

export class LeadAddCommentsResetButton {
    static tag = new IdSelector('addcomment-cancel-btn', 'button');
    static render(localization: ILocalization): JQuery {
        return LeadAddCommentsResetButton.tag.create({ type: 'button', 'class': 'k-button red', text: `${localization.button_Reset}` });
    }
}

export class LeadAddCommentsButton {
    static tag = new IdSelector('addcomment-btn', 'button');
    static render(localization: ILocalization): JQuery {
        return LeadAddCommentsButton.tag.create({ type: 'button', 'class': 'k-button', text: `${localization.button_AddComment}`, disabled: 'disabled' });
    }
}

export class LeadHistorySection {
    static tag = new IdSelector('leadhistory', 'fieldset');
    static render(leadActivities: ISubcontractorLeadActivity[], localization: ILocalization): JQuery {
        let section = LeadHistorySection.tag.create()
            .append(Legend.render(localization.section_LeadHistory));

        leadActivities = leadActivities || [];
        leadActivities.forEach((item) => {
            let note = Note.render()
                .append(NoteDate.render(`${dateFormat(Helper.toDate(item.ActivityDate), 'mm/dd/yyyy')}`))
                .append(NoteTime.render(`${dateFormat(Helper.toDate(item.ActivityDate), 'h:MM TT')}`))
                .append(NoteBy.render(item.ActivityBy))
                .appendTo(section);
            if (!!item.ApptDateTime) {
                note.append(NoteApptDateTime.render(`${localization.label_Appointment} ${dateFormat(Helper.toDate(item.ApptDateTime), 'mm/dd/yyyy h:MM TT')}`));
            }
            note.append(NoteComment.render(`${localization.label_Notes} ${item.Notes}`));
        })

        return section;
    }
}


export class Note { static tag = new TagSelector('note'); static render(text?: string) { return Note.tag.create({ text: text }); } }
export class NoteDate { static tag = new TagSelector('date'); static render(text?: string) { return NoteDate.tag.create({ text: text }); } }
export class NoteTime { static tag = new TagSelector('time'); static render(text?: string) { return NoteTime.tag.create({ text: text }); } }
export class NoteBy { static tag = new TagSelector('by'); static render(text?: string) { return NoteBy.tag.create({ text: text }); } }
export class NoteApptDateTime { static tag = new TagSelector('appt'); static render(text?: string) { return NoteApptDateTime.tag.create({ text: text }); } }
export class NoteComment { static tag = new TagSelector('comment'); static render(text?: string) { return NoteComment.tag.create({ text: text }); } }