import { IdSelector, TagSelector } from "../Common/Tag";
import { IPagingSorts, ITruck_Sales, LeadStatuses, Products, IProduct, NotificationTypes } from "../Service/ServicesModels";
import { Helper } from "../Utilities/Helper";
import { Select } from "./Generics";
import { ILocalization } from "../Common/ILocalizations";


export class FilterSortSection {
    static tag = new TagSelector('filters');
    static render(
        isPrinciple: boolean,
        localization: ILocalization,
        truckFilter: { trucks: ITruck_Sales[], selected: number, enableAllTrucks?: boolean } = null,
        productFilter: { products: IProduct[], selected: Products } = null,
        leadStatusFilter: { leadStatuses: LeadStatuses[], selected: LeadStatuses } = null,
        notificationTypeFilter: { notificationTypes: NotificationTypes[], selected: NotificationTypes } = null,
        sort: { sorts: IPagingSorts[], sort: string } = null
    ): JQuery {

        let section = FilterSortSection.tag.create({ html: `<div><i class="fa fa-filter"></i><span>${localization.filters}</span></div>` });
        if (!!truckFilter) {
            let options = TruckFilter.getOptions(isPrinciple, truckFilter.selected, truckFilter.trucks, localization, truckFilter.enableAllTrucks);
            section.append(TruckFilter.render(options, truckFilter.selected));
        }
        if (!!productFilter) {
            let options = ProductFilter.getOptions(productFilter.products, localization);
            section.append(ProductFilter.render(options, productFilter.selected));
        }
        if (!!leadStatusFilter) {
            let options = LeadStatusFilter.getOptions(leadStatusFilter.leadStatuses, localization);
            section.append(LeadStatusFilter.render(options, leadStatusFilter.selected));
        }
        if (!!notificationTypeFilter) {
            let options = NotificationTypeFilter.getOptions(notificationTypeFilter.notificationTypes, localization);
            section.append(NotificationTypeFilter.render(options, notificationTypeFilter.selected));
        }

        if (!!sort) section.append(SortSelection.render(sort.sorts, sort.sort));

        return section;
    }
}


export class SortSelection {
    static tag = new IdSelector('sort', 'select');
    static render(sorts: IPagingSorts[], sort: string): JQuery {
        return Select.render<IPagingSorts>(
            /*items*/ sorts,
            /*value*/(i) => !!i ? Helper.toString(i.sort) : null,
            /*htmlContent*/(i) => i.description,
            /*isSelected*/(i) => sort === i.sort,
            /*isDisabled*/(i) => i.disabled,
            /*nullOption*/ undefined,
            /*tag*/ SortSelection.tag
        );
    }
}

export class TruckFilter {
    static tag = new IdSelector('truckfilter', 'select');
    static render(options: ITruckFilterOption[], truckId: number): JQuery {
        return Select.render<ITruckFilterOption>(
            /*items*/ options,
            /*value*/(i) => !!i ? Helper.toString(i.id) : null,
            /*htmlContent*/(i) => i.htmlContent,
            /*isSelected*/(i) => truckId === (!!i ? i.id : null),
            /*isDisabled*/(i) => i.disabled,
            /*nullOption*/ undefined,
            /*tag*/ TruckFilter.tag
        );
    }
    static getOptions(isPrinciple: boolean, truckId: number, trucks: ITruck_Sales[], localization: ILocalization, enableAllTrucks: boolean = true): ITruckFilterOption[] {
        let options: ITruckFilterOption[] = [];

        // Add all trucks option
        if (isPrinciple && enableAllTrucks) {
            options.push({ id: null, htmlContent: localization.option_AllTrucks });
        }
        if (!!trucks) {
            trucks.forEach((t) => {
                if (isPrinciple || t.Id === truckId) {
                    options.push({ id: t.Id, htmlContent: `${t.TruckNumber}-${t.LastName}, ${t.FirstName}` });
                }
            });
        }

        return options;
    }
}

export class ProductFilter {
    static tag = new IdSelector('productfilter', 'select');
    static render(options: IProductFilterOption[], product: Products): JQuery {
        return Select.render<IProductFilterOption>(
            /*items*/ options,
            /*value*/(i) => !!i ? Helper.toString(i.id) : null,
            /*htmlContent*/(i) => i.htmlContent,
            /*isSelected*/(i) => product === (!!i ? i.id : null),
            /*isDisabled*/(i) => i.disabled,
            /*nullOption*/ undefined,
            /*tag*/ ProductFilter.tag
        );
    }
    static getOptions(products: IProduct[], localization: ILocalization): IProductFilterOption[] {
        let options: IProductFilterOption[] = [],
            l = localization;

        // Add all products option
        options.push({ id: null, htmlContent: localization.option_AllProducts });

        if (!!products) {
            products.forEach((product) => {
                options.push({ id: product.Id, htmlContent: `${l.label_Product} ${product.Name}` });
            });
        }
        return options;
    }
}

export class LeadStatusFilter {
    static tag = new IdSelector('statusfilter', 'select');
    static render(options: ILeadStatusFilterOption[], status: LeadStatuses): JQuery {
        return Select.render<ILeadStatusFilterOption>(
            /*items*/ options,
            /*value*/(i) => !!i ? Helper.toString(i.id) : null,
            /*htmlContent*/(i) => i.htmlContent,
            /*isSelected*/(i) => status === (!!i ? i.id : null),
            /*isDisabled*/(i) => i.disabled,
            /*nullOption*/ undefined,
            /*tag*/ LeadStatusFilter.tag
        );
    }
    static getOptions(statuses: LeadStatuses[], localization: ILocalization): ILeadStatusFilterOption[] {
        let options: ILeadStatusFilterOption[] = [],
            l = localization;
        if (!!statuses) {
            statuses.forEach((s) => {
                options.push({ id: s, htmlContent: `${l.label_LeadStatus} ${LeadStatusFilter.getDescription(s, localization)}` })
            });
        }
        return options;
    }
    static getDescription(status: LeadStatuses, localization: ILocalization): string {
        let desc = [
            localization.leadStatusesNewLead,
            localization.leadStatusesAcknowledged,
            localization.leadStatusesLeadCanceled,
            localization.leadStatusesScheduled,
            localization.leadStatusesSold,
            localization.leadStatusesNoSale,
            localization.leadStatusesAllActive,
        ];
        return status !== null ? desc[status] : localization.option_AllProducts;
    }
}

export class NotificationTypeFilter {
    static tag = new IdSelector('notificationtypefiler', 'select');
    static render(options: INotificationTypeOption[], type: NotificationTypes): JQuery {
        return Select.render<INotificationTypeOption>(
            /*items*/ options,
            /*value*/(i) => !!i ? Helper.toString(i.id) : null,
            /*htmlContent*/(i) => i.htmlContent,
            /*isSelected*/(i) => type === (!!i ? i.id : null),
            /*isDisabled*/(i) => i.disabled,
            /*nullOption*/ undefined,
            /*tag*/ NotificationTypeFilter.tag
        );
    }

    static getOptions(types: NotificationTypes[], localization: ILocalization): INotificationTypeOption[] {
        let options: INotificationTypeOption[] = [],
            l = localization;
        if (!!types) {
            types.forEach((s) => {
                options.push({ id: s, htmlContent: `${l.label_NotificationType} ${NotificationTypeFilter.getDescription(s, localization)}` })
            });
        }
        return options;
    }
    static getDescription(type: NotificationTypes, localization: ILocalization): string {
        let desc = [
            localization.notificationTypesAll,
            localization.notificationTypesCompliance,
            localization.notificationTypesSchedule,
            localization.notificationTypesPendingWorkOrder,
            localization.notificationTypesGeneral,
            localization.notificationTypesEPA,
        ];
        return type !== null ? desc[type] : localization.option_AllTypes;
    }
}


export interface ITruckFilterOption {
    id: number;
    htmlContent: string;
    disabled?: boolean;
}

export interface IProductFilterOption {
    id: Products;
    htmlContent: string;
    disabled?: boolean;
}

export interface ILeadStatusFilterOption {
    id: LeadStatuses;
    htmlContent: string;
    disabled?: boolean;
}

export interface INotificationTypeOption {
    id: NotificationTypes;
    htmlContent: string;
    disabled?: boolean;
}