
import { InfinateScrollView } from "../Views/InfinateScrollView";
import { ServiceHandler } from "../Service/ServiceHandler";
import { PageBase } from "./PageBase";
import { IDictionary } from "../Common/IDictionary";
import { Helper } from "../Utilities/Helper";
import { Utilities } from "../Utilities/Utilities";
import { IDocumentInfo } from "../Service/ServicesModels";
import { TagSelector } from "../Common/Tag";


export class DocumentViewPage extends PageBase {
    static tag = new TagSelector("documentviewpage");
    _documentInfo: IDocumentInfo;

    constructor(private service: ServiceHandler) {
        super(DocumentViewPage.tag);
        let self = this;


        self.scroller = new DocumentPageScroller(self, service);
    }

    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;
        if (!await super.load(parameters)) return false;
        self.scroller.clear();
        self._documentInfo = await self.service.getDocumentInfo(Helper.toNumber(parameters.id));

        window.MySessionParameters['DocumentPageScroller_id'] = Helper.toString(self._documentInfo.DocumentStorageId);
        window.MySessionParameters['DocumentPageScroller_pageWidth'] = Helper.toString(self._documentInfo.Width);
        window.MySessionParameters['DocumentPageScroller_pageCount'] = Helper.toString(self._documentInfo.PageCount);

        self.scroller.refreshParameters();
        return true;
    }

    public resize(windowResized: boolean = false) {
        let self = this;
        super.resize();
        if (!!self.element) {
            let items = DocumentPageScroller.tag.get(self.element),
                height = self.calculateViewableHeight(InfinateScrollView.tag.get(self.element),
                    $('#title'), $('#docinfo'), $('#actions')
                )- 50;

            items.height(height);
            self.scroller.resetHeight(height);

            if (windowResized) {
                self.scroller.queryChanged();
            }
        }
    }
    public async init(): Promise<boolean> {
        let self = this;
        if (!await super.init()) return false;

        if (!!self.element) {
            // We need to set a static viewable area;
            //self.resize()
            //$(window).on('resize', function (e) { self.resize(); });
        }
    }

    public async render(): Promise<JQuery> {

        let self = this;
        let element = await super.render();
        if (!element) return null;

        element
            .append($('<h2>', { id: 'title', text: self._documentInfo.FileName }))
            .append($('<div>', { id: 'docinfo' })
                .append($('<p>', { html: `<b>Document: </b>${self._documentInfo.DocumentName}` }))
                .append($('<p>', { html: `<b>Page Count: </b>${self._documentInfo.PageCount}` }))
                .append($('<p>', { html: `<b>Document Type: </b>${self._documentInfo.DocumentType}` }))
                .append($('<p>', { html: `<b>Size: </b>${self._documentInfo.DownloadSize}` }))
                .append($('<p>', { html: `<b>Note: </b>${self._documentInfo.Note}` }))
            );

        element.append(await self._renderActions());
        element.append(await self._renderPages());
        return element;
    }

    async _renderActions(): Promise<JQuery> {
        let self = this;
        return $('<actions>', { id: 'actions' })
            .append($('<a>', { target: '_blank', href: `${self.service.baseUrl}documentStorage/${self._documentInfo.DocumentStorageId}?inline=false&filename=${self._documentInfo.FileName}`, html: `<i class="fa fa-download"></i>Download` }))
            .append($('<a>', { target: '_blank', href: `${self.service.baseUrl}documentStorage/${self._documentInfo.DocumentStorageId}?inline=true&filename=${self._documentInfo.FileName}`, html: `<i class="fa fa-gear"></i>Native viewer` }));
    }

    async _renderPages(): Promise<JQuery> {
        let self = this;
        return $('<documentpages>', {})
            .append(await self.scroller.render());
    }

    // #region Scroller
    public get scroller(): DocumentPageScroller {
        return this.children['scroller'] as DocumentPageScroller;
    }
    public set scroller(val: DocumentPageScroller) {
        this.setOrResetChild('scroller', val);
    }
    // #endregion
}

export class DocumentPageScroller extends InfinateScrollView {
    static tag = new TagSelector("documentpages");

    _documentStorageId: number = null;
    _pageIndex: number = 0;
    _pageCount: number = 0;
    _pageWidth: number = 500;


    constructor(parentPage: PageBase, private service: ServiceHandler) {
        super(parentPage, DocumentPageScroller.tag);
        let self = this;

        self.refreshParameters();
    }

    public refreshParameters() {
        let self = this;
        self._documentStorageId = Helper.toNumber(window.MySessionParameters['DocumentPageScroller_id']);
        self._pageWidth = Helper.toNumber(window.MySessionParameters['DocumentPageScroller_pageWidth']);
        self._pageCount = Helper.toNumber(window.MySessionParameters['DocumentPageScroller_pageCount']);
    }

    public calculatePageZoom(): number {
        let self = this;
        return !!self.element ? Math.floor((Math.max(self.element.width(), 30) - 30) / self._pageWidth * 100) : 100;
    }

    public clear() {
        let self = this;
        self._pageIndex = 0;
        if (!!self.element) {
            self.element.empty();
        }
    }

    public queryChanged() {
        let self = this;
        self.clear();
        self.refresh();
    }

    public async fetchMorePages(): Promise<JQuery[]> {
        let self = this;
        let items: JQuery[] = [];

        if (self._pageIndex > self._pageCount) { }
        else if (self._pageIndex === self._pageCount) { }
        else {
            self._pageIndex++;
            let pg = $('<documentpage>', {}).appendTo(self.element);
            let img = await self.service.getDocumentPage(self._documentStorageId, self._pageIndex, self.calculatePageZoom());
            pg.append(img);
            items.push(pg);
        }
        return items;
    }

    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        //element.append(await self.renderMenu());
        //element.append(await self._renderFilterSortSelect());
        //element.append(await self._renderItems());

        return element;
    }
}
