import { TagSelector, IJQuerySelector, ClassSelector } from "../Common/Tag";

export class Content { static tag = new TagSelector('content'); }
export class Spacer { static tag = new TagSelector('spacer'); }
export class Logo { static tag = new TagSelector('logo'); }
export class Page { static tag = new TagSelector('page'); }
export class PageLoading { static tag = new TagSelector('page-loading'); }

// icons
export class LoaderSpinner { static tag = new ClassSelector('fa fa-spinner fa-spin', 'i'); static render() { return LoaderSpinner.tag.create(); } }
export class WarningIcon { static tag = new ClassSelector('fa fa-exclamation-triangle', 'i'); static render() { return WarningIcon.tag.create(); } }
export class MapNavigationIcon { static tag = new ClassSelector('fa fa-map-marker', 'i'); static render() { return MapNavigationIcon.tag.create(); } }
export class CalendarIcon { static tag = new ClassSelector('fa fa-calendar', 'i'); static render() { return CalendarIcon.tag.create(); } }
export class DocumentIcon { static tag = new ClassSelector('fa fa-file-text-o', 'i'); static render() { return DocumentIcon.tag.create(); } }
export class UploadIcon { static tag = new ClassSelector('fa  fa-upload', 'i'); static render() { return UploadIcon.tag.create(); } }
export class DocumentsIcon { static tag = new ClassSelector('fa fa-files', 'i'); static render() { return DocumentsIcon.tag.create(); } }
export class WorkOrderIcon { static tag = new ClassSelector('fa fa-doc', 'i'); static render() { return WorkOrderIcon.tag.create(); } }
export class AcknowledgeIcon { static tag = new ClassSelector('fa fa-thumbs-up', 'i'); static render() { return AcknowledgeIcon.tag.create(); } }
export class TruckIcon { static tag = new ClassSelector('fa fa-truck', 'i'); static render() { return TruckIcon.tag.create(); } }
export class CrewMemberIcon { static tag = new ClassSelector('fa fa-user', 'i'); static render() { return CrewMemberIcon.tag.create(); } }
export class SchedulerDetailIcon { static tag = new ClassSelector('fa fa-circle', 'i'); static render() { return SchedulerDetailIcon.tag.create(); } }
export class DeleteIcon { static tag = new ClassSelector('fa fa-remove ', 'i'); static render() { return DeleteIcon.tag.create(); } }
export class SaveIcon { static tag = new ClassSelector('fa fa-save ', 'i'); static render() { return SaveIcon.tag.create(); } }
export class AddIcon { static tag = new ClassSelector('fa fa-plus ', 'i'); static render() { return AddIcon.tag.create(); } }

export class CardTitle { static tag = new TagSelector('h4'); static render(text?: string) { return CardTitle.tag.create({ html: text }); } }
export class CardContent { static tag = new TagSelector('p'); static render(text?: string) { return CardContent.tag.create({ html: text }); } }
export class ButtonContent { static tag = new TagSelector('span'); static render(text?: string) { return ButtonContent.tag.create({ html: text }); } }
export class LinkContent { static tag = new TagSelector('span'); static render(text?: string) { return LinkContent.tag.create({ html: text }); } }
export class Span { static tag = new TagSelector('span'); static render(text?: string) { return Span.tag.create({ html: text }); } }
export class Bold { static tag = new TagSelector('b'); static render(text?: string) { return Bold.tag.create({ text: text }); } }
export class TextContent { static tag = new TagSelector('p'); static render(text?: string) { return TextContent.tag.create({ html: text }); } }


export class Link { static tag = new TagSelector('a'); static render(href: string, target: string = null) { return Link.tag.create({ href: href, target: target }); } }

export class PageTitle {
    static tag = new TagSelector('h2');
    static render(title: string, tag: IJQuerySelector = PageTitle.tag): JQuery { return tag.create({ html: title }); }
}
export class PageSubTitle {
    static tag = new TagSelector('h4');
    static render(title: string, tag: IJQuerySelector = PageSubTitle.tag): JQuery { return tag.create({ html: title }); }
}


export class Button {
    static tag = new TagSelector('button');
    static render(id: string, htmlContent: string, cls: string = ''): JQuery {
        return Button.tag.create({ id: id, html: htmlContent, 'class': `k-button` })
            .addClass(cls);
    }
}

export class ButtonGroup {
    static tag = new TagSelector('div');
    static render(...buttons: JQuery[]): JQuery {
        let grp = ButtonGroup.tag.create({ 'class': 'btn-grp' });
        if (!!buttons) {
            for (let i = 0; i < buttons.length; i++) {
                grp.append(buttons[i]);
            }
        }
        return grp;
    }
}


export class FieldSet {
    static tag = new TagSelector('fieldset');
    static render(attributes: JQuery.PlainObject<any> = {}): JQuery {
        return FieldSet.tag.create(attributes);
    }
}

export class Field {
    static tag = new TagSelector('field');
    static render(attributes: JQuery.PlainObject<any> = {}, ...children: JQuery[]): JQuery {
        let field = Field.tag.create(attributes);
        if (!!children) {
            for (let i = 0; i < children.length; i++) {
                field.append(children[i]);
            }
        }
        return field;
    }
}

export class FieldLabel {
    static tag = new TagSelector('fieldlabel');
    static render(attributes: JQuery.PlainObject<any> = {}): JQuery {
        return FieldLabel.tag.create(attributes);
    }
}

export class Legend {
    static tag = new TagSelector('legend');
    static render(caption: string): JQuery {
        return Legend.tag.create({ text: caption });
    }
}


export class Loader {
    static tag = new TagSelector('loader');
    static render(message: string = 'Loading...'): JQuery {
        return Loader.tag.create()
            .append(LoaderSpinner.render())
            .append(ButtonContent.render(message));
    }
}
export class NoResults {
    static tag = new TagSelector('noresults');
    static render(message: string = `Sorry...we couldn't find any matches`): JQuery {
        return NoResults.tag.create()
            .append(WarningIcon.render())
            .append(ButtonContent.render(message));
    }
}

export class Div {
    static tag = new TagSelector('div');
    static render(...children: JQuery[]): JQuery {
        let div = Div.tag.create();
        if (!!children) {
            for (let i = 0; i < children.length; i++) {
                div.append(children[i]);
            }
        }
        return div;
    }
}

export class Select {
    static tag = new TagSelector('select');
    static render<T>(items: T[], value: (item: T) => string, htmlContent: (item: T) => string, isSelected: (item: T) => boolean, isDisabled: (item: T) => boolean, nullOption: T = undefined, tag: IJQuerySelector = Select.tag, attr: JQuery.PlainObject<any> = {}): JQuery {
        let select = tag.create(attr);
        if (nullOption !== undefined) Option.render(value(nullOption), htmlContent(nullOption), isSelected(nullOption)).appendTo(select);
        items.forEach((item) => {
            Option.render(value(item) || '', htmlContent(item), isSelected(item), isDisabled(item)).appendTo(select);
        });
        return select;
    }

}

export class Option {
    static tag = new TagSelector('option');
    static render(value: string, htmlContent: string, isSelected: boolean, isDisabled: boolean = false): JQuery {
        let options = { value: value, html: htmlContent } as any;
        if (isSelected) options['selected'] = 'selected';
        if (isDisabled) options['disabled'] = 'disabled';
        return Option.tag.create(options);
    }
}

export class Links {
    static tag = new TagSelector('links');
    static render(...children: JQuery[]): JQuery {
        let links = Links.tag.create();
        if (!!children) {
            for (let i = 0; i < children.length; i++) {
                links.append(children[i]);
            }
        }
        return links;
    }
}

export class Copyright {
    static tag = new TagSelector('copyright');
    static render(copyright: string): JQuery {
        return Copyright.tag.create({ 'class': 'left', text: copyright });
    }
}

export class PageLoader {
    static tag = new TagSelector('pageloading');
    static showLoader(show: boolean) {
        let loader = PageLoader.tag.get();
        if (show) loader.addClass('active');
        else loader.removeClass('active');
    }
}