import { TagSelector, IdSelector, IJQuerySelector } from "../Common/Tag";
import { ServiceHandler } from "../Service/ServiceHandler";
import { IWorkOrderDocument } from "../Service/ServicesModels";
import { ILocalization } from "../Common/ILocalizations";
import { Legend, NoResults, ButtonGroup, Option, Link, CardTitle, CardContent, Field, FieldLabel, UploadIcon, ButtonContent } from "./Generics";
import { Helper } from "../Utilities/Helper";


export class PacketDocuments {
    static tag = new IdSelector('packetdocs', 'fieldset');
    static async render(service: ServiceHandler, documents: IWorkOrderDocument[], localization: ILocalization, documentViewPageHash: string): Promise<JQuery> {
        return await DocumentsSection.render(service, localization.section_PacketDocuments, documents, localization, documentViewPageHash, PacketDocuments.tag);
    }
}


export class UploadedDocuments {
    static tag = new IdSelector('uploadeddocs', 'fieldset');
    static async render(service: ServiceHandler, documents: IWorkOrderDocument[], localization: ILocalization, documentViewPageHash: string): Promise<JQuery> {
        return await DocumentsSection.render(service, localization.section_UploadedDocuments, documents, localization, documentViewPageHash, UploadedDocuments.tag);
    }
}

export class DocumentsSection {
    static tag = new TagSelector('fieldset');
    static async render(service: ServiceHandler, caption: string, documents: IWorkOrderDocument[], localization: ILocalization, documentViewPageHash: string, tag: IJQuerySelector = DocumentsSection.tag): Promise<JQuery> {
        let section = tag.create()
            .append(Legend.render(caption));

        if (!!documents && documents.length > 0) {
            for (let i = 0; i < documents.length; i++) {
                let doc = documents[i];
                section.append(await DocumentItem.render(service, doc, documentViewPageHash));
            }
        }
        else {
            section.append(NoResults.render(localization.noResults_LeadDocuments));
        }
        return section;
    }
}

export class DocumentItem {
    static tag = new TagSelector('document-item');
    static async render(service: ServiceHandler, doc: IWorkOrderDocument, documentViewPageHash: string, tag: IJQuerySelector = DocumentItem.tag): Promise<JQuery> {
        let link = Link.render(`${documentViewPageHash}${Helper.toQueryString({ id: doc.DocumentStorageId }, '!')}`)
            .append(await service.getDocumentFormatIcon(doc.DocumentFormatId))
            .append(CardTitle.render(doc.DocumentName))
            .append(CardContent.render(doc.DocumentDescription));

        return tag.create()
            .append(link);
    }
}

export class DocumentUploadSection {
    static tag = new IdSelector('upload', 'fieldset');
    static render(localization: ILocalization, uploadDocumentTypes: IUploadTypes[]): JQuery {
        return DocumentUploadSection.tag.create()
            .append(Legend.render(localization.section_DocumentUpload))
            .append(Field.render({},
                FieldLabel.render({ text: localization.label_DocumentType }),
                DocumentUploadTypeSelect.render(localization, uploadDocumentTypes)
            ))
            .append(Field.render({},
                FieldLabel.render({ text: localization.label_FilesToUpload }),
                DocumentUploadSelection.render(uploadDocumentTypes[0].ext, localization)
            ))
            //.append(DocumentUploadTypeSelect.render(localization, uploadDocumentTypes))
            //.append(DocumentUploadSelection.render(uploadDocumentTypes[0].ext, localization))
            .append(ButtonGroup.render(
                CancelDocumentUploadButton.render(localization),
                DocumentUploadButton.render(localization)
            ));
    }
}

export class DocumentUploadTypeSelect {
    static tag = new IdSelector('uploadType', 'select');
    static render(localization: ILocalization, options: IUploadTypes[] = [], tag: IJQuerySelector = DocumentUploadTypeSelect.tag): JQuery {
        let s = tag.create({ placeholder: localization.placeholder_DocumentType });
        options.forEach((o) => { s.append(Option.render(Helper.toString(o.id) || '', o.text, o.id === null, o.disabled)); })
        return s;
    }
}

export class DocumentUploadSelection {
    static tag = new IdSelector('files', 'input');
    static render(accept: string, localization: ILocalization, tag: IJQuerySelector = DocumentUploadSelection.tag): JQuery {
        return tag.create({ placeholder: localization.placeholder_UploadFile, type: 'file', accept: accept, multiple: 'multiple', disabled: 'disabled' })
    }
}

export class CancelDocumentUploadButton {
    static tag = new IdSelector('upload-cancel-btn', 'button');
    static render(localization: ILocalization, tag: IJQuerySelector = CancelDocumentUploadButton.tag): JQuery {
        return tag.create({ html: localization.button_Reset, 'class': 'k-button red', disabled: 'disabled' });
    }
}

export class DocumentUploadButton {
    static tag = new IdSelector('upload-btn', 'button');
    static render(localization: ILocalization, tag: IJQuerySelector = DocumentUploadButton.tag): JQuery {
        return tag.create({ 'class': 'k-button', disabled: 'disabled' })
            .append(UploadIcon.render())
            .append(ButtonContent.render(localization.button_Upload));
    }
}


export interface IUploadTypes {
    id: number;
    text: string;
    limit: number;
    disabled: boolean;
    ext: string;
}