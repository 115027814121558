import { PageBase } from "../Pages/PageBase";
import { TagSelector } from "../Common/Tag";

export class NotFoundPage extends PageBase {
    static tag = new TagSelector("notfoundpage");
    constructor() {
        super(NotFoundPage.tag);
    }

    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        $("<h3>", { text: "Page Not Found" }).appendTo(element);

        return element;
    }
}