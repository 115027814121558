import { Control } from "../Common/Control";
import { IJQuerySelector } from "../Common/Tag";


export class ViewBase extends Control {
    public element: JQuery = null;

    constructor(public tag: IJQuerySelector) { super(); }

    public get(parent?: JQuery): JQuery { return this.tag.get(parent); }

    /** Renders the controls DOM elements. */
    public async render(): Promise<JQuery> {
        if (!this.tag) throw "ViewBase requires an Element tag";
        return this.element = this.tag.create();
    }

    /** Refreshes the controls DOM elements */
    public async refresh(): Promise<boolean> {
        let original = this.element;
        let newView = await this.render();
        original.replaceWith(newView);
        this.init();
        return true;
    }

    //public getLoaderElement(message: string = 'Loading content...'): JQuery {
    //    return this._loaderTag.create()
    //        .append(this._loaderIconTag.create())
    //        .append($('<span>', { text: message }));
    //}
}