import { TagSelector, IJQuerySelector } from "../Common/Tag";
import { IAddress, IPhone, PhoneTypes } from "../Service/ServicesModels";
import { Phone } from "../Utilities/Phone";
import { MapNavigationIcon, LinkContent, Link } from "./Generics";
import { ILocalization } from "../Common/ILocalizations";


export class AddressLine { static tag = new TagSelector('p'); static render(text: string) { return AddressLine.tag.create({ text: text }); } }
export class Address {
    static tag = new TagSelector('address');
    static render(address: IAddress, tag: IJQuerySelector = Address.tag): JQuery {
        let addr = tag.create()
            .append(AddressLine.render(address.StreetLine));
        if (!!address.StreetLine2) {
            addr.append(AddressLine.render(address.StreetLine2));
        }
        addr.append(AddressLine.render(`${address.City}, ${address.State} ${address.Zip}`));
        return addr;
    }
    static renderPending(address: IAddress, tag: IJQuerySelector = Address.tag): JQuery {
        let addr = tag.create();
        addr.append(AddressLine.render(`${address.City}, ${address.State} ${address.Zip}`));
        return addr;
    }
}

export class PhoneNumber {
    static tag = new TagSelector('phone');
    static render(phone: IPhone, localization: ILocalization, tag: IJQuerySelector = PhoneNumber.tag): JQuery {
        return tag.create({ html: `<b>${PhoneNumber.type(phone.Type, localization)}</b> <a href="tel:${phone.PhoneNumber}">${Phone.formatPhone(phone.PhoneNumber)}</a>` })
    }
    static type(type: PhoneTypes, localization: ILocalization): string {
        switch (type) {
            case PhoneTypes.MrsWorkPhone:
            case PhoneTypes.MrWorkPhone:
            case PhoneTypes.Business:
                return localization.label_PhoneWork;
            case PhoneTypes.Mobile:
                return localization.label_PhoneMobile;
            case PhoneTypes.Fax:
                return localization.label_PhoneFax;
            case PhoneTypes.Pager:
                return localization.label_PhonePager;
            default:
                return localization.label_PhoneHome;
        }
    }
}

export class Email {
    static tag = new TagSelector('email');
    static render(email: string, tag: IJQuerySelector = PhoneNumber.tag): JQuery {
        return tag.create({ html: `<a href="mailto:${email}" >${email}</a>` });
    }
}

export class MapLink {
    static tag = new TagSelector('maplink');
    static render(address: IAddress, caption: string = 'Open Map', title: string = '', url: string = 'https://google.com/maps/place/', tag: IJQuerySelector = MapLink.tag): JQuery {
        let mapdetails = `${address.StreetLine},${address.City},${address.State} ${address.Zip}`;

        return tag.create()
            .append(Link.render(`${url}${mapdetails}`, '_blank')
                .append(MapNavigationIcon.render())
                .append(LinkContent.render(caption))
            );
    }
}