import { ViewBase } from "../Views/ViewBase";
import { IDictionary } from "../Common/IDictionary";
import { Utilities } from "../Utilities/Utilities";
import { IJQuerySelector, TagSelector } from "../Common/Tag";
import { Header } from "../Views/Header";
import { Spacer } from "../Partials/Generics";

export class PageBase extends ViewBase {
    static tag = new TagSelector('page');

    constructor(tag: IJQuerySelector) {
        super(tag);
    }

    public async load(parameters: IDictionary<string>): Promise<boolean> {
        return true;
    }

    public async init(): Promise<boolean> {
        let self = this;
        if (!await super.init()) return false;
        if (!!self.element) {
            self.resize();

            $(window).on('resize', function (e) { self.resize(true); });
        }
        return true;
    }

    //public refresh(): void {
    //    this.load({}).then(e => { super.refresh(); });
    //}

    private _calculateViewableHeight(...elements: JQuery[]) {
        let exclude = (e: JQuery) => e.css('position').isIn(['absolute', 'fixed', 'sticky', 'static']),
            vph = $(window).height(),
            header = Utilities.spaceOccupied(Header.tag.get()),
            spacerTop = Utilities.spaceOccupied(Spacer.tag.get().first()),
            spacerBottom = Utilities.spaceOccupied(Spacer.tag.get().last()),
            page = Utilities.spaceOccupied(Page.tag.get()),
            baseUsed = vph
                - (exclude(Header.tag.get()) ? 0 : header.heightUsed)
                - spacerTop.heightUsed
                - (Utilities.isMobile ? 0 : spacerBottom.height)
                - page.verticalBuffer;
        if (!!elements) elements.forEach((e) => {
            let pos = e.css('position');
            baseUsed -= pos === 'absolute' ? Utilities.spaceOccupied(e).heightUsed : 0;
        });
        return baseUsed;
    }
    public calculateViewableHeight(target: JQuery, ...elements: JQuery[]) {
        let exclude = (e: JQuery) => e.css('position').isIn(['absolute', 'fixed', 'sticky', 'static']),
            page = Utilities.spaceOccupied(Page.tag.get()),
            targetSpace = Utilities.spaceOccupied(target),
            baseUsed = page.height - targetSpace.verticalBuffer - 5;
        if (!!elements) elements.forEach((e) => {
            baseUsed -= exclude(e) ? 0 : Utilities.spaceOccupied(e).heightUsed;
        });
        return baseUsed;
    }

    public resize(windowResized: boolean = false) {
        let self = this;
        if (!!self.element) {
            let page = Page.tag.get(),
                height = self._calculateViewableHeight();
            page.height(height);
        }
    }
}

export class Page {
    static tag = new TagSelector('page');
}