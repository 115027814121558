import { IDictionary } from "../Common/IDictionary";
import { ISubcontractorLead, ISubcontractorLeadActivity, IPagedCollection, ILeadResultReason, ISubcontractor, IStartPageCounts, INotification, IWorkOrder, ITruck, ICrewMember, ITruckAccess, NotificationRecipientTypes, IWorkOrderDocument, IDocumentInfo, IProduct, ICalendar, ITruck_Sales, IAdminNotification, IDocument } from "./ServicesModels";
import { Subscription } from "../Common/Subscription";


export class ServiceHandler {

    public get baseUrl(): string { return this._baseUrl; }
    public get token(): string { return this._token; }
    public get tokenRefreshUrl(): string { return this._tokenRefreshUrl; }

    constructor(private _tokenRefreshUrl: string, private _baseUrl: string, private _token: string, private retryLimit: number = 3) {
        if (!_baseUrl.endsWith('/')) _baseUrl += '/';
    }

    _getQueryString(queryParameters: IQueryParameters): string {
        let values: string[] = [];
        if (!!queryParameters) {
            Object.keys(queryParameters).forEach((key) => {
                let v = queryParameters[key];
                if (v !== null && v !== undefined && v !== '') {
                    if (Array.isArray(v as string[] | number[] | boolean[])) {
                        let x: any[] = v as any[];
                        x.forEach((item) => {
                            if (item !== null && item !== undefined && item !== '')
                                values.push(`${encodeURIComponent(key)}=${encodeURIComponent(item.toString())}`);
                        });
                    }
                    else {
                        values.push(`${encodeURIComponent(key)}=${encodeURIComponent(queryParameters[key].toString())}`);
                    }
                }
            });
        }
        return values.length > 0 ? '?' + values.join('&') : '';
    }

    async _doWork<T>(settings: JQuery.AjaxSettings, retryLimit: number = 3, tryCount: number = 1): Promise<T> {
        let self = this;
        try {
            if (!!settings) {
                settings.headers = settings.headers || {};
                settings.headers['__RequestVerificationToken'] = self.token;
            }
            return await $.ajax(settings);
        }
        catch (ex) {
            if (!!ex.status && ex.status === 401) {
                self.onAuthorizationExpired.trigger(self, ex);

                // #region background refresh... didn't work
                //console.log(`Token expired... attempting retry: ${settings.url}`);
                //if (!await self._refreshToken()) throw "Unable to refresh token.";

                //tryCount++;
                //return await self._doWork<T>(settings, retryLimit, tryCount);
                // #endregion
            }
            else if (ex.statusText === 'timeout' && tryCount <= retryLimit) {
                console.log(`Attempting retry: ${settings.url}`);
                tryCount++;
                return await self._doWork<T>(settings, retryLimit, tryCount);
            }
            else if (!!ex.status && ex.status === 400) {
                console.warn('Bad request', ex);
                self.onValidationError.trigger(this, ex.responseJSON);
            }
            else {
                self.onSystemError.trigger(this, ex.responseJSON);
            }
            throw (ex);
        }
    }

    /**
     *  Attempted to create a background cookie refresh, but only refreshed for iframe. CORS violations when tried as ajax.
     **/

    //async _refreshToken(): Promise<boolean> {
    //    let self = this;

    //    return new Promise<boolean>((resolve, reject) => {
    //        let f = document.createElement('iframe') as HTMLIFrameElement;
    //        f.src = self.tokenRefreshUrl;
    //        f.setAttribute('style', 'display: none;');
    //        f.onload = (ev) => {
    //            document.body.removeChild(f);
    //            resolve(true);
    //        };
    //        f.onerror = (e) => {
    //            reject(e);
    //        };
    //        document.body.appendChild(f);
    //    });
    //}

    // #region Events
    public onAuthorizationExpired: Subscription = new Subscription();
    public onValidationError: Subscription = new Subscription();
    public onSystemError: Subscription = new Subscription();
    // #endregion

    // #region Generics
    public async getJson<ReturnType>(url: string, queryParameters: IQueryParameters = null): Promise<ReturnType> {
        let self = this;
        if (url.startsWith('/')) url.substring(1);
        return await self._doWork<ReturnType>({ method: 'GET', url: `${self._baseUrl}${url}${self._getQueryString(queryParameters)}`, dataType: 'json' }, self.retryLimit);
    }
    public async getJsonBoolean(url: string, queryParameters: IQueryParameters = null): Promise<boolean> {
        let self = this;
        if (url.startsWith('/')) url.substring(1);
        await self._doWork<any>({ method: 'GET', url: `${self._baseUrl}${url}${self._getQueryString(queryParameters)}`, dataType: 'json' }, self.retryLimit);
        return true;
    }

    public async postJson<ReturnType, ContentType>(url: string, content: ContentType = null, queryParameters: IQueryParameters = null): Promise<ReturnType> {
        let self = this;
        if (url.startsWith('/')) url.substring(1);
        return await self._doWork<ReturnType>({ method: 'POST', url: `${self._baseUrl}${url}${self._getQueryString(queryParameters)}`, dataType: 'json', contentType: 'application/json', data: JSON.stringify(content) }, self.retryLimit);
    }
    public async postJsonBoolean<ContentType>(url: string, content: ContentType = null, queryParameters: IQueryParameters = null): Promise<boolean> {
        let self = this;
        if (url.startsWith('/')) url.substring(1);
        await self._doWork<any>({ method: 'POST', url: `${self._baseUrl}${url}${self._getQueryString(queryParameters)}`, contentType: 'application/json', data: JSON.stringify(content) }, self.retryLimit);
        return true;
    }

    public async putJson<ReturnType, ContentType>(url: string, content: ContentType = null, queryParameters: IQueryParameters = null): Promise<ReturnType> {
        let self = this;
        if (url.startsWith('/')) url.substring(1);
        return await self._doWork<ReturnType>({ method: 'PUT', url: `${self._baseUrl}${url}${self._getQueryString(queryParameters)}`, dataType: 'json', contentType: 'application/json', data: JSON.stringify(content) }, self.retryLimit);
    }
    public async putJsonBoolean<ContentType>(url: string, content: ContentType = null, queryParameters: IQueryParameters = null): Promise<boolean> {
        let self = this;
        if (url.startsWith('/')) url.substring(1);
        await self._doWork<any>({ method: 'PUT', url: `${self._baseUrl}${url}${self._getQueryString(queryParameters)}`, contentType: 'application/json', data: JSON.stringify(content) }, self.retryLimit);
        return true;
    }

    public async deleteJson(url: string, queryParameters: IQueryParameters = null): Promise<boolean> {
        let self = this;
        if (url.startsWith('/')) url.substring(1);
        await self._doWork<any>({ method: 'DELETE', url: `${self._baseUrl}${url}${self._getQueryString(queryParameters)}` }, self.retryLimit);
        return true;
    }
    // #endregion

    // #region Lookups

    public async getLookupLeadResultReasons(): Promise<ILeadResultReason[]> {
        let self = this;
        return await self.getJson<ILeadResultReason[]>(`leads/nogoodreasons`);
    }

    public async getLookupTrucks(): Promise<ITruck[]> {
        let self = this;
        return await self.getJson<ITruck[]>('trucks/lookup');
    }

    public async getImpersonationTrucks(subcontractorId: number): Promise<ITruck_Sales[]> {
        let self = this;
        return await self.getJson<ITruck_Sales[]>(`administration/trucks/${subcontractorId}`);
    }

    public async getLookupSalesTrucks(): Promise<ITruck_Sales[]> {
        let self = this;
        return await self.getJson<ITruck_Sales[]>('trucks/leads');
    }

    public async getActiveProducts(): Promise<IProduct[]> {
        let self = this;
        return await self.getJson<IProduct[]>('subcontractors/products');
    }

    // #endregion

    // #region Start Page

    public async getCounts(): Promise<IStartPageCounts> {
        let self = this;
        return await self.getJson<IStartPageCounts>('subcontractors/counts');
    }

    public async getSubcontractor(): Promise<ISubcontractor> {
        let self = this;
        return await self.getJson<ISubcontractor>('subcontractors/');
    }

    // #endregion

    // #region Notifications

    public async getPagedActiveNotifications(filter: string, orderby: string, skip: number = 0, take: number = 0): Promise<IPagedCollection<INotification>> {
        let self = this;
        return self.getJson<IPagedCollection<INotification>>('notifications/active', { filter: filter, orderby: orderby, skip: skip, take: take });
    }

    public async acknowledgeNotification(id: number): Promise<INotification> {
        let self = this;
        return self.getJson<INotification>('notifications/Acknowledge', { id: id });
    }

    public async getPagedNotifications(filter: string, orderby: string, skip: number = 0, take: number = 0): Promise<IPagedCollection<IAdminNotification>> {
        let self = this;
        return self.getJson<IPagedCollection<IAdminNotification>>('notifications', { filter: filter, orderby: orderby, skip: skip, take: take });
    }

    public async getNotification(id: number): Promise<IAdminNotification> {
        let self = this;
        return self.getJson<IAdminNotification>(`notifications/${id}`);
    }

    public async saveNotification(notification: IAdminNotification): Promise<IAdminNotification> {
        let self = this;
        return self.postJson<IAdminNotification, IAdminNotification>('notifications', notification);
    }

    public async uploadNotificationDocument(id: number, file: File): Promise<IWorkOrderDocument> {
        let self = this;
        let data = new FormData();
        data.append('files', file);
        return await self._doWork<IWorkOrderDocument>({
            type: 'POST',
            url: `${self.baseUrl}documents/notification/${id}`,
            contentType: false,
            processData: false,
            data: data
        }, self.retryLimit);
    }

    // #endregion

    // #region Trucks

    public async getCrewMembers(): Promise<ICrewMember[]> {
        let self = this;
        return await self.getJson<ICrewMember[]>('trucks');
    }

    public async updateTruckAccess(id: number, isAccessEnabled: boolean, isSOWEnabled: boolean, recipientType: NotificationRecipientTypes): Promise<ITruckAccess> {
        let self = this;
        return await self.postJson<ITruckAccess, ITruckAccess>(`trucks/access`, { SubcontractorCrewMemberId: id, AccessEnabled: isAccessEnabled, SOWEnabled: isSOWEnabled, SubcontractorNotificationRecientTypeId: recipientType } as ITruckAccess);
    }
    // #endregion


    // #region Work Orders
    public async getWorkOrder(id: number): Promise<IWorkOrder> {
        let self = this;
        return self.getJson<ISubcontractor>('workorders/detail/', { id: id });
    }

    public async acknowledgeWorkOrder(id: number): Promise<boolean> {
        let self = this;
        return self.getJsonBoolean('notifications/AcknowledgeWorkOrder', { id: id });
    }

    public async rejectWorkOrder(id: number): Promise<boolean> {
        let self = this;
        return self.getJsonBoolean('notifications/RejectWorkOrder', { id: id });
    }


    public async getPagedWorkOrders(filter: string, orderby: string, skip: number = 0, take: number = 0): Promise<IPagedCollection<IWorkOrder>> {
        let self = this;
        return self.getJson<IPagedCollection<IWorkOrder>>('workorders/', { filter: filter, orderby: orderby, skip: skip, take: take });
    }

    public async getWorkOrderPacketDocuments(id: number): Promise<IWorkOrderDocument[]> {
        let self = this;
        return await self.getJson<IWorkOrderDocument[]>(`packetDocuments/${id}`);
    }

    public async getWorkOrderUploadedDocuments(id: number): Promise<IWorkOrderDocument[]> {
        let self = this;
        return await self.getJson<IWorkOrderDocument[]>(`documents/${id}`);
    }

    public async uploadWorkOrderDocument(id: number, documentType: number, file: File): Promise<IWorkOrderDocument> {
        let self = this;
        let data = new FormData();
        data.append('files', file);
        return await self._doWork<IWorkOrderDocument>({
            type: 'POST',
            url: `${self.baseUrl}documents/${id}${self._getQueryString({ documentTypeId: documentType })}`,
            contentType: false,
            processData: false,
            data: data
        }, self.retryLimit);
    }
    // #endregion

    // #region Leads Page

    public async getSubcontractorLead(subcontractorLeadId: number): Promise<ISubcontractorLead> {
        let self = this;
        let leads = await self.getJson<IPagedCollection<ISubcontractorLead>>('leads', { filter: `Id eq ${subcontractorLeadId}`, orderby: 'Id desc', skip: 0, top: 5 });
        return !!leads && !!leads.items && leads.items.length > 0 ? leads.items[0] : null;
    }

    public async getSubcontractorLeadActivity(subcontractorLeadId: number): Promise<ISubcontractorLeadActivity[]> {
        let self = this;
        return await self.getJson<ISubcontractorLeadActivity[]>(`leads/${subcontractorLeadId}/activity`);
    }


    public async getPagedSubcontractorLeads(truckId: number, filter: string, orderby: string, skip: number = 0, take: number = 0): Promise<IPagedCollection<ISubcontractorLead>> {
        let self = this;
        return self.getJson<IPagedCollection<ISubcontractorLead>>(`leads/${truckId || ''}`, { filter: filter, orderby: orderby, skip: skip, take: take });
    }


    public async getSubcontractorLeadUploadedDocuments(id: number): Promise<IWorkOrderDocument[]> {
        let self = this;
        return await self.getJson<IWorkOrderDocument[]>(`leadDocuments/${id}`);
    }

    public async uploadSubcontractorLeadDocument(id: number, documentType: number, file: File): Promise<IWorkOrderDocument> {
        let self = this;
        let data = new FormData();
        data.append('files', file);
        return await self._doWork<IWorkOrderDocument>({
            type: 'POST',
            url: `${self.baseUrl}leadDocuments/${id}${self._getQueryString({ documentTypeId: documentType })}`,
            contentType: false,
            processData: false,
            data: data
        }, self.retryLimit);
    }
    // #endregion

    // #region Subcontractor Lead Actions
    public async acknowledgeSubcontractorLead(subcontractorLeadId: number): Promise<ISubcontractorLead> {
        let self = this;
        return await self.postJson<ISubcontractorLead, any>(`leads/${subcontractorLeadId}/acknowledge`);
    }

    public async cancelSubcontractorLead(subcontractorLeadId: number, reasonCode: string, recontactOn?: Date): Promise<ISubcontractorLead> {
        let self = this;
        return await self.postJson<ISubcontractorLead, any>(`leads/${subcontractorLeadId}/cancel`, { ReasonCode: reasonCode, RecontactOn: recontactOn });
    }

    public async scheduleSubcontactorLeadAppointment(subcontractorLeadId: number, appointmentDateTime: Date): Promise<ISubcontractorLead> {
        let self = this;
        return await self.postJson<ISubcontractorLead, any>(`leads/${subcontractorLeadId}/schedule`, { ApptDateTime: appointmentDateTime.toEasternISOString() });
    }

    public async addCommentToSubcontractorLead(subcontractorLeadId: number, comment: string): Promise<boolean> {
        let self = this;
        return await self.postJsonBoolean<any>(`leads/${subcontractorLeadId}/addcomment`, { Comment: comment });
    }

    public async submitQuoteForSubcontractorLead(subcontractorLeadId: number, salestax: number, subtotal: number, sold: boolean): Promise<boolean> {
        let self = this;
        return await self.postJsonBoolean<any>(`leads/${subcontractorLeadId}/${sold ? 'sold' : 'nosale'}`, { SalesTax: salestax, Subtotal: subtotal });
    }
    // #endregion

    // #region Documents

    public async getDocumentInfo(documentStorageId: number): Promise<IDocumentInfo> {
        let self = this;
        return await self.getJson<IDocumentInfo>(`documentStorage/${documentStorageId}`, { info: true });
    }

    public async getDocumentPage(documentStorageId: number, pageNumber: number, pageZoom: number): Promise<HTMLImageElement> {
        let self = this;
        return new Promise<HTMLImageElement>((resolve, reject) => {
            const img = new Image();
            img.addEventListener('load', () => resolve(img));
            img.addEventListener('error', err => reject(err));
            img.src = `${self.baseUrl}documentStorage/${documentStorageId}/${self._getQueryString({
                info: false,
                pageNumber: pageNumber,
                pageZoom: pageZoom
            })}`;
        });
    }

    public async getDocumentFormatIcon(documentFormatId: number, scale: number = null): Promise<HTMLImageElement> {
        let self = this;
        return new Promise<HTMLImageElement>((resolve, reject) => {
            const img = new Image();
            img.addEventListener('load', () => resolve(img));
            img.addEventListener('error', err => reject(err));
            img.src = `${self.baseUrl}documentStorage/icon/${documentFormatId}/${self._getQueryString({
                scale: scale
            })}`;
        });
    }


    // #endregion

    // #region Scheduler
    public async getCalendar(subcontractorCrewLeadId: number): Promise<ICalendar> {
        let self = this;
        return await self.getJson<ICalendar>(`scheduler/`, { id: subcontractorCrewLeadId });
    }

    public async updateCalendar(subcontractorCrewLeadId: number, startDate: Date, endDate: Date, isAvailable: boolean): Promise<boolean> {
        let self = this;
        return await self.postJsonBoolean(`scheduler/`, { Id: subcontractorCrewLeadId, StartDate: startDate.toEasternISOString(), EndDate: endDate.toEasternISOString(), IsAvailable: isAvailable });
    }
    //#endregion

    // #region Registration

    public async register(subcontractorId: number, email: string, password: string, passwordConfirmation: string): Promise<boolean> {
        let self = this;
        return await self.postJsonBoolean(`subcontractors/register`, {
            SubContractorID: subcontractorId,
            Email: email,
            Password: password,
            ConfirmPassword: passwordConfirmation
        });
    }

    // #endregion

    // #region Administration

    public async impersonateUser(subcontractorId: number, subcontractorCrewLeadId: number): Promise<boolean> {
        let self = this;
        return await self.postJsonBoolean('administration/impersonate', { SubcontractorId: subcontractorId, SubcontractorCrewLeadId: subcontractorCrewLeadId });
    }

    // #endregion
}

export interface IQueryParameters extends IDictionary<string | number | boolean | Enumerator | string[] | number[] | boolean[]> { }