export { }

declare global {
    interface Number {
        hasFlag: (flag: any) => boolean;
        padZeros: (size: number) => string;
        getRandomInt: (min: number, max: number) => number;
        format: () => string;
        formatUSD: () => string;
        isIn: (items: number[]) => boolean;
    }
}
Number.prototype.hasFlag = function (flag) {
    return ((this & flag) == flag);
};
Number.prototype.padZeros = function (size) {
    if (this === null || size === undefined || size === null) return null;
    size = parseInt(size.toString());
    var s = this + "";
    while (s.length < size) s = "0" + s;
    return s;
}
Number.prototype.getRandomInt = function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
Number.prototype.format = function () {
    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    }).format(this);
}
Number.prototype.formatUSD = function () {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    }).format(this);
}
Number.prototype.isIn = function (items: number[]) {
    return items.indexOf(this) > -1;
}