export { }

declare global {
    interface String {
        joinPath: (partialPath: string) => string;
        templateUri: (partialPath: string) => string;
        classSelector: () => string;
        idSelector: () => string;
        nameSelector: () => string;
        parseMvcDate: () => Date;
        padZero: (size: number) => string;
        pad: (num: number, size: number) => string;
        isIn: (items: string[]) => boolean;
        contains: (value: string) => boolean;
        toDate: () => Date;
    }
}


String.prototype.joinPath = function (partialPath: string) {
    var path = this;
    if (this.substring(this.length - 1, this.length) !== '/') path += '/';
    path += partialPath;
    return path;
};
String.prototype.templateUri = function (partialPath: string) {
    var path = this.joinPath(partialPath);
    if (path.indexOf(".html") < 0) path += '.html';
    return path;
};
String.prototype.classSelector = function () {
    return '.' + this;
};
String.prototype.idSelector = function () {
    return '#' + this;
};
String.prototype.nameSelector = function () {
    return '[name="' + this + '"]';
}
String.prototype.parseMvcDate = function () {
    var pattern = /Date\(([^)]+)\)/;
    var results = pattern.exec(this);
    return new Date(parseFloat(results[1]));
};
String.prototype.padZero = function (size: number): string {
    let s = this as string;
    if (this.length < size) {
        s = "0" + this.toString();
        return s.padZero(size);
    }
    return s;
}
String.prototype.pad = function (num: number, size: number): string {
    var s = this + "";
    while (s.length < size) s = num.toString() + s;
    if (s) s = s.trim();
    return s;
}
String.prototype.isIn = function (items: string[]) {
    return items.indexOf(this) > -1;
}
String.prototype.contains = function (value: string): boolean {
    const s = this as string;
    return s.indexOf(value) != -1;
}

String.prototype.toDate = function (): Date {
    let value = this;
    if (value === undefined) return null;
    if (value === null) return null;
    if (value === "") return null;
    if (value instanceof Date) return value;
    if (typeof value === "string") {
        const d = new Date(value);
        return (d instanceof Date && !isNaN(d as any)) ? d : null;
    }
    return null;
}