export { }

declare global {
    interface Array<T> {
        //isString: () => boolean;
        contains: (searchElement: T, fromIndex?: number) => boolean;
    }
}

Array.prototype.contains = <T>(searchElement: T, fromIndex?: number): boolean => {
    return (this as any).lastIndexOf(searchElement, fromIndex) > -1;
};