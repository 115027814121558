import { IDictionary } from "./IDictionary";


export interface ICalendarLocalization {
    days: string[],
    daysAbr: string[],
    months: string[],
    monthsAbr: string[]
}

export class Localization {

    static parse(source: ILocalization): ILocalization {
        let d = defaults as any;
        let s = source as any;
        if (!source) return d;
        Object.keys(d).forEach((key) => { s[key] = s[key] || d[key]; });
        return s;
    }
}

export interface ILocalization //extends IDictionary<string>
{
    // #region Header

    /** English: User Manual */
    header_UserManual: string;

    /** English:  Notifications*/
    header_Notifications: string;

    /** English:  Leads*/
    header_Leads: string;

    /** English:  Work Orders*/
    header_WorkOrders: string;

    /** English:  Scheduling*/
    header_Scheduling: string;

    /** English:  Trucks*/
    header_Trucks: string;

    /** English:  Administration*/
    header_Administration: string;

    /** English:  Developer*/
    header_Developer: string;

    /** English:  Search*/
    header_Search: string;

    /** English:  View*/
    header_View: string;

    /** English:  Impersonation*/
    header_Impersonation: string;

    /** English: Change Subcontractor */
    header_ChangeSubcontractor: string;

    /** English: Lead Referral */
    header_LeadReferral: string;
    // #endregion

    /** English:  This will NOT update your password if your already registered.*/
    notice_PasswordNoUpdate: string;

    /** English:  Online*/
    online: string;

    /** English:  Offline*/
    offline: string;

    /** English:  Appointment*/
    callout_Appointment: string;

    /** English:  Filters*/
    filters: string;

    /** English:  to*/
    to: string;

    /** English:  Documents*/
    documents: string;

    /** English:  Measure*/
    measure: string;

    /** English: No Detailed Description */
    noDetailDescription: string;

    /** English:  Quantity*/
    quantity: string;

    /** English:  By acknowledging this statement of work, I hereby agree with the scope of work and the labor line items scope. I understand that once the project commences if there are any changes to the scope of work or unforeseen items I must get prior approval from the local district office. I also certify that I am currently covered by General Liability, Commercial Auto Insurance and the appropriate State Workers Compensation coverage or waiver that meets or exceeds the Sears Home Improvement Products Insurance guidelines.*/
    acknowledgementStatement: string;

    /** English:  Contact Transform SR Home Improvements you may be Out-of-Compliance.*/
    out_of_Compliance: string;

    /** English: Your account has been registered.<br/>You should be receiving an email shortly to confirm your account. */
    success_Registration: string;

    /** English: Are you sure you want to decline this WO? */
    confirm_WoRejection: string;

    /** English: Are you sure you want to acknowledge this WO? */
    confirm_WoAcknowledge: string;

    // #region Links
    /** English:  Open Work Order*/
    link_OpenWorkOrder: string;

    /** English:  Open Document*/
    link_OpenDocument: string;

    /** English:  View Document*/
    link_ViewDocument: string;

    /** English: Forgot your password? */
    link_ForgotPassword: string;
    // #endregion

    // #region pages

    /** English:  Lead*/
    page_Lead: string;

    /** English:  Lead - Documents*/
    page_LeadDocuments: string;

    /** English:  Work Order*/
    page_WorkOrder: string;

    /** English:  Work Order - Documents*/
    page_WorkOrderDocuments: string;

    /** English:  Crew Members and Trucks*/
    page_Trucks: string;

    /** English:  Register*/
    page_UserRegistration: string;

    /** English:  Create a new account. This will <b>NOT</b> update your password if your already registered.*/
    page_UserRegistration_Subtitle: string;

    /** English:  Notification*/
    page_NotificationEdit: string;

    /** English:  Create/Edit notification*/
    page_NotificationEdit_Subtitle: string;
    // #endregion

    // #region Loader

    /** English:  Working...*/
    loader_Working: string;

    /** English:  Loading uploaded documents...*/
    loader_UploadedDocuments: string;

    /** English:  Uploading documents...*/
    loader_UploadingDocuments: string;

    /** English:  Loading lead history...*/
    loader_LeadHistory: string;

    /** English:  Loading packet documents...*/
    loader_PacketDocuments: string;

    /** English:  Acknowledging notification...'*/
    loader_AcknowledgingNotification: string;

    /** English:  No documents or photos available...*/
    noResults_LeadDocuments: string;

    /** English:  N/A*/
    noResults_TruckSkills: string;

    /** English:  N/A*/
    noResults_TruckMarkets: string;
    // #endregion

    // #region Sections

    /** English:  Payment Rates*/
    section_PaymentRates: string;

    /** English:  Items*/
    section_Items: string;

    /** English:  History*/
    section_LeadHistory: string;

    /** English:  Packet Documents*/
    section_PacketDocuments: string;

    /** English:  Uploaded Documents*/
    section_UploadedDocuments: string;

    /** English:  Skills*/
    section_TruckSkills: string;

    /** English:  Markets*/
    section_TruckMarkets: string;

    /** English:  Portal Access*/
    section_TruckPortalAccess: string;

    /** English:  Upload Documents/Photos*/
    section_DocumentUpload: string;

    /** English:  Project Coordinator*/
    section_ProjectCoordinator: string;

    /** English:  Project Supervisor*/
    section_ProjectSupervisor: string;

    /** English:  CPC*/
    section_CPC: string;

    section_DocumentStorage: string;

    section_Acknowledgement: string;
    // #endregion

    // #region Input/Select/TextArea

    /** English:  Select reason...*/
    placeholder_LeadCancelReason: string;

    /** English:  Reason lead is being canceled*/
    title_LeadCancelReason: string;

    /** English:  Select date/time of Appointment*/
    placeholder_AppointmentDateTime: string;

    /** English:  Appointment Date/Time*/
    title_AppointmentDateTime: string;

    /** English:  Enter tax*/
    placeholder_QuoteTaxAmount: string;

    /** English:  Tax amount*/
    title_QuoteTaxAmount: string;

    /** English:  Enter subtotal*/
    placeholder_QuoteSubtotal: string;

    /** English:  Subtotal amount*/
    title_QuoteSubTotal: string;

    /** English:  Enter comment...*/
    placeholder_NewComments: string;

    /** English:  'Select document type to upload.*/
    placeholder_DocumentType: string;

    /** English:  Choose files to upload.*/
    placeholder_UploadFile: string;

    /** English:  */
    placeholder_Email: string;

    /** English:  */
    placeholder_SubcontractorId: string;

    /** English:  */
    placeholder_Password: string;

    /** English:  */
    placeholder_PasswordConfirmation: string;

    placeholder_Description: string;


    placeholder_Message: string;

    placeholder_DocumentURL: string;

    placeholder_DocumentStorage: string;

    // #endregion

    // #region Buttons

    /** English:  Schedule Appointment*/
    button_ScheduleAppointment: string;

    /** English:  Reschedule Appointment*/
    button_RescheduleAppointment: string;

    /** English:  Cancel Lead*/
    button_CancelLead: string;

    /** English:  Add Comments*/
    button_AddComment: string;

    /** English:  Reset*/
    button_Reset: string;

    /** English:  Upload*/
    button_Upload: string;

    /** English:  Quoted*/
    button_Quoted: string;

    /** English:  Add Comments*/
    button_AddComments: string;

    /** English:  Sale*/
    button_Sale: string;

    /** English:  No Sale*/
    button_NoSale: string;

    /** English:  Acknowledge*/
    button_Acknowledge: string;

    /** English:  Acknowledge Statement of Work*/
    button_Acknowledgement: string;

    /** English: Reject Statement of Work */
    button_Decline: string;

    /** English:  Open Map*/
    button_OpenMap: string;

    /** English:  Access Enabled*/
    button_TruckAccess_Enable: string;

    /** English:  Access Disabled*/
    button_TruckAccsss_Disable: string;

    /** English:  SOW Enabled*/
    button_SOW_Enable: string;

    /** English:  SOW Disabled*/
    button_SOW_Disable: string;

    /** English:  Close*/
    button_Close: string;

    /** English:  Register*/
    button_Register: string;

    /** English: Go back */
    button_Back: string;
    // #endregion

    // #region Labels

    /** English:  Product:*/
    label_Product: string;

    /** English:  Truck:*/
    label_Truck: string;

    /** English:  Job:*/
    label_Job: string;

    /** English:  WO:*/
    label_WorkOrder: string;

    /** English:  Start on:*/
    label_StartOn: string;

    /** English:  Appt:*/
    label_Appointment: string;

    /** English:  Notes:*/
    label_Notes: string;

    /** English:  Status:*/
    label_LeadStatus: string;

    /** English:  Labor:*/
    label_Labor: string;

    /** English:  Trip Charge:*/
    label_TripCharge: string;

    /** English:  Cash Tickets:*/
    label_CashTickets: string;

    /** English:  Total:*/
    label_Total: string;

    /** English:  Acknowledged on:*/
    label_AcknowledgedOn: string;

    /** English: Acknowledgment Status: */
    label_AcknowledgmentStatus: string;

    /** English:  Lead:*/
    label_Lead: string;

    /** English:  Received on:*/
    label_ReceivedOn: string;

    /** English:  Document Type:*/
    label_DocumentType: string;

    /** English:  Files to Upload:*/
    label_FilesToUpload: string;

    /** English:  Tax amount:*/
    label_LeadQuoteTax: string;

    /** English:  Subtotal:*/
    label_LeadQuoteSubtotal: string;

    /** English:  Appointment Date/Time:*/
    label_LeadScheduleApptDate: string;

    /** English:  Cancelation Reason:*/
    label_LeadCancelationReason: string;

    /** English:  New Comment:*/
    label_LeadCommentAdd: string;

    /** English:  H:*/
    label_PhoneHome: string;

    /** English:  W:*/
    label_PhoneWork: string;

    /** English:  M:*/
    label_PhoneMobile: string;

    /** English:  F:*/
    label_PhoneFax: string;

    /** English:  P:*/
    label_PhonePager: string;

    /** English:  Id:*/
    label_Id: string;

    /** English:  Email:*/
    label_Email: string;

    /** English:  Subcontractor Id:*/
    label_SubcontractorId: string;

    /** English:  Password:*/
    label_Password: string;

    /** English:  Confirm password:*/
    label_PasswordConfirmation: string;

    /** English: Notification Type: */
    label_NotificationType: string;

    label_Description: string;
    label_Message: string;
    label_DocumentURL: string;
    label_DocumentStorage: string;
    label_FileToUpload: string;
    // #endregion

    // #region Options

    /** English:  All Trucks*/
    option_AllTrucks: string;

    /** English:  All Products*/
    option_AllProducts: string;


    /** English:  All Types*/
    option_AllTypes: string;
    // #endregion

    // #region Sorts

    /** English:  Sort Date: Newest - Oldest*/
    dateNewestToOldest: string;

    /** English:  Sort Date: Oldest - Newest*/
    dateOldestToNewest: string;

    /** English:  Sort Name: Z-A*/
    nameDescending: string;

    /** English:  Sort Name: A-Z'*/
    nameAscending: string;
    // #endregion

    // #region Document Types

    /** English:  Select Type*/
    documentTypes_NotSelected: string;

    /** English:  Contract*/
    documentTypes_Contract: string;

    /** English:  Contract (CA)*/
    documentTypes_Contract_CA: string;

    /** English:  Contract Change Authorization*/
    documentTypes_ContractChangeAuthorization: string;

    /** English:  Completion Certificate*/
    documentTypes_CompletionCertificate: string;

    /** English:  Disclosure Notice*/
    documentTypes_DisclosureNotice: string;

    /** English:  Damage Checklist */
    documentTypes_DamageCheckList: string;

    /** English:  Damage Waiver */
    documentTypes_DamageWaiver: string;

    /** English:  HVAC Start up/Completion */
    documentTypes_HvacStartupCompletion: string;

    /** English:  Permit Compliance */
    documentTypes_PermitCompliance: string;

    /** English:  EPA Acknowledgement*/
    documentTypes_EPA_AcknowledgementForm: string;

    /** English:  EPA Acknowledgement - Signed*/
    documentTypes_EPA_Acknowledgement_Signed: string;

    /** English:  EPA LRRP Checklist - Signed*/
    documentTypes_EPA_LRRP_Checklist_Signed: string;

    /** English:  Equipment Invoice from Contractor*/
    documentTypes_EquipmentInvoiceFromContractor: string;

    /** English:  Equipment Receipt from Vendor*/
    documentTypes_EquipmentReceiptFromVendor: string;

    /** English:  Quote Left Behind*/
    documentTypes_LeaveBehindQuote: string;

    /** English:  Load Calculation Worksheet*/
    documentTypes_LoadCalculationWorksheet: string;

    /** English:  Notice of Cancellation*/
    documentTypes_NoticeOfCancellation: string;

    /** English:  Other Document*/
    documentTypes_OtherDocument: string;

    /** English:  Permit*/
    documentTypes_Permit: string;

    /** English:  Permit Fee Receipt*/
    documentTypes_PermitFeeReceipt: string;

    /** English:  Permit Waiver*/
    documentTypes_PermitWaiver: string;

    /** English:  Photo - Before*/
    documentTypes_PhotoBefore: string;

    /** English:  Photo - In Progress*/
    documentTypes_PhotoInProgress: string;

    /** English:  Photo - After*/
    documentTypes_PhotoAfter: string;

    /** English:  Photo - Lead Safe*/
    documentTypes_PhotoLeadSafe: string;

    /** English:  Proposal Worksheet*/
    documentTypes_ProposalWorksheet: string;

    /** English:  Purchase Summary*/
    documentTypes_PurchaseSummary: string;

    /** English:  Sam's Gift Card*/
    documentTypes_SamsGiftCardDoc: string;

    /** English:  Waiver of Lein*/
    documentTypes_WaiverOfLein: string;
    // #endregion

    // #region Lead Statuses

    /** English:  New Lead*/
    leadStatusesNewLead: string;

    /** English:  Acknowledged*/
    leadStatusesAcknowledged: string;

    /** English:  Lead Canceled*/
    leadStatusesLeadCanceled: string;

    /** English:  Scheduled*/
    leadStatusesScheduled: string;

    /** English:  Quoted - Sold*/
    leadStatusesSold: string;

    /** English:  Quoted - Not Sold*/
    leadStatusesNoSale: string;

    /** English:  All Active Leads*/
    leadStatusesAllActive: string;
    // #endregion

    // #region Work Order Types

    /** English:  Appointment*/
    workOrderType_Appointment: string;

    /** English:  Service*/
    workOrderType_Service: string;

    /** English:  Measure*/
    workOrderType_Measure: string;

    /** English:  Install*/
    workOrderType_Install: string;
    // #endregion

    // #region notification receipent types

    /** English:  Notifications: Principle Only*/
    notificationRecipientType_PrincipleOnly: string;

    /** English:  Notifications: Truck Only*/
    notificationRecipientType_TruckOnly: string;

    /** English:  Notifications: Principle and Truck*/
    notificationRecipientType_PrincipleAndTruck: string;

    // #endregion

    // #region notification types

    /** English: All General and EPA */
    notificationTypesAll: string;

    /** English: Compliance */
    notificationTypesCompliance: string;

    /** English: Schedule */
    notificationTypesSchedule: string;

    /** English: Pending Work Order */
    notificationTypesPendingWorkOrder: string;

    /** English: General */
    notificationTypesGeneral: string;

    /** English: EPA */
    notificationTypesEPA: string;

    // #endregion

    // #region acknowledgment statuses

    /** English: Accepted */
    acknowledgementStatusAccepted: string;
    /** English: Rejected */
    acknowledgementStatusRejected: string;
    /** English: Offer Expired */
    acknowledgementStatusExpired: string;

    // #endregion

    /** English: Can not upload this file [<%fileName%>]. It exceeds upload limit [<%limit%>].*/
    validation_FileSizeExceeded: string;

    // #region Scheduler

    /** English: Available */
    scheduler_Available: string;

    /** English: Job */
    scheduler_Job: string;

    /** English: Service */
    scheduler_Service: string;

    /** English: Measure */
    scheduler_Measure: string;

    /** English: Available All Day */
    scheduler_allDayAvailable: string;

    /** English: Unavailable All Day */
    scheduler_allDayUnavailable: string;

    /** English: Pending */
    scheduler_Pending: string;

    /** English: Lead */
    scheduler_Lead: string;

    // #endregion

    // #region Password Validator

    /** English:  Password must contain the following: */
    passwordValidator_Title: string;

    /** English:  A <b>lowercase</b> letter */
    passwordValidator_Letter: string;

    /** English:  A <b>capital (uppercase)</b> letter */
    passwordValidator_Capital: string;

    /** English:  A <b>number</b> */
    passwordValidator_Number: string;

    /** English:  A <b>symbol (!@#$%*()_+^&)</b> */
    passwordValidator_Symbol: string;

    /** English:  Minimum <b>8 characters</b> */
    passwordValidator_Length: string;

    /** English:  <b>Confirmation</b> must match */
    passwordValidator_Confirmation: string;

    // #endregion
}

export let defaults: ILocalization = {

    // #region Header
    header_UserManual: 'User Manual',
    header_Notifications: 'Notifications',
    header_Leads: 'Leads',
    header_WorkOrders: 'Work Orders',
    header_Scheduling: 'Scheduling',
    header_Trucks: 'Trucks',
    header_Administration: 'Administration',
    header_Developer: 'Developer',

    header_Search: 'Search',
    header_View: 'View',
    header_Impersonation: 'Impersonation',
    header_ChangeSubcontractor: 'Change Subcontractor',
    header_LeadReferral: 'Lead Referral',

    // #endregion

    online: 'Online',
    offline: 'Offline',
    notice_PasswordNoUpdate: '<b>Notice:</b> This will <b>NOT</b> update your password if you are already registered.',

    callout_Appointment: 'Appointment',
    filters: 'Filters',
    to: 'to',
    documents: "Documents",
    noDetailDescription: "No Detailed Description",
    measure: "Measure",
    quantity: "Quantity",
    acknowledgementStatement: "By acknowledging this statement of work, I hereby agree with the scope of work and the labor line items scope. I understand that once the project commences if there are any changes to the scope of work or unforeseen items I must get prior approval from the local district office. I also certify that I am currently covered by General Liability, Commercial Auto Insurance and the appropriate State Workers Compensation coverage or waiver that meets or exceeds the Sears Home Improvement Products Insurance guidelines.",
    out_of_Compliance: "Contact Transform SR Home Improvements you may be Out-of-Compliance.",

    success_Registration: "Your account has been registered.<br/>You should be receiving an email shortly to confirm your account.",
    confirm_WoRejection: 'Are you sure you want to decline this WO?',
    confirm_WoAcknowledge: 'Are you sure you want to acknowledge this WO?',

    // links
    link_OpenWorkOrder: 'Open Work Order',
    link_OpenDocument: 'Open Document',
    link_ViewDocument: 'View Document',
    link_ForgotPassword: 'Forgot your password?',

    // pages
    page_LeadDocuments: 'Lead - Documents',
    page_WorkOrder: "Work Order",
    page_WorkOrderDocuments: "Work Order - Documents",
    page_Trucks: 'Crew Members and Trucks',
    page_Lead: 'Lead',
    page_UserRegistration: 'Register',
    page_UserRegistration_Subtitle: 'Create a new account.',

    page_NotificationEdit: 'Notification',
    page_NotificationEdit_Subtitle: 'Create/Edit notification',

    // loader
    loader_Working: 'Working...',
    loader_UploadingDocuments: 'Uploading documents...',
    loader_LeadHistory: 'Loading lead history...',
    loader_PacketDocuments: 'Loading packet documents...',
    loader_UploadedDocuments: 'Loading uploaded documents...',
    loader_AcknowledgingNotification: 'Acknowledging notification...',

    noResults_LeadDocuments: 'No documents or photos available...',
    noResults_TruckSkills: 'N/A',
    noResults_TruckMarkets: 'N/A',

    // sections
    section_DocumentUpload: 'Upload Documents/Photos',
    section_PacketDocuments: 'Packet Documents',
    section_UploadedDocuments: 'Uploaded Documents',
    section_PaymentRates: 'Payment Rates',
    section_Items: 'Items',
    section_TruckSkills: 'Skills',
    section_TruckMarkets: 'Markets',
    section_TruckPortalAccess: 'Portal Access',
    section_LeadHistory: 'History',
    section_CPC: 'CPC',
    section_ProjectCoordinator: 'Project Coordinator',
    section_ProjectSupervisor: 'Project Supervisor',
    section_DocumentStorage: 'Document Storage',
    section_Acknowledgement: 'Acknowledge Work Order',

    // form controls - input/select
    placeholder_DocumentType: 'Select document type to upload.',
    placeholder_UploadFile: 'Choose files to upload.',

    placeholder_LeadCancelReason: 'Select reason...',
    title_LeadCancelReason: 'Reason lead is being canceled',

    placeholder_AppointmentDateTime: 'Select date/time of Appointment',
    title_AppointmentDateTime: 'Appointment Date/Time',

    placeholder_QuoteTaxAmount: 'Enter tax',
    title_QuoteTaxAmount: 'Tax amount',

    placeholder_QuoteSubtotal: 'Enter subtotal',
    title_QuoteSubTotal: 'Subtotal amount',

    placeholder_NewComments: 'Enter comment...',

    placeholder_Email: 'This email must be your Transform email.',
    placeholder_SubcontractorId: 'This id must be your Transform id.',
    placeholder_Password: '* Required *',
    placeholder_PasswordConfirmation: '* Required *',

    placeholder_Description: '',
    placeholder_DocumentStorage: '',
    placeholder_DocumentURL: '',
    placeholder_Message: '',

    // buttons
    button_Acknowledge: 'Acknowledge',
    button_TruckAccess_Enable: 'Access Enabled',
    button_TruckAccsss_Disable: 'Access Disabled',
    button_SOW_Enable: 'SOW Enabled',
    button_SOW_Disable: 'SOW Disabled',

    // form controls - buttons
    button_OpenMap: 'Open Map',
    button_Acknowledgement: "Acknowledge Statement of Work",
    button_Decline: 'Decline Statement of Work',
    button_CancelLead: "Cancel Lead",
    button_AddComment: "Add",
    button_Reset: 'Reset',
    button_Upload: 'Upload',
    //button_ScheduleAppointment: 'Schedule Appt',
    button_Quoted: 'Quoted',
    button_AddComments: 'Add Comments',
    button_Sale: 'Sale',
    button_NoSale: 'No Sale',
    button_ScheduleAppointment: "Schedule Appointment",
    button_RescheduleAppointment: "Reschedule Appointment",
    button_Close: 'Close',
    button_Register: 'Register',
    button_Back: 'Go back',


    label_AcknowledgedOn: 'Acknowledged on:',
    label_AcknowledgmentStatus: 'Acknowledgment Status:',
    label_Lead: 'Lead:',
    label_ReceivedOn: 'Received on:',
    label_Labor: "Labor:",
    label_TripCharge: "Trip Charge:",
    label_CashTickets: "Cash Tickets:",
    label_Total: "Total:",
    label_Job: 'Job:',
    label_WorkOrder: 'WO:',
    label_StartOn: 'Start on:',
    label_Appointment: 'Appt:',
    label_Notes: 'Notes:',
    label_LeadStatus: "Status:",
    label_Product: 'Product:',
    label_Truck: 'Truck:',
    label_DocumentType: 'Document Type:',
    label_FilesToUpload: 'Files to Upload:',
    label_LeadQuoteTax: 'Tax amount:',
    label_LeadQuoteSubtotal: 'Subtotal:',
    label_LeadScheduleApptDate: 'Appointment Date/Time:',
    label_LeadCancelationReason: 'Cancelation Reason:',
    label_LeadCommentAdd: 'New Comment:',
    label_PhoneHome: 'H:',
    label_PhoneWork: 'W:',
    label_PhoneMobile: 'M:',
    label_PhoneFax: 'F:',
    label_PhonePager: 'P:',
    label_Id: 'Id:',

    label_Email: 'Email:',
    label_SubcontractorId: 'Subcontractor Id:',
    label_Password: 'Password:',
    label_PasswordConfirmation: 'Confirm password:',

    label_NotificationType: 'Notification Type:',

    label_Description: 'Description:',
    label_Message: 'Message:',
    label_DocumentURL: 'Link:',
    label_DocumentStorage: 'Document Storage Id:',
    label_FileToUpload: 'File to Upload',

    option_AllProducts: 'All Products',
    option_AllTrucks: 'All Trucks',
    option_AllTypes: 'All Types',

    // Sorts
    dateNewestToOldest: 'Sort Date: Newest - Oldest',
    dateOldestToNewest: 'Sort Date: Oldest - Newest',
    nameDescending: 'Sort Name: Z-A',
    nameAscending: 'Sort Name: A-Z',

    // documentTypes
    documentTypes_NotSelected: 'Select Type',
    documentTypes_Contract: 'Contract',
    documentTypes_Contract_CA: 'Contract (CA)',
    documentTypes_ContractChangeAuthorization: 'Contract Change Authorization',
    documentTypes_CompletionCertificate: 'Completion Certificate',
    documentTypes_DamageCheckList: 'Damage Checklist',
    documentTypes_DamageWaiver: 'Damage Waiver',    
    documentTypes_DisclosureNotice: 'Disclosure Notice',
    documentTypes_EPA_AcknowledgementForm: 'EPA Acknowledgement',
    documentTypes_EPA_Acknowledgement_Signed: 'EPA Acknowledgement - Signed',
    documentTypes_EPA_LRRP_Checklist_Signed: 'EPA LRRP Checklist - Signed',
    documentTypes_EquipmentInvoiceFromContractor: 'Equipment Invoice from Contractor',
    documentTypes_EquipmentReceiptFromVendor: 'Equipment Receipt from Vendor',
    documentTypes_HvacStartupCompletion: 'HVAC Start up/Completion',
    documentTypes_LeaveBehindQuote: 'Quote Left Behind',
    documentTypes_LoadCalculationWorksheet: 'Load Calculation Worksheet',
    documentTypes_NoticeOfCancellation: 'Notice of Cancellation',
    documentTypes_OtherDocument: 'Other Document',
    documentTypes_Permit: 'Permit',
    documentTypes_PermitCompliance: 'Permit Compliance ',
    documentTypes_PermitFeeReceipt: 'Permit Fee Receipt',
    documentTypes_PermitWaiver: 'Permit Waiver',
    documentTypes_PhotoBefore: 'Photo - Before',
    documentTypes_PhotoInProgress: 'Photo - In Progress',
    documentTypes_PhotoAfter: 'Photo - After',
    documentTypes_PhotoLeadSafe: 'Photo - Lead Safe',
    documentTypes_ProposalWorksheet: 'Proposal Worksheet',
    documentTypes_PurchaseSummary: 'Purchase Summary',
    documentTypes_SamsGiftCardDoc: "Sam's Gift Card",
    documentTypes_WaiverOfLein: 'Waiver of Lein',

    // Lead Statues
    leadStatusesNewLead: "New Lead",
    leadStatusesAcknowledged: "Acknowledged",
    leadStatusesLeadCanceled: "Lead Canceled",
    leadStatusesScheduled: "Scheduled",
    leadStatusesSold: "Quoted - Sold",
    leadStatusesNoSale: "Quoted - Not Sold",
    leadStatusesAllActive: "All Active Leads",

    // #region Work Order Types
    workOrderType_Appointment: 'Appointment',
    workOrderType_Service: 'Service',
    workOrderType_Measure: 'Measure',
    workOrderType_Install: 'Install',
    // #endregion

    // #region Notification Recipent Types
    notificationRecipientType_PrincipleOnly: 'Notifications: Principle Only',
    notificationRecipientType_TruckOnly: 'Notifications: Truck Only',
    notificationRecipientType_PrincipleAndTruck: 'Notifications: Principle and Truck',
    // #endregion

    // #region notification types
    notificationTypesAll: 'All General and EPA',
    notificationTypesCompliance: 'Compliance',
    notificationTypesSchedule: 'Schedule',
    notificationTypesPendingWorkOrder: 'Pending Work Order',
    notificationTypesGeneral: 'General',
    notificationTypesEPA: 'EPA',
    // #endregion


    // #region acknowledgment status
    acknowledgementStatusAccepted: 'Accepted',
    acknowledgementStatusRejected: 'Declined',
    acknowledgementStatusExpired: 'Offer Expired',
    // #endregion

    validation_FileSizeExceeded: "Can not upload this file [<%fileName%>]. It exceeds upload limit [<%limit%>].",

    // #region Scheduler
    scheduler_Available: 'Available',
    scheduler_Job: 'Job',
    scheduler_Service: 'Service',
    scheduler_Measure: 'Measure',
    scheduler_Pending: 'Pending',
    scheduler_Lead: 'Lead',


    scheduler_allDayAvailable: 'Available All Day',
    scheduler_allDayUnavailable: 'Unavailable All Day',
    // #endregion

    // #region Password Validator
    passwordValidator_Title: 'Password must contain the following',
    passwordValidator_Letter: 'A <b>lowercase</b> letter',
    passwordValidator_Capital: 'A <b>capital (uppercase)</b> letter',
    passwordValidator_Number: 'A <b>number</b>',
    passwordValidator_Symbol: 'A <b>symbol (!@#$%*()_+^&)</b>',
    passwordValidator_Length: 'Minimum <b>8 characters</b>',
    passwordValidator_Confirmation: '<b>Confirmation</b> must match'

    // #endregion
}