import { ViewBase } from "./ViewBase";
import { TagSelector, IJQuerySelector } from "../Common/Tag";
import { Utilities } from "../Utilities/Utilities";
import { Loader, NoResults } from "../Partials/Generics";
import { PageBase } from "../Pages/PageBase";


export class InfinateScrollView extends ViewBase {
    static tag = new TagSelector('infinatescrollview');
    constructor(private parentPage: PageBase, private contentTag: IJQuerySelector = new TagSelector('div'), tag?: IJQuerySelector) {
        super(tag || InfinateScrollView.tag);
    }


    public async init(): Promise<boolean> {
        let self = this;

        if (!!self.element) {
            self.element
                .css('overflow-y', 'auto')
                .css('min-height', 425)
                .css('display', 'block')
                .scroll((e) => {
                    self._observe();
                });
            self.resetHeight();
            await self._loadMoreItems();
        }
        return true;
    }

    public async fetchMorePages(): Promise<JQuery[]> {
        return [];
    }

    _observing = false;
    async _observe(): Promise<boolean> {
        let self = this,
            container = self.element.children(self.contentTag.selector).first()
            ;
        if (!self._observing) {
            self._observing = true;
            let t = container.height() - self.element.height();
            if (self.element.scrollTop() >= t) {
                //console.log("observe", {
                //    container: t,
                //    scroll: self.element.scrollTop()
                //});
                await self._loadMoreItems();
            }
            self._observing = false;
            return true;
        }
    }

    async _loadMoreItems(): Promise<boolean> {
        let self = this,
            container = self.element.children(self.contentTag.selector).first();
        self._showLoader(true);
        try {
            let next = await self.fetchMorePages();
            if (!!next && next.length > 0) {
                next.forEach((item) => item.appendTo(container));
                await self._fillPages();
            }
        }
        catch (ex) {
            if (!!ex.status && ex.status === 401) {
                window.location.reload();
            }
            else {
                alert(ex.responseJSON.Message);
            }
        }
        self._showNoResults(container.children().length === 0);
        self._showLoader(false);
        return true;
    }

    _showLoader(show: boolean) {
        let self = this,
            loader = self.element.children(Loader.tag.selector).first();
        if (show && (!loader || loader.length == 0)) {
            loader = Loader.render('Loading content...')
                .appendTo(self.element);
        }
        else if (!show) {
            loader.remove();
        }
    }

    _showNoResults(show: boolean) {
        let self = this,
            noResult = self.element.children(NoResults.tag.selector).first();
        if (show && (!noResult || noResult.length == 0)) {
            noResult = NoResults.render(`Sorry...we couldn't find any matches.`)
                .appendTo(self.element);
        }
        else if (!show) {
            noResult.remove();
        }
    }

    async _fillPages(): Promise<boolean> {
        let self = this,
            container = self.element.children(self.contentTag.selector).first();

        let currentPage = container.children().last();
        if (!!currentPage && currentPage.length > 0) {
            if (Utilities.spaceOccupied(self.element).heightUsed > (currentPage.position().top + currentPage.height())) {
                return await self._loadMoreItems();
            }
        }
        else {
            return await self._loadMoreItems();
        }
        return true;
    }

    public resetHeight(height?: number) {
        if (!!this.element) {
            let self = this,
                containerHeight: number = 0;

            height = height || self.element.parent().height();

            containerHeight = height;// - self.element.position().top;
            this.element
                .css('height', containerHeight > 0 ? containerHeight : height);
        }
    }

    public async render(): Promise<JQuery> {
        let self = this;
        let item = await super.render();
        self.contentTag.create().appendTo(item);
        return item;
    }

    public async refresh(): Promise<boolean> {
        if (! await super.refresh()) return false;
        let self = this;
        self.parentPage.resize();
        return true;
    }
}