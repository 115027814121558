import { PageBase } from "./PageBase";
import { IDictionary } from "../Common/IDictionary";
import { ICrewMember, IOffice, ISkillSet } from "../Service/ServicesModels";
import { Phone } from "../Utilities/Phone";
import { ServiceHandler } from "../Service/ServiceHandler";
import { TruckAccess } from "../Views/TruckAccess";
import { TagSelector } from "../Common/Tag";
import { Legend, PageTitle } from "../Partials/Generics";
import { ILocalization } from "../Common/ILocalizations";
import { SubcontractorCrewMember } from "../Partials/Trucks";



export class TrucksPage extends PageBase {

    static CrewMemberTag: string = 'crewmember';
    static TruckTag: string = 'truck';
    static NoResultsTag: string = 'noresults';

    constructor(private service: ServiceHandler, private localization: ILocalization, private mapRedirectURL: string = 'https://google.com/maps/place/') {
        super(new TagSelector('truckspage'));
    }

    _crewMembers: ICrewMember[] = null;
    public get crewMembers(): ICrewMember[] { return this._crewMembers; }

    public getOrAddTruckAccessControl(crewMember: ICrewMember) {
        let self = this,
            key = `truckAccess-${crewMember.Id}`,
            access = this.children[key] as TruckAccess;

        if (!access) {
            access = new TruckAccess(crewMember, self.service, self.localization);
            this.setOrResetChild(key, access);
        }
        return access;
    }

    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;
        if (! await super.load(parameters)) return false;
        self._crewMembers = await self.service.getCrewMembers();
        return true;
    }

    public async init(): Promise<boolean> {
        let self = this;
        if (! await super.init()) return false;
        return true;
    }


    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        element.append(PageTitle.render(self.localization.page_Trucks));

        for (let i = 0; i < self._crewMembers.length; i++) {
            element.append(await SubcontractorCrewMember.render(self._crewMembers[i], self.localization, self.mapRedirectURL, (cm) => { return self.getOrAddTruckAccessControl(cm); }));
        }

        return self.element;
    }
}