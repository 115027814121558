import dateFormat from "dateformat";
import { ILocalization } from "../Common/ILocalizations";
import { ClassSelector, IdSelector, IJQuerySelector, TagSelector } from "../Common/Tag";
import { IAdminNotification, INotification, NotificationTypes, IScope, AcknowledgmentStatuses } from "../Service/ServicesModels";
import { Helper } from "../Utilities/Helper";
import { DocumentUploadButton } from "./Documents";
import { AcknowledgeIcon, ButtonContent, CardContent, CardTitle, DocumentIcon, Field, FieldLabel, Legend, Link, LinkContent, WorkOrderIcon, Span, Button, DeleteIcon, Div, ButtonGroup, AddIcon, SaveIcon } from "./Generics";



export class WorkOrderLink {
    static tag = Link.tag;
    static render(pageHash: string, laborBillId: number, localization: ILocalization): JQuery {
        return Link.render(`${pageHash}!id=${laborBillId}`)
            .append(WorkOrderIcon.render())
            .append(LinkContent.render(localization.link_OpenWorkOrder));
    }
}

export class ViewDocumentLink {
    static tag = Link.tag;
    static render(pageHash: string, documentStorageId: number, localization: ILocalization): JQuery {
        return Link.render(`${pageHash}!id=${documentStorageId}`)
            .append(DocumentIcon.render())
            .append(LinkContent.render(localization.link_ViewDocument));
    }
}
export class OpenDocumentLink {
    static tag = Link.tag;
    static render(documentURL: string, localization: ILocalization): JQuery {
        return Link.render(documentURL, '_blank')
            .append(DocumentIcon.render())
            .append(LinkContent.render(localization.link_OpenDocument));
    }
}
export class AcknowledgeNotificationButton {
    static tag = new ClassSelector('acknowledge', 'button');
    static render(notification: INotification, localization: ILocalization, tag: IJQuerySelector = AcknowledgeNotificationButton.tag): JQuery {
        return tag.create({ type: 'button', 'class': 'k-button', 'data-id': notification.Id, })
            .append(AcknowledgeIcon.render())
            .append(ButtonContent.render(localization.button_Acknowledge));
    }
}

export class NotificationLinks {
    static tag = new TagSelector('documentlinks');
    static render(notification: INotification, localization: ILocalization, documentPageHash: string, workOrderPageHash: string, tag: IJQuerySelector = NotificationLinks.tag): JQuery {
        let links = tag.create();
        if ((notification.Type.Id as NotificationTypes).isIn([NotificationTypes.EPA, NotificationTypes.General]) &&
            !notification.AcknowledgedOn) {
            links.append(AcknowledgeNotificationButton.render(notification, localization));
        }
        if (!!notification.DocumentUrl) {
            links.append(OpenDocumentLink.render(notification.DocumentUrl, localization));
        }
        if (!!notification.DocumentStorageId) {
            links.append(ViewDocumentLink.render(documentPageHash, notification.DocumentStorageId, localization));
        }
        if (!!notification.LaborBillId && !Helper.contains([AcknowledgmentStatuses.Rejected, AcknowledgmentStatuses.Expired], notification.AcknowledgementStatus)) {
            links.append(WorkOrderLink.render(workOrderPageHash, notification.LaborBillId, localization));
        }
        return links;
    }
}

export class Notification {

    static getTypeEnum(leadStatus: NotificationTypes): string {
        let enums = [
            '',
            'Compliance',
            'Schedule',
            'PendingWorkOrder',
            'General',
            'EPA',
        ];
        return enums[leadStatus];
    }
    static getDescription(type: NotificationTypes, localization: ILocalization): string {
        let desc = [
            localization.notificationTypesCompliance,
            localization.notificationTypesSchedule,
            localization.notificationTypesPendingWorkOrder,
            localization.notificationTypesGeneral,
            localization.notificationTypesEPA,
        ];
        return type !== null ? desc[type] : localization.option_AllTypes;
    }
}

export class ActiveNotificationCard {
    static tag = new TagSelector('notification');
    static render(notification: INotification, localization: ILocalization, documentPageHash: string, workOrderPageHash: string, tag: IJQuerySelector = ActiveNotificationCard.tag): JQuery {
        let item = tag.create({ 'data-id': notification.Id })
            .append(CardTitle.render(notification.Description))
            .append(CardContent.render(notification.Message))
            .append(CardContent.render(`${localization.label_ReceivedOn} ${dateFormat(Helper.toDate(notification.ActiveOn), 'mm/dd')}`));

        if (!!notification.AcknowledgedOn) {
            item.append(CardContent.render(`${localization.label_AcknowledgedOn} ${dateFormat(Helper.toDate(notification.AcknowledgedOn), 'mm/dd')}`));
        }
        if (!!notification.AcknowledgementStatus) {
            item.append(CardContent.render(`${localization.label_AcknowledgmentStatus} ${ActiveNotificationCard.getAcknowledgementStatus(notification.AcknowledgementStatus, localization)}`));
        }

        item.append(NotificationLinks.render(notification, localization, documentPageHash, workOrderPageHash));
        return item;
    }

    static getAcknowledgementStatus(status: AcknowledgmentStatuses, localization: ILocalization) {
        let desc = [
            localization.acknowledgementStatusAccepted,
            localization.acknowledgementStatusRejected,
            localization.acknowledgementStatusExpired
        ];
        return status != null ? desc[status - 1] : '';
    }
}
export class NotificationCard {
    static tag = new TagSelector('notification');
    static render(notification: IAdminNotification, tag: IJQuerySelector = ActiveNotificationCard.tag): JQuery {
        return tag.create({ 'data-id': notification.Id })
            .append(CardTitle.render(notification.Description))
            .append(CardContent.render(notification.Message))
            .data('notification', notification);
    }
}

export class NotificationDocumentStorageSection {
    static tag = new IdSelector('document-storage', 'fieldset');
    static render(notification: IAdminNotification, localization: ILocalization): JQuery {
        let section = NotificationDocumentStorageSection.tag.create()
            .append(Legend.render(localization.section_DocumentStorage))
            .append(Field.render({},
                FieldLabel.render({ html: localization.label_DocumentStorage }),
                NotificationDocumentStorageIdInput.render(notification.DocumentStorageId, localization)
            ));

        if (Helper.hasValue(notification.Id)) {
            section
                .append(Field.render({},
                    FieldLabel.render({ html: localization.label_FileToUpload }),
                    NotificationDocumentFileInput.render(localization)
                ))
                .append(ButtonGroup.render(DocumentUploadButton.render(localization)));
        }

        return section;
    }
}


export class NotificationDescriptionInput {
    static tag = new IdSelector('description', 'input');
    static render(description: string, localization: ILocalization): JQuery {
        return NotificationDescriptionInput.tag.create({ value: description, 'class': 'k-textbox' });
    }
}
export class NotificationMessageInput {
    static tag = new IdSelector('message', 'input');
    static render(message: string, localization: ILocalization): JQuery {
        return NotificationMessageInput.tag.create({ value: message, 'class': 'k-textbox' });
    }
}
export class NotificationDocumentLinkInput {
    static tag = new IdSelector('documentlink', 'input');
    static render(documentLink: string, localization: ILocalization): JQuery {
        return NotificationDocumentLinkInput.tag.create({ value: documentLink, 'class': 'k-textbox' });
    }
}
export class NotificationTypeInput {
    static tag = new IdSelector('type', 'input');
    static render(checked: boolean, value: NotificationTypes, localization: ILocalization): JQuery {
        let type = NotificationTypeInput.tag.create({ type: 'radio', value: value, 'class': '' });
        if (checked) type.attr('checked', '');
        return type;
    }
}
export class NotificationDocumentStorageIdInput {
    static tag = new IdSelector('documentstorageid', 'input');
    static render(id: number, localization: ILocalization): JQuery {
        return NotificationDocumentStorageIdInput.tag.create({ type: 'number', value: id, 'class': 'k-textbox' });
    }
}
export class NotificationDocumentFileInput {
    static tag = new IdSelector('file', 'input');
    static render(localization: ILocalization): JQuery {
        return NotificationDocumentFileInput.tag.create({ type: 'file', /*accept: accept,*/ /*multiple: 'multiple'*/ });
    }
}

export class NotificationActiveOnInput {
    static tag = new IdSelector('activeon', 'input');
    static render(date: Date, isMobile: boolean, localization: ILocalization): JQuery {
        return NotificationActiveOnInput.tag.create({
            placeholder: localization.placeholder_AppointmentDateTime,
            title: localization.title_AppointmentDateTime,
            required: true,
            type: isMobile ? 'datetime-local' : 'textbox',
            step: 'any',
            'class': isMobile ? 'k-textbox' : ''
        });
    }
}
export class NotificationCriticalOnInput {
    static tag = new IdSelector('criticalon', 'input');
    static render(date: Date, isMobile: boolean, localization: ILocalization): JQuery {
        return NotificationCriticalOnInput.tag.create({
            placeholder: localization.placeholder_AppointmentDateTime,
            title: localization.title_AppointmentDateTime,
            required: true,
            type: isMobile ? 'datetime-local' : 'textbox',
            step: 'any',
            'class': isMobile ? 'k-textbox' : ''
        });
    }
}
export class NotificationDistrictInput {
    static tag = new IdSelector('districtid', 'input');
    static render(id: number, localization: ILocalization): JQuery {
        return NotificationDistrictInput.tag.create({ type: 'number', value: id, 'class': 'k-textbox' });
    }
}
export class NotificationProductInput {
    static tag = new IdSelector('productid', 'input');
    static render(id: number, localization: ILocalization): JQuery {
        return NotificationProductInput.tag.create({ type: 'number', value: id, 'class': 'k-textbox' });
    }
}
export class NotificationSubcontractorInput {
    static tag = new IdSelector('subcontractorid', 'input');
    static render(id: number, localization: ILocalization): JQuery {
        return NotificationSubcontractorInput.tag.create({ type: 'number', value: id, 'class': 'k-textbox' });
    }
}

export class NotificationLaborBillInput {
    static tag = new IdSelector('laborbillid', 'input');
    static render(id: number, localization: ILocalization): JQuery {
        return NotificationLaborBillInput.tag.create({ type: 'number', value: id, 'class': 'k-textbox' });
    }
}

export class NotificationScopeAddButton {
    static tag = new IdSelector('addscope-btn', 'button');
    static render(localization: ILocalization): JQuery {
        return NotificationScopeAddButton.tag.create({ 'class': 'k-button', disabled: 'disabled' })
            .append(AddIcon.render())
            .append(ButtonContent.render('Add Scope'));
    }
}

export class NotificationAddScopeSection {
    static tag = new IdSelector('add-scope', 'fieldset');
    static render(isMobile: boolean, localization: ILocalization): JQuery {
        return NotificationAddScopeSection.tag.create()
            .append(Legend.render('Add Scopes'))
            .append(Field.render({},
                FieldLabel.render({ html: 'Active On:' }),
                NotificationActiveOnInput.render(null, isMobile, localization)
            ))

            .append(Field.render({},
                FieldLabel.render({ html: 'Critical On:' }),
                NotificationCriticalOnInput.render(null, isMobile, localization)
            ))

            .append(Field.render({},
                FieldLabel.render({ html: 'District Id:' }),
                NotificationDistrictInput.render(null, localization)
            ))

            .append(Field.render({},
                FieldLabel.render({ html: 'Product Id:' }),
                NotificationProductInput.render(null, localization)
            ))

            .append(Field.render({},
                FieldLabel.render({ html: 'Subcontractor Id:' }),
                NotificationSubcontractorInput.render(null, localization)
            ))

            .append(Field.render({},
                FieldLabel.render({ html: 'Labor Bill Id:' }),
                NotificationLaborBillInput.render(null, localization)
            ))

            .append(ButtonGroup.render(NotificationScopeAddButton.render(localization)));

    }
}

export class NotificationScopesSection {
    static tag = new IdSelector('scopes', 'fieldset');
    static render(scopes: IScope[], localization: ILocalization): JQuery {
        let section = NotificationScopesSection.tag.create()
            .append(Legend.render('Scopes'));

        scopes.forEach((scope) => {
            section.append(NotificationScope.render(scope, localization));
        });

        return section;
    }
}

export class NotificationScope {
    static tag = new TagSelector('scope');
    static deleteScopeTag = new ClassSelector('delete-scope', 'button');
    static render(scope: IScope, localization: ILocalization): JQuery {
        let element = NotificationScope.tag.create();
        if (!!scope.ActiveOn) element.append(Span.render(`<b>Active On:</b> ${dateFormat(scope.ActiveOn, 'm/dd/yyyy | hh:MM TT')}`));
        if (!!scope.CriticalOn) element.append(Span.render(`<b>Critical On:</b> ${dateFormat(scope.CriticalOn, 'm/dd/yyyy | hh:MM TT')}`));
        if (!!scope.DistrictId) element.append(Span.render(`<b>District Id:</b> ${scope.DistrictId}`));
        if (!!scope.ProductId) element.append(Span.render(`<b>Product Id:</b> ${scope.ProductId}`));
        if (!!scope.SubcontractorId) element.append(Span.render(`<b>Subcontractor Id:</b> ${scope.SubcontractorId}`));
        if (!!scope.LaborBillId) element.append(Span.render(`<b>Labor Bill Id:</b> ${scope.LaborBillId}`));


        element.append(ButtonGroup.render(
            Button.render(`remove-scope-btn_${scope.Id}`, '', NotificationScope.deleteScopeTag.cls)
                .append(DeleteIcon.render())
                .append(Span.render('Remove'))
        ));
        return element.data('notificationScope', scope);
    }
}

export class NotificationSaveButton {
    static tag = new IdSelector('save-btn', 'button');
    static render(localization: ILocalization): JQuery {
        return NotificationSaveButton.tag.create({ 'class': 'k-button', disabled: 'disabled' })
            .append(SaveIcon.render())
            .append(ButtonContent.render('Save'));
    }
}

export class NotificationAddButton {
    static tag = new IdSelector('add-btn', 'button');
    static render(localization: ILocalization): JQuery {
        return NotificationAddButton.tag.create({ 'class': 'k-button' })
            .append(AddIcon.render())
            .append(ButtonContent.render('Add'));
    }
}