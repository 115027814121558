

import { PageBase } from "../Pages/PageBase";
import { IDictionary } from "../Common/IDictionary";
import { ISubcontractorLead, LeadStatuses, ILeadResultReason, ISubcontractorLeadActivity } from "../Service/ServicesModels";
import { Helper } from "../Utilities/Helper";
import { Utilities } from "../Utilities/Utilities";
import { ServiceHandler } from "../Service/ServiceHandler";
import { TagSelector } from '../Common/Tag';
import { ILocalization } from '../Common/ILocalizations';
import { Loader, PageTitle, } from '../Partials/Generics';
import { LeadHistorySection, LeadDetail, LeadCustomer, LeadDocumentsLink, LeadActions, LeadActionCancelButton, LeadCancelSection, LeadActionScheduleButton, LeadScheduleSection, LeadActionQuoteButton, LeadQuotedSection, LeadActionAddCommentButton, LeadAddCommentsSection, LeadQuoteNoSaleButton, LeadQuoteSaleButton, LeadQuoteTaxInput, LeadQuoteSubtotalInput, LeadAddCommentsButton, LeadCommentInput, LeadCancelButton, LeadScheduleButton, LeadScheduleApptDateInput, LeadCancelReasonSelect, LeadQuoteResetButton, LeadAddCommentsResetButton, LeadScheduleResetButton, LeadCancelResetButton } from '../Partials/Leads';

export class LeadsPage extends PageBase {

    constructor(private localization: ILocalization, private service: ServiceHandler, private leadDocumentsPageHashTag: string, private mapRedirectURL: string = 'https://google.com/maps/place/') {
        super(new TagSelector("leadpage"));

        this.statusDescriptions = [
            localization.leadStatusesNewLead,
            localization.leadStatusesAcknowledged,
            localization.leadStatusesLeadCanceled,
            localization.leadStatusesScheduled,
            localization.leadStatusesSold,
            localization.leadStatusesNoSale,
        ];

        this.statusEnums = [
            'NewLead',
            'Acknowledged',
            'LeadCanceled',
            'Scheduled',
            'Sold',
            'NoSale'
        ];
    }

    _noGoodReasons: ILeadResultReason[];
    statusDescriptions: string[];
    statusEnums: string[];

    _lead: ISubcontractorLead = null;
    public get lead(): ISubcontractorLead { return this._lead; }

    _leadActivities: ISubcontractorLeadActivity[] = [];
    public get activities(): ISubcontractorLeadActivity[] { return this._leadActivities; }

    get scheduleDate(): kendo.ui.DateTimePicker { return this.kendoControls['scheduleDate'] as any; }
    //get recontactDate(): kendo.ui.DateTimePicker { return this.kendoControls['recontactDate'] as any; }
    //get noGoodReason(): kendo.ui.DropDownList { return this.kendoControls['noGoodReason'] as any; }

    public get isActionsVisible(): boolean { return $('#actions').is(':visible'); }
    public set isActionsVisible(val: boolean) { val ? $('#actions').show() : $('#actions').hide(); }


    public get isCancelLeadVisible(): boolean { return $('#cancelsection').is(':visible'); }
    public set isCancelLeadVisible(val: boolean) { val ? $('#cancelsection').show() : $('#cancelsection').hide(); }


    public get isScheduleAppointmentVisible(): boolean { return $('#schedulesection').is(':visible'); }
    public set isScheduleAppointmentVisible(val: boolean) { val ? $('#schedulesection').show() : $('#schedulesection').hide(); }


    public get isAddCommentVisible(): boolean { return $('#addcommentsection').is(':visible'); }
    public set isAddCommentVisible(val: boolean) { val ? $('#addcommentsection').show() : $('#addcommentsection').hide(); }

    public get isQuotedVisible(): boolean { return $('#quotedsection').is(':visible'); }
    public set isQuotedVisible(val: boolean) { val ? $('#quotedsection').show() : $('#quotedsection').hide(); }

    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;

        if (! await super.load(parameters)) return false;
        self._lead = await self.service.getSubcontractorLead(Helper.toNumber(parameters.id));

        if (self._lead.Status == LeadStatuses.NewLead) {
            self._lead = await self.service.acknowledgeSubcontractorLead(self._lead.Id);
        }
        if (self._lead.Status.isIn([LeadStatuses.Acknowledged, LeadStatuses.Scheduled])) {
            self._noGoodReasons = await self.service.getLookupLeadResultReasons();
        }

        //self._leadActivities = await self.service.getSubcontractorLeadActivity(self._lead.Id);
        return true;
    }

    public async init(): Promise<boolean> {
        let self = this;
        if (! await super.init()) return false;
        if (!!self.element) {
            self._refreshLeadHistory();

            self.element.off();

            let scheduleDate = (document.getElementById(LeadScheduleApptDateInput.tag.id) as HTMLInputElement),
                minScheduleDate = new Date().DateOnly().addDays(-7);

            if (!!scheduleDate) scheduleDate.min = minScheduleDate.toISOString();

            if (!Utilities.isMobile) {
                self.kendoControls['scheduleDate'] = LeadScheduleApptDateInput.tag.get(self.element).kendoDateTimePicker({
                    value: null,
                    dateInput: true,
                    min: minScheduleDate,
                    change: (e) => {
                        let v = e.sender.value();
                        Utilities.SetDisabled(LeadScheduleButton.tag.get(self.element), !v);
                    }
                }).data('kendoDateTimePicker');

            }
            self.element
                .on('click', LeadActionCancelButton.tag.selector, function (e) {
                    self.isCancelLeadVisible = true;
                    self.isActionsVisible = false;
                })
                .on('click', LeadActionScheduleButton.tag.selector, function (e) {
                    self.isScheduleAppointmentVisible = true;
                    self.isActionsVisible = false;
                })
                .on('click', LeadActionAddCommentButton.tag.selector, function (e) {
                    self.isAddCommentVisible = true;
                    self.isActionsVisible = false;
                })
                .on('click', LeadActionQuoteButton.tag.selector, function (e) {
                    self.isQuotedVisible = true;
                    self.isActionsVisible = false;
                })
                .on('click', [LeadCancelResetButton.tag.selector, LeadScheduleResetButton.tag.selector, LeadAddCommentsResetButton.tag.selector, LeadQuoteResetButton.tag.selector].join(', '), function () {
                    self.isAddCommentVisible = false;
                    self.isCancelLeadVisible = false;
                    self.isQuotedVisible = false;
                    self.isScheduleAppointmentVisible = false;
                    self.isActionsVisible = true;

                    // clear inputs
                    $('input', self.element).val(null);
                    $('select', self.element).val(null);

                    // disable buttons
                    Utilities.SetDisabled(LeadCancelButton.tag.get(self.element), true);
                    Utilities.SetDisabled(LeadAddCommentsButton.tag.get(self.element), true);
                    Utilities.SetDisabled(LeadScheduleButton.tag.get(self.element), true);
                    Utilities.SetDisabled(LeadQuoteSaleButton.tag.get(self.element), true);
                    Utilities.SetDisabled(LeadQuoteNoSaleButton.tag.get(self.element), true);
                })
                .on('change', LeadCommentInput.tag.selector, function (e) {
                    let v = $(e.target).val();
                    Utilities.SetDisabled(LeadAddCommentsButton.tag.get(self.element), !v);
                })
                .on('keyup', LeadCommentInput.tag.selector, function (e) {
                    let v = $(e.target).val();
                    Utilities.SetDisabled(LeadAddCommentsButton.tag.get(self.element), !v);
                })
                .on('change', [LeadQuoteTaxInput.tag.selector, LeadQuoteSubtotalInput.tag.selector].join(', '), function (e) {
                    let invalid = LeadQuoteTaxInput.tag.get(self.element).is(':invalid') ||
                        LeadQuoteSubtotalInput.tag.get(self.element).is(':invalid');
                    Utilities.SetDisabled(LeadQuoteSaleButton.tag.get(self.element), invalid);
                    Utilities.SetDisabled(LeadQuoteNoSaleButton.tag.get(self.element), invalid);
                })
                .on('keyup', [LeadQuoteTaxInput.tag.selector, LeadQuoteSubtotalInput.tag.selector].join(', '), function (e) {
                    let invalid = LeadQuoteTaxInput.tag.get(self.element).is(':invalid') ||
                        LeadQuoteSubtotalInput.tag.get(self.element).is(':invalid');
                    Utilities.SetDisabled(LeadQuoteSaleButton.tag.get(self.element), invalid);
                    Utilities.SetDisabled(LeadQuoteNoSaleButton.tag.get(self.element), invalid);
                })
                .on('click', LeadAddCommentsButton.tag.selector, async function (e) {
                    let loader = Loader.render(self.localization.loader_Working).appendTo(LeadAddCommentsSection.tag.get(self.element));
                    try {
                        let comment = Helper.toString(LeadCommentInput.tag.get(self.element).val());
                        if (!!comment) {
                            await self.service.addCommentToSubcontractorLead(self.lead.Id, comment);
                            self.isAddCommentVisible = false;
                            self.isActionsVisible = true;
                            LeadCommentInput.tag.get(self.element).val(null);

                        }
                    }
                    catch (ex) { }
                    loader.remove();
                    await self._refreshLeadHistory();
                })
                .on('click', LeadQuoteSaleButton.tag.selector, async function (e) {
                    let loader = Loader.render(self.localization.loader_Working).appendTo(LeadQuotedSection.tag.get(self.element));
                    try {
                        let subtotal = Helper.toNumber(LeadQuoteSubtotalInput.tag.get(self.element).val());
                        let tax = Helper.toNumber(LeadQuoteTaxInput.tag.get(self.element).val());
                        if (!!subtotal && !!tax) {
                            await self.service.submitQuoteForSubcontractorLead(self.lead.Id, tax, subtotal, true);
                            await self.load({ id: Helper.toString(self.lead.Id) });
                            self.refresh();
                        }
                    }
                    catch (ex) { }
                    loader.remove();
                })
                .on('click', LeadQuoteNoSaleButton.tag.selector, async function (e) {
                    let loader = Loader.render(self.localization.loader_Working).appendTo(LeadQuotedSection.tag.get(self.element));
                    try {
                        let subtotal = Helper.toNumber(LeadQuoteSubtotalInput.tag.get(self.element).val());
                        let tax = Helper.toNumber(LeadQuoteTaxInput.tag.get(self.element).val());
                        if (!!subtotal && !!tax) {
                            await self.service.submitQuoteForSubcontractorLead(self.lead.Id, tax, subtotal, false);
                            await self.load({ id: Helper.toString(self.lead.Id) });
                            self.refresh();
                        }
                    }
                    catch (ex) { }
                    loader.remove();
                })
                .on('change', LeadCancelReasonSelect.tag.selector, function (e) {
                    let v = $(e.target).val();
                    Utilities.SetDisabled(LeadCancelButton.tag.get(self.element), !v);
                })
                .on('change', LeadScheduleApptDateInput.tag.selector, function (e) {
                    let v = $(e.target).val();
                    Utilities.SetDisabled(LeadScheduleButton.tag.get(self.element), !v);
                })
                .on('click', LeadCancelButton.tag.selector, async function (e) {
                    let loader = Loader.render(self.localization.loader_Working).appendTo(LeadCancelSection.tag.get(self.element));
                    try {
                        let reasonCode = Helper.toString(LeadCancelReasonSelect.tag.get(self.element).val());
                        if (!!reasonCode) {
                            self._lead = await self.service.cancelSubcontractorLead(self.lead.Id, reasonCode);
                            self.refresh();
                        }
                    }
                    catch (ex) { }
                    loader.remove();
                })
                .on('click', LeadScheduleButton.tag.selector, async function (e) {
                    let loader = Loader.render(self.localization.loader_Working).appendTo(LeadScheduleSection.tag.get(self.element));
                    try {
                        let scheduleDate = Utilities.isMobile ? Helper.toDate(LeadScheduleApptDateInput.tag.get(self.element).val()) :
                            (!!self.scheduleDate ? self.scheduleDate.value() : null);

                        if (!!scheduleDate) {
                            self._lead = await self.service.scheduleSubcontactorLeadAppointment(self.lead.Id, scheduleDate);
                            self.refresh();
                        }
                    }
                    catch (ex) { }
                    loader.remove();
                })
                ;
        }

        return true;
    }

    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;

        element
            .append(PageTitle.render(`${self.localization.page_Lead} ${self.statusDescriptions[self.lead.Status]}`))
            .append(LeadDetail.render(self.lead, self.localization))
            .append(LeadCustomer.render(self.lead.Lead.Customer, self.lead.Status, self.lead.Lead.ApptDateTime, self.mapRedirectURL, self.localization));

        if (self.lead.Status.isIn([LeadStatuses.Sold, LeadStatuses.NoSale])) {
            element.append(LeadDocumentsLink.render(self.lead.Id, self.leadDocumentsPageHashTag, self.localization));
        }
        let actions = LeadActions.render().appendTo(element);

        if (self.lead.Status.isIn([LeadStatuses.Acknowledged, LeadStatuses.Scheduled])) {
            actions.append(LeadActionCancelButton.render(self.localization));
            element.append(LeadCancelSection.render(self._noGoodReasons, self.localization));
        }

        if (self.lead.Status.isIn([LeadStatuses.Acknowledged, LeadStatuses.Scheduled])) {
            actions.append(LeadActionScheduleButton.render(self.localization));
            element.append(LeadScheduleSection.render(Utilities.isMobile, !!self.lead.Lead.ApptDateTime, self.localization));
        }
        if (self.lead.Status.isIn([LeadStatuses.Scheduled])) {
            actions.append(LeadActionQuoteButton.render(self.localization));
            element.append(LeadQuotedSection.render(self.localization));
        }

        actions.append(LeadActionAddCommentButton.render(self.localization));
        element.append(LeadAddCommentsSection.render(self.localization));
        element.append(LeadHistorySection.render(self._leadActivities, self.localization));

        return self.element;
    }

    async _refreshLeadHistory(): Promise<boolean> {
        let self = this;
        if (!!self.element) {
            let historySection = LeadHistorySection.tag.get(self.element);
            let loader = Loader.render(self.localization.loader_LeadHistory)
                .appendTo(historySection);
            try {
                self._leadActivities = await self.service.getSubcontractorLeadActivity(self.lead.Id);
                historySection.replaceWith(LeadHistorySection.render(self._leadActivities, self.localization));
            }
            catch (ex) {
                loader.remove();
            }
        }
        return true;
    }
}
