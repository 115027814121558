
import { LeadStatuses, IPagingSorts, Products, ITruck, ISubcontractorLead, IPagedCollection, ITruck_Sales, IProduct } from "../Service/ServicesModels";
import { PageBase } from "./PageBase";
import { Helper } from "../Utilities/Helper";
import { InfinateScrollView } from "../Views/InfinateScrollView";
import { Utilities } from "../Utilities/Utilities";
import dateFormat from "dateformat";
import { IDictionary } from "../Common/IDictionary";
import { ServiceHandler } from "../Service/ServiceHandler";
import { TagSelector } from "../Common/Tag";
import { ILocalization } from "../Common/ILocalizations";
import { FilterSortSection, TruckFilter, ProductFilter, LeadStatusFilter, SortSelection } from "../Partials/Filters";
import { Lead, LeadCard } from "../Partials/Leads";
import { AppSession } from "../AppSession";


export class LeadSearchPage extends PageBase {

    //static FiltersTag: string = "filters";
    //static TruckTag: string = "truckfilter";
    //static ProductFilterTag: string = "productfilter";
    //static StatusFilterTag: string = "statusfilter";
    //static SortTag: string = "sort";
    //static ItemsTag: string = "items";


    _trucks: ITruck[];
    _sorts: IPagingSorts[];
    _products: IProduct[] = [];
    _statuses: LeadStatuses[] = [];

    constructor(private localization: ILocalization, private service: ServiceHandler, private leadsPageHashTag: string, private isPrinciple: boolean, private subcontractorCrewLeadId: number) {
        super(new TagSelector("leadsearchpage"));
        let self = this;

        this._sorts = [
            { sort: "Id desc", description: self.localization.dateNewestToOldest },
            { sort: "Id asc", description: self.localization.dateOldestToNewest },
            { sort: "Lead/Customer/LastName asc", description: self.localization.nameAscending },
            { sort: "Lead/Customer/LastName desc", description: self.localization.nameDescending },
        ];

        this._statuses = [
            LeadStatuses.AllActive,
            LeadStatuses.NewLead,
            LeadStatuses.Acknowledged,
            LeadStatuses.Scheduled,
            LeadStatuses.Sold,
            LeadStatuses.NoSale,
            LeadStatuses.LeadCanceled
        ];

        self.scroller = new LeadSearchScroller(self, self.service, self.localization, self.leadsPageHashTag);
    }



    public async load(parameters: IDictionary<string>): Promise<boolean> {
        let self = this;
        if (!await super.load(parameters)) return false;
        self.scroller.clear();
        if (!!parameters.truck) AppSession.setData('LeadSearch', 'truckId', parameters.truck);
        if (!!parameters.product) AppSession.setData('LeadSearch', 'productId', parameters.product);
        if (!!parameters.status) AppSession.setData('LeadSearch', 'status', parameters.status);

        self._trucks = await self.service.getLookupSalesTrucks();
        self._products = await self.service.getActiveProducts();

        self.scroller.refreshParameters();
        return true;
    }


    public async init(): Promise<boolean> {
        let self = this;
        if (!await super.init()) return false;

        if (!!self.element) {
            // We need to set a static viewable area;
            //self.resize()
            //$(window).on('resize', function (e) { self.resize(); });

            //$(LeadSearchPage.MenuTag, self.element).children("ul").kendoMenu();
            $(self.element)
                .off()
                .on('change', TruckFilter.tag.selector, function (e) {
                    self.scroller.truckId = Helper.toNumber($(this).val() || null);
                    FilterSortSection.tag.get(self.element).blur();
                })
                .on('change', ProductFilter.tag.selector, function (e) {
                    self.scroller.productId = Helper.toNumber($(this).val() || null);
                    FilterSortSection.tag.get(self.element).blur();
                })
                .on('change', LeadStatusFilter.tag.selector, function (e) {
                    self.scroller.status = Helper.toNumber($(this).val() || null);
                    FilterSortSection.tag.get(self.element).blur();
                })
                .on('change', SortSelection.tag.selector, function (e) {
                    self.scroller.orderby = Helper.toString($(this).val() || null);
                    FilterSortSection.tag.get(self.element).blur();
                })
                .on('click', LeadSearchScroller.tag.selector, function () {
                    FilterSortSection.tag.get(self.element).blur();
                })
                .on('click', function (e) {
                    let filters = FilterSortSection.tag.get(self.element);
                    if ($(e.target).is(filters) ||
                        $(e.target).is(filters.children('div'))) {
                        self.element.toggleClass('show-only-filters');
                    }
                    else if ($(e.target).is(filters.children('select'))) {
                        return false;
                    }
                    else {
                        self.element.removeClass('show-only-filters');
                    }
                })
                .on('change', `${FilterSortSection.tag.selector} select`, function (e) {
                    self.element.removeClass('show-only-filters');
                });
        }
    }

    public resize() {
        let self = this;
        super.resize();
        if (!!self.element) {
            let items = LeadSearchScroller.tag.get(self.element),
                height = self.calculateViewableHeight(InfinateScrollView.tag.get(self.element), FilterSortSection.tag.get(self.element));
            //items.height(height);
            self.scroller.resetHeight(height);
        }
    }

    public async render(): Promise<JQuery> {
        let self = this;
        let element = await super.render();
        if (!element) return null;
        let productOptions =
            element.append(FilterSortSection.render(
            /*isPrinciple*/ self.isPrinciple,
            /*title*/ self.localization,
            /*truckFilter*/ { trucks: self._trucks, selected: self.scroller.truckId },
            /*productFilter*/ { products: self._products, selected: self.scroller.productId },
            /*statusFilter*/ { leadStatuses: self._statuses, selected: self.scroller.status },
            /*notificationType*/ null,
            /*sort*/ { sorts: self._sorts, sort: self.scroller.orderby }
            ));
        element.append(await self.scroller.render());

        return element;
    }

    // #region Scroller
    public get scroller(): LeadSearchScroller {
        return this.children['scroller'] as LeadSearchScroller;
    }
    public set scroller(val: LeadSearchScroller) {
        this.setOrResetChild('scroller', val);
    }
    // #endregion
}

export class LeadSearchScroller extends InfinateScrollView {
    static tag = new TagSelector("leads");

    constructor(parentPage: PageBase, private service: ServiceHandler, private localization: ILocalization, private leadsPageHashTag: string) {
        super(parentPage, LeadSearchScroller.tag);

        this.refreshParameters();
    }

    //_truckId: number = 5319;
    _truckId: number = null;
    _productId: number = null;
    _status: LeadStatuses = null;
    _orderby: string = 'Id desc';
    _skip: number = 0;
    _take: number = 5;
    _totalCount: number = 1;

    public get truckId(): number { return this._truckId; }
    public set truckId(val: number) { if (this._truckId !== val) { this._truckId = val; AppSession.setData('LeadSearch', 'truckId', val); this.queryChanged(); } }

    public get productId(): number { return this._productId; }
    public set productId(val: number) { if (this._productId !== val) { this._productId = val; AppSession.setData('LeadSearch', 'productId', val); this.queryChanged(); } }

    public get status(): number { return this._status; }
    public set status(val: number) { if (this._status !== val) { this._status = val; AppSession.setData('LeadSearch', 'status', val); this.queryChanged(); } }

    public get orderby(): string { return this._orderby; }
    public set orderby(val: string) { if (this._orderby !== val) { this._orderby = val; AppSession.setData('LeadSearch', 'orderby', val); this.queryChanged(); } }

    public get skip(): number { return this._skip; }
    public get take(): number { return this._take; }
    public get totalCount(): number { return this._totalCount; }

    public refreshParameters() {
        let self = this;

        self._truckId = AppSession.asNumber('LeadSearch', 'truckId');
        self._productId = AppSession.asNumber('LeadSearch', 'productId');
        self._status = AppSession.asNumber('LeadSearch', 'status');
        self._orderby = AppSession.asString('LeadSearch', 'orderby') || 'Id desc';
    }

    public async init(): Promise<boolean> {
        let self = this;
        if (!await super.init()) return false;

        if (!!self.element) {
            self.element
                .on('click', LeadCard.tag.selector, function (e) {
                    let id = Helper.toNumber($(this).attr('data-id'));
                    window.location.hash = `${self.leadsPageHashTag}${Helper.toQueryString({ id: id }, '!')}`;
                });
        }
        return true;
    }
    // #region fetch data

    public clear() {
        let self = this;

        // reseting fetch counts
        self._skip = 0;
        self._totalCount = 1;
        if (!!self.element) {
            self.element.empty();
        }
    }

    public queryChanged() {
        let self = this;
        self.clear();
        self.refresh();
    }
    public async fetchMorePages(): Promise<JQuery[]> {
        let self = this;
        let items: JQuery[] = [];

        if (self.skip > self.totalCount) { }
        else if (self.skip === self.totalCount) { }
        else {
            let leads = await self._getPagedLeads();
            for (let i = 0; i < leads.items.length; i++) {
                let lead = leads.items[i];
                items.push(await LeadCard.render(lead, self.localization));
            }

            self._totalCount = leads.totalCount;
            self._skip += leads.items.length || 1;
        }
        return items;
    }

    async _getPagedLeads(): Promise<IPagedCollection<ISubcontractorLead>> {
        let self = this;
        try {
            let filter = "";
            if (Helper.hasValue(self.productId)) filter += `Lead/Branch/Product/Id eq ${self.productId}`;
            if (Helper.hasValue(self.status) && self.status == LeadStatuses.AllActive) {
                let active: string[] = [
                    `Status eq '${Lead.getStatusEnum(LeadStatuses.NewLead)}'`,
                    `Status eq '${Lead.getStatusEnum(LeadStatuses.Acknowledged)}'`,
                    `Status eq '${Lead.getStatusEnum(LeadStatuses.Scheduled)}'`,
                ];
                let statusFilter = `(${active.join(' or ')})`;
                filter += !!filter ? ` and ${statusFilter}'` : statusFilter;
            }
            else if (Helper.hasValue(self.status)) {
                let statusFilter = `Status eq '${Lead.getStatusEnum(self.status)}'`;
                filter += !!filter ? ` and ${statusFilter}` : statusFilter;
            }

            return await self.service.getPagedSubcontractorLeads(self.truckId, filter, self.orderby, self.skip, self.take);
        }
        catch (ex) {
            return { items: [], totalCount: 0 };
        }
    }
    // #endregion




}

