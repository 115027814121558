export { }

declare global {
    interface HTMLCollection {
        forEach: (fn: (item: Element, i: number, items: HTMLCollection) => void) => void;
    }
    interface Element {
        isArray: () => boolean;
    }
}

Element.prototype.isArray = function () {
    const e = this as Element;
    if (e.children.length < 2) return false;
    const tagName = e.children[0].tagName;
    for (let i = 1; i < e.children.length; i++) {
        if (e.children[i].tagName !== tagName) return false;
    }
    return true;
}
HTMLCollection.prototype.forEach = function (fn: (item: Element, i: number, items: HTMLCollection) => void) {
    const items = this as HTMLCollection;
    for (let i = 0; i < items.length; i++) {
        fn(items[i], i, items);
    }
}